import React, { lazy, Suspense } from 'react';
import PrivateRoute from "../../../routes/PrivateRoute";
import ActivityList from '../Health/Activities/List/ActivityList';
import ActivityForm from '../Health/Activities/Form/ActivityForm';
import CancerPortalPage from '../Health/HealthPortalPage/CancerPortalPage';

const ProviderList = lazy(() => import('../Health/Providers/List/ProviderList'));
const HealthSchedules = lazy(() => import('../Health/Calendars/HealthSchedules'));
const PlannerList = lazy(() => import('../Health/Planners/List/PlannerList'));
const PlannerReelList = lazy(() => import('../Health/Planners/List/PlannerReelList'));
const PlannerForm = lazy(() => import('../Health/Planners/Form/PlannerForm'));
const Schedules = lazy(() => import('../Health/Calendars/Schedules'));
const InvitedUserList = lazy(() => import("../InvitedUsers/InvitedUserList"));

const HealthRouter = (props) => (
  <>
    <PrivateRoute path="/cancer/page/:health_id?" component={CancerPortalPage} exact/>	
    <PrivateRoute path="/health/activities/:health_id?" searchString={props.searchString} component={ActivityList} exact />
    <PrivateRoute path="/health/activityForm/:journey_profile_id/:id?" searchString={props.searchString} component={ActivityForm} exact />
    <Suspense fallback={<div className="spinner"/>}>
      <PrivateRoute path="/health/planner" searchString={props.searchString} component={PlannerList} exact />
      <PrivateRoute path="/health/providers/:health_id?" searchString={props.searchString} component={ProviderList} exact />
      <PrivateRoute path={["/health/schedules/:journey_profile_id","/infant/schedules/:journey_profile_id"]} component={HealthSchedules} exact/>
      <PrivateRoute path="/health/invite/user/:journey_profile_id" component={InvitedUserList} searchString={props.searchString} exact />
      <PrivateRoute path="/health/planner/form/:id?" searchString={props.searchString} component={PlannerForm} exact />
      <PrivateRoute path="/health/planner/reel/:journey_profile_id" searchString={props.searchString} component={PlannerReelList} exact />
    </Suspense>
  </>
)

export default HealthRouter;