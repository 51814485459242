import BaseService from "../../../services/BaseService";
import env from "../../../env";
import axios from "axios";

class DailyMeasurementsService extends BaseService {

  async report(req) {
    const res = await axios.post(`${env.health_backend}health/daily_measurements/reports`, req);
    return res.data;
  }
}

export default new DailyMeasurementsService('health_backend', 'health/daily_measurements')