import env from '../../env';
import axios from 'axios';
import BaseService from "../../services/BaseService";

class OrganizationService extends BaseService {
  
  async report(req) {
    return await axios.post(`${env.health_backend}health/report/organization`, req);
  }

  async stats(req) {
    return await axios.post(`${env.health_backend}health/organizations/stats`, req);
  }
}

export default new OrganizationService('health_backend', 'health/organizations');