import React, {useEffect, useRef } from 'react';
import useModal from "../../hooks/useModal";
import {AlertSM} from "./NotificationPopup";

const useTimer = (onTimeExpired, timeRemainigLabel) => {

  const {isOpen: isTimeAlertOpen, toggleModal: toggleTimeAlert} = useModal();

  const timeEle = useRef(null);
  let timer = null;

  useEffect(()=>{
    return () =>{
      clearInterval(timer);
    }
  }, [])

  const startTimer = (totalTimeInMinutes) => {  
    const minutesLater = new Date();
    minutesLater.setMinutes(minutesLater.getMinutes() + totalTimeInMinutes);

    // Set the date we're counting down to
    let countDownDate = minutesLater.getTime();

    // Update the count down every 1 second
    timer = setInterval(function() {

      // Get today's date and time
      let now = new Date().getTime();

      // Find the distance between now and the count down date
      let distance = countDownDate - now;
      sessionStorage.setItem('current_timer', ((distance/1000/60)+1)); //add 1 min extra

      // Time calculations for days, hours, minutes and seconds
      //let days = Math.floor(distance / (1000 * 60 * 60 * 24));
      let hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      let seconds = Math.floor((distance % (1000 * 60)) / 1000);

      // Display the result
      if(timeEle?.current){
        timeEle.current.innerText = (hours + "h " + minutes + "m " + seconds + "s " + timeRemainigLabel);
      }

      if(seconds === 59 && [1, 5, 10].includes(minutes)){
        !isTimeAlertOpen && toggleTimeAlert();
        //window.alert(`Only ${minutes} minutes left`)
      }

      // If the count down is finished, write text
      if (distance < 0) {
        clearInterval(timer);
        sessionStorage.setItem('current_timer', null);
        timeEle.current.innerText = ("EXPIRED");
        onTimeExpired && onTimeExpired();
      }
    }, 1000);
  }

  return {
    isTimeAlertOpen,
    toggleTimeAlert,
    startTimer,
    timeEle
  }

}

const TimerEle = ({onTimeExpired, totalTimeInMinutes, timeRemainigLabel=""}) =>{
  const { startTimer, timeEle, isTimeAlertOpen, toggleTimeAlert } = useTimer(onTimeExpired, timeRemainigLabel);

  useEffect(()=>{
    const elapsedMin = parseFloat(sessionStorage.getItem('current_timer') || 0)
    if(totalTimeInMinutes>0)startTimer(elapsedMin || totalTimeInMinutes); 
  }, [totalTimeInMinutes])

  return (
    <>
      <span ref={timeEle}></span>
      <AlertSM autoClose={true} text={timeEle.current?.innerText + ' ' + timeRemainigLabel}
        isOpen={isTimeAlertOpen} toggleModal={toggleTimeAlert}/>
    </>
  )
}

export {TimerEle, useTimer};