import React, { useRef } from "react";
import useModal from "../../../hooks/useModal";
import GenericModal from "../../../components/Modals/GenericModal";
import ReviewForm from './ReviewForm';

const useFormTestReview = () => {
  const {isOpen:isTestInputReviewFormOpen, toggleModal:toggleTestInputReviewForm} = useModal();

  const checkTestInputReviewNeeded = (fromData, fallback) =>{
    if(fromData.test_input){
      toggleTestInputReviewForm()
    }else if(fallback){
      fallback()
    }
  }

  return {
    checkTestInputReviewNeeded,
    isTestInputReviewFormOpen,
    toggleTestInputReviewForm,
  }
};

const FormTestInputReviewFormPopup = ({isOpen, toggleModal, testInputId, itemId, itemType, onclose}) => {
  const formId = 622;
  let {current:scope} = useRef({});

  scope.formProps = {
    'isPopupMode':true,
    'match':{
      'params':{
        'formId':formId,
        'id':testInputId,
        'item_id':itemId,
        'item_type':itemType
      }
    }
  }

  const onSubmit = () =>{
    onclose()
  }

  if(isOpen){
    return <GenericModal 
      component={ReviewForm} 
      isOpen={isOpen} 
      toggleModal={toggleModal}
      onSubmit={onSubmit}
      {...scope.formProps}/>
  }

  return null
  
}

export {useFormTestReview, FormTestInputReviewFormPopup}