import React ,{Fragment} from "react";

export const Pagination = (props) => {
  if(!props.totalItems || !props.itemsPerPage)return null;

  const nextPageClick=() =>{
    props.setCurrentpage(props.currentpage + 1)
  }
 
  const prevPageClick=() =>{
    props.setCurrentpage(props.currentpage - 1)
  }
 
  const handleClick=(page_number) =>{
    props.setCurrentpage(page_number) 
  }

  const totalPage = Math.ceil(props.totalItems / props.itemsPerPage);
  const pageNumbers = Array.from({length: totalPage}, (_, i) => i + 1)
  /*const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(props.totalItems / props.itemsPerPage); i++) {
    pageNumbers.push(i);
  }*/

  const renderPageNumbers = pageNumbers.map(number => {
    return(
      <a className={props.currentpage == number ? 'bg-highlight color-white' : ''} key={number} onClick={(e) => handleClick(number)}>
        {number}
      </a>)
  })

  if(pageNumbers.length==0){
    return null;
  }
  
  if(props.loadMoreBtn){
    return(
      <Fragment>
        {props.currentpage < totalPage &&
          <div className="col-xs-12 pagination text-center">
            <div className="badge-2 bg-lred" onClick={() => nextPageClick()}>
              <i className="fas fa-arrow-down m-l-5"/> Load more
            </div>
          </div>
        }
      </Fragment>
    )
  }

  return (
    <div className="col-xs-12 pagination">
      {props.currentpage > 1 && 
      <a onClick={() => prevPageClick()} className="bg-dark2-light">
        <i className="fa fa-angle-left"></i>
      </a>}
      
      {renderPageNumbers}
      
      {props.currentpage < totalPage && 
      <a onClick={() => nextPageClick()} className="bg-dark2-light">
        <i className="fa fa-angle-right"></i>
      </a>}
    </div>
  )
}

export default Pagination;