import React, { useEffect, useState, useRef } from "react"
import DynamicForm from "../../../FormBuilder/DynamicForm"
import DailyMeasurementsService from "../DailyMeasurementsService"
import querystringify from "querystringify"
import FormContainer from '../../../../components/FormBuilder/FormContainer'
import GeoLocation from "../../../../components/Common/GeoLocation"
import { useCurrentUserHook } from "../../../Authentication/useUserHook"

// let formMode = null
// let measurement = {}
// let data = {}
// let formFn = {}

function DailyMeasurementForm(props) {
  let { current: scope } = useRef({ formFn: {}});
  const {currentUser} = useCurrentUserHook()

  const [isFormRendered, setFormRendered] = useState(false)
  const [post, setPost] = useState({})

  let params = props.match.params
  let queryParam = querystringify.parse(props.location.search)

  const get = () => {
    const req = {
      id: params.id,
      journey_profile_id: params.journey_profile_id,
      form_id: queryParam.form_id
    }
    DailyMeasurementsService.get(params.id).then((res) => {
      if (res.status == 200) {
        scope.measurement = {...res.data.measurement, ...scope.measurement.geo_data}
        scope.data = scope.measurement.data || {}
        setFormRendered(true)
      }
    }).catch(function (res) {
      scope.measurement = null
    })
  }

  useEffect(() => {
    scope.measurement = {}
    scope.data = {}
    scope.formMode = "create-form-submissions"
    scope.formFn = {
      form_type: "cancer_journey.measurement",
      journey_profile_id: params.journey_profile_id,
    }

    if (params.id) {
      get()
      scope.formMode = "edit-form-submissions"
    } else if (!isFormRendered) {
      setFormRendered(true)
    }
  }, [])

  const create = (measurement) => {
    measurement.organization_id = currentUser.current_organization_id
    measurement.created_by = currentUser.id
    measurement.updated_by = currentUser.id
    measurement.journey_profile_id = params.journey_profile_id
    measurement.form_id = parseInt(queryParam.form_id) || 440
    measurement.form_type = queryParam.label || "measurement"
    DailyMeasurementsService.create(measurement).then((res) => {
      if (res.status == 201) {
        onSubmitPageRedirect(res.data.measurement)
      }
    }).catch((res) => {

    })
  }

  const update = (measurement) => {
    measurement.updated_by = currentUser.id
    DailyMeasurementsService.update(measurement).then((res) => {
      if (res.status == 204) {
        onSubmitPageRedirect()
      }
    }).catch((res) => {

    })
  }

  const onSubmitPageRedirect = (measurement) => {
    if(props.isPopupMode){
      props.onFormSubmit(measurement)
    }else if(queryParam.form_id){
      props.history.goBack()
    }
  }

  if (isFormRendered) {
    return (
      <FormContainer post={post} isPopupMode={props.isPopupMode}>
        <DynamicForm formMode={scope.formMode}
          formId={queryParam.form_id}
          form={scope.measurement}
          data={scope.data}
          formFn={scope.formFn}
          onCreate={create}
          onUpdate={update}
          post={post}
          onCancel={onSubmitPageRedirect}
          setPost={setPost}
          isPopupMode={props.isPopupMode}
          />
        <GeoLocation geoData={scope.measurement}/>
      </FormContainer>
    )
  } else {
    return null
  }
}

export default DailyMeasurementForm;