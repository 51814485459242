import React, { useState, useEffect, useContext, useMemo, useRef } from "react";
import HealthCategoryService from "../HealthCategoryService";
import CheckAccess from "../../Roles/CheckAccess";
import PreLoadSpinner from "../../PreLoadSpinner";
import Cs from "../../../services/CommonService";
import querystringify from "querystringify";
import useModal from "../../../hooks/useModal";
import Tabs, { TabPane } from "rc-tabs";
import { CommentsBtn } from "../../Modals/Comments";
import GenericModal from "../../Modals/GenericModal";
import Components from './Components';
import UpcomingSchedulesTable from "../Health/Calendars/UpcomingSchedulesTable";
import { AudioRecordingBtn } from '../../AudioRecordings/List';
import { usePageTitle } from "../../../hooks/pageHead";
import SideMenuBar from "../../Common/SideMenuBar";
import CTLazyLoad from "../../Common/CTLazyLoad";
import { tabMoreIcon } from "../../Common/Button";
import MCJScheduleAlert from "../MotherInfantJourney/Activities/List/MCJScheduleAlert";
import { getLocale } from '../../FormBuilder/FieldLabel';
import { usePageLocale } from '../../../hooks/useLocale';
import { LocalizeContext } from "../../../contexts/Localize/LocalizeStateProvider";
import { CueTip } from "../../Common/CueTooltip";
import { FilesListBtn } from "../CaseReports/OrganizationMediaFiles";
import { JourneyContext } from '../List/JourneyContext';
import { useSubscriptionAccess } from '../../Subscriptions/useSubscription';
import { useCurrentUserHook } from "../../Authentication/useUserHook";
import { NavIcon } from "../../Common/MenuItem";

const css = ['/css/tab_banner_layout.css'];
const PortalPage = (props) => {
  
  let params = props.match.params;
  let queryParam = querystringify.parse(props.location.search);
  let {current:scope} = useRef({'extraProps':{}, 'journeyComponents':{}});

  const {currentUser} = useCurrentUserHook();
  const {localeState:{casePortalPage}, localeDispatch} = useContext(LocalizeContext);
  const {journeyState:{dataSource:dsCaseList},journeyDispatch} = useContext(JourneyContext);

  const currentOrgMember = currentUser?.current_member || {};
  const {labelLocale} = usePageLocale(currentUser.current_locale, 'casePortalPage');
  const {subscriptionAccesses} = useSubscriptionAccess();

  const [loading, setLoading] = useState(true);
  const [journey, setJourney] = useState({});
  const [todaySchedules, setTodaySchedules] = useState([]);
  const [pageIds, setPageIds] = useState(['case_portal_page']);
  const [pptTabKey, setPptTabKey] = useState('');
  const [processTabKey, setProcessTabKey] = useState('');
  const [activityReloadTab, setActivityReloadTab] = useState();
  const [child, setChild] = useState();
  //const [childList, setChildList] = useState([])

  const { isOpen: isUpcomingSchedulesOpen, toggleModal:toggleUpcomingSchedulesModal } = useModal();
  const { isOpen: isAudioRecorderOpen, toggleModal:toggleAudioRecorder } = useModal();

  usePageTitle(journey?.data?.mother_name || journey?.data?.name);

  const orgBgImageStyle = {
    background:`url(${Cs.getIconByType(currentUser?.current_organization?.data?.picture)}) center /cover`,
  }

  const assessmentPermission = useMemo(()=>{ 
    return CheckAccess.getMemberPermissionBySid(currentUser, 'case_add_on', 'case_assessment');
  }, [currentUser.id, currentUser.current_organization_id, currentUser.current_medical_facility_id])

  useEffect(() => {
    scope.journeyComponents = subscriptionAccesses;
  }, [subscriptionAccesses])

  useEffect(() => {
    let req = {
      'subscription_id': currentUser.current_subscription?.id,
      'locale':currentUser.current_locale,
      'data_source_params': {
        'locale':currentUser.current_locale,
        'data_source_id': [3935, 4736]
      }
    }

    req.page_locale = {
      'data_source_ids':[3934, 3938, 3940, 3986, 4721],
      'locale':currentUser.current_locale
    }

    HealthCategoryService.get(params.journey_profile_id, req).then((res)=>{
      setLoading(true)
      if(res.status === 200){
        res = res.data
        if(queryParam.type === 'cancer_journey'){
          scope.journeyComponents.firstTabs = scope.journeyComponents.element_ppt_cancer.options;
          scope.journeyComponents.secondTabs = scope.journeyComponents.pins_process_cancer.options;
          scope.extraProps.menuOptions = scope.journeyComponents.indiv_health_portal;
        }else if(queryParam.type === 'ckd_journey'){
          scope.journeyComponents.firstTabs = scope.journeyComponents.element_ppt_cancer.options;
          scope.journeyComponents.secondTabs = scope.journeyComponents.pins_process_dementia.options;
          scope.extraProps.menuOptions = scope.journeyComponents.indiv_health_portal;
          setProcessTabKey(scope.journeyComponents.secondTabs?.[0]?.sid);
        }else if(queryParam.type === 'dementia_journey'){
          scope.journeyComponents.firstTabs = scope.journeyComponents.element_ppt_cancer.options;
          scope.journeyComponents.secondTabs = scope.journeyComponents.pins_process_dementia.options;
          scope.extraProps.menuOptions = scope.journeyComponents.indiv_health_portal;
        }else if(queryParam.type === 'infant_journey'){
          const qpChild = res.child //s.find(c => c.id === queryParam.child_id)
          scope.extraProps.associatedJourney = res.associated_childs;
          //extraProps.setChildList = setChildList
          scope.extraProps.child = qpChild;
          scope.extraProps.menuOptions = scope.journeyComponents.maternity_infant_nodes;
          scope.extraProps.orgMemberInactiveDays = res.org_member_inactive_days;
          scope.extraProps.schedulesMissed = res.schedules_missed;
          scope.journeyComponents.firstTabs = scope.journeyComponents.element_ppt_mcj.options.filter((t)=> 
            CheckAccess.checkUserAccess(currentOrgMember, currentOrgMember?.permissions?.infant_ppt, t.sid) 
          );
          scope.journeyComponents.secondTabs = scope.journeyComponents.pins_process_mcj.options.filter((t)=> 
            CheckAccess.checkUserAccess(currentOrgMember, currentOrgMember?.permissions?.infant_process, t.sid)
          );

          if(!qpChild){
            //If baby is not born show only mother and prenatal tabs
            scope.journeyComponents.firstTabs = scope.journeyComponents.firstTabs.filter((t)=> t.sid !== 'child_birth');
            scope.journeyComponents.secondTabs = scope.journeyComponents.secondTabs.filter((t)=> ['prenatal_care', 'periodic_check', 'protein_intake'].indexOf(t.sid) > -1);
          }else if(qpChild){
            //IF the baby is LT 6 months, then do NOT display the assess_cf tab
            const baby_age_month = Cs.getAge(qpChild.data?.dob_baby_formatted_date, null, true, 2);
            if(baby_age_month < 6){
              scope.journeyComponents.secondTabs = scope.journeyComponents.secondTabs.filter((t)=> t.sid !== 'assess_cf');
            }
          }

          setChild(qpChild);
          //setChildList(res.childs)
          setPptTabKey(!qpChild ? 'journey_profile':'child_birth');
          setProcessTabKey(!qpChild ? 'prenatal_care':'growth_measurement');
          onSecondTabChange(!qpChild ? 'prenatal_care':'growth_measurement');
        }else if(queryParam.type === 'adult_metabolic'){
          scope.extraProps.menuOptions = scope.journeyComponents.maternity_infant_nodes;
          scope.extraProps.orgMemberInactiveDays = res.org_member_inactive_days;
          scope.extraProps.schedulesMissed = res.schedules_missed;
          scope.journeyComponents.firstTabs = scope.journeyComponents.amj_ppt_tabs.options
          .filter((t)=> 
            CheckAccess.checkUserAccess(currentOrgMember, currentOrgMember?.permissions?.amj_ppt, t.sid) 
          );
          scope.journeyComponents.secondTabs = scope.journeyComponents.amj_process_tabs.options
          .filter((t)=> 
            CheckAccess.checkUserAccess(currentOrgMember, currentOrgMember?.permissions?.amj_process, t.sid)
          );

          setPptTabKey('journey_profile');
          setProcessTabKey('wellbeing_checklist');
        }
        
        scope.extraProps.timeFilter = (res.data_source_list.time_filter);
        setJourney(res.journey_profile);
        journeyDispatch({
          "type":"ACTIVE",
          "payload": {'activeJourney':res.journey_profile}
        });
        localeDispatch({
          "type": "UPDATE",
          "payload": {
            'casePortalPage': res.page_locale?.caseportal_page_elements,
            'childBirthTab': res.page_locale?.birth_details_tab,
            'motherTab': res.page_locale?.mother_tab_locale,
            'commonTableHeaders': res.page_locale?.general_table_fields,
            'accessBFTable': res.page_locale?.access_bf_table,
            'scheduleAlert': res.data_source_list?.upcoming_visits_alerts
          }
        });
        if(res.today_schedules.length > 0){
          setTodaySchedules(res.today_schedules)
          //toggleUpcomingScheduleAlert()
        };
        setLoading(false);
      }
    })
  }, [currentUser.current_locale])
  
  const getJourneyTypeLabel = () =>{
    if(queryParam.type === 'infant_journey'){
      return labelLocale(1);
    }
    return journey.data_source?.health_condition?.label;
  }

  const openAudioRecorder = (e) =>{
    toggleAudioRecorder(e);
  }

  let onSecondTabChange = (key) => {
    setProcessTabKey(key);
    const preFix = queryParam.type === 'infant_journey'?'mcj_':'';
    //setActiveKey(key)
    setPageIds([pageIds[0], preFix+key]);
  }

  const reloadActivity = (tab) => {
    setProcessTabKey(tab);
    setActivityReloadTab(tab);
  } 

  const updateStatus = (idx, req, callbackFn) =>{
    HealthCategoryService.update(req).then((res)=>{
      if(res.status === 204){
        journeyDispatch({
          'type': "UPDATE_BY_ID",
          'id':req.id,
          'payload':req
        })
        setJourney({...journey, ...req})
        callbackFn && callbackFn(req)
      }
    })
  }

  if(loading) 
    return <PreLoadSpinner />


  /*if(todaySchedules.length > 0){
    
    if(isUpcomingScheduleAlertOpen){
      return <GenericModal 
        component={MCJScheduleAlert} 
        journeyProfile={journey} child={child}
        onSuccess={toggleUpcomingScheduleAlert} 
        toggleModal={()=>setTodaySchedules([])}
        todaySchedules={todaySchedules}/>
    }

    return <ActivityModalLinks 
      child={child} journeyProfile={journey} 
      scheduleId={todaySchedules[0].id}
      schedule={todaySchedules[0]} autoOpenForm={true} 
      onScheduledFormSubmit={onScheduledActivityFormSubmit}/>
  } */

  const TabEle = ({tab}) => {
    const tabLocale = useMemo(() => {
      return{
        'label' : getLocale(tab, currentUser.current_locale, 'label'),
        'detail' : getLocale(tab, currentUser.current_locale, 'detail')
      }
    }, [tab.sid]);

    return <span data-tip={tabLocale.detail}>{tabLocale.label}</span>
  }

  return (
    <CTLazyLoad css={css}>
      <div id="ct">
        <div className="app-content m-t-15">
          <div className="bg-white brd-10 flex justspacebetween m-b-20 p10 m-t-20">
            <h2 className="m-r-30 f18-xss">
              {getJourneyTypeLabel()}
            </h2>
            <div className="flex aligncenter">
              <span id="pre_schedules" 
                className="bg-grey-4 round-btn m-r-5 m-t-4 tooltip"
                onClick={e=>toggleUpcomingSchedulesModal(e)}>
                  <i className="fas fa-calendar-alt"/>
                  <CueTip tooltip={labelLocale(4)} positionCss="bottom"/>
              </span>
              <AudioRecordingBtn journey={journey} currentUser={currentUser} onInitOpen={queryParam.popup === 'audio'}/>
              {/*<ScribblePadBtn journey={journey} currentUser={currentUser}/>*/}
              <CommentsBtn item={journey} currentUser={currentUser} 
                onInitOpen={queryParam.popup === 'comments'}
                commentableType="journey_profile" 
                commentableId={journey.id}/>
              <FilesListBtn journey={journey}
                tooltip={labelLocale(20)} 
                organizationId={currentUser?.current_organization_id}
                currentUser={currentUser}/>
              {assessmentPermission?.write &&
                <NavIcon id="add_assessment" positionCss="left"
                  className="bg-highlight round-btn float-right m-r-5 m-t-4" 
                  icon="far fa-list-alt bg-grey-4"
                  link={`/case/${journey.id}/assessments/${journey.created_by}`}/>
              }
            </div>
          </div>  

          <div id="case_ppt_details"
            className="app-header flex justspacebetween card bg-white brd-10 m-t-30 m-t-20-xs b-tab active">
            <Tabs defaultActiveKey={pptTabKey} moreIcon={tabMoreIcon}>
              {scope.journeyComponents.firstTabs?.map((tab, key) =>
                <TabPane tab={<TabEle tab={tab}/>} key={tab.sid}>
                  <Components componentName={tab.sid} 
                    journey={journey} 
                    formId={journey.form_id} 
                    dataSource={tab} 
                    updateStatus={updateStatus}
                    orgBgImageStyle={orgBgImageStyle}
                    journeyType={queryParam.type}
                    defaultViewType="patientAdditionalInfo"
                    {...scope.extraProps}/>      
                </TabPane>
              )}
            </Tabs>
          </div>

          <MCJScheduleAlert child={child}
            journeyProfile={journey} 
            todaySchedules={todaySchedules}
            processNodes={scope.journeyComponents.secondTabs}
            setTodaySchedules={setTodaySchedules}
            reloadActivity={reloadActivity}
            labelLocale={labelLocale}
            currentLocale={currentUser.current_locale}/>

          <div id="case_process_details" className="app-header flex justspacebetween card bg-white brd-10 m-t-30 m-t-20-xs b-tab active">
            <Tabs activeKey={processTabKey} onChange={onSecondTabChange} moreIcon={tabMoreIcon}>
              {scope.journeyComponents.secondTabs?.map((tab, key) =>
                <TabPane tab={<TabEle tab={tab}/>} key={tab.sid}>
                  <Components componentName={tab.sid} 
                    journeyProfile={journey} 
                    dataSource={tab} 
                    journeyType={queryParam.type}
                    activityReloadTab={activityReloadTab} 
                    defaultViewType="activityTable"
                    {...scope.extraProps}/>      
                </TabPane>
              )}  
            </Tabs>
          </div>

        </div>
      </div>

      {/*<ScreenOrientationAlert alert={labelLocale(8)}/>*/}

      {isUpcomingSchedulesOpen &&
        <GenericModal component={UpcomingSchedulesTable} 
          isOpen={isUpcomingSchedulesOpen} 
          toggleModal={toggleUpcomingSchedulesModal} 
          title={labelLocale(4)}
          journeyProfileId={params.journey_profile_id}
          setProcessTabKey={setProcessTabKey}/>
      }
      
      <SideMenuBar pageIds={pageIds}/>
      
      <div className="m-b-80" />

    </CTLazyLoad>
  )
}

export default PortalPage;