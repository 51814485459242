import {useContext} from "react";
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider";

const useCurrentUserHook = () => {
	const { state: {screen, user: currentUser}, dispatch, isAuthenticated, localeDispatch} = useContext(AuthContext);

	const hasDepartments = () => {
		return currentUser.current_organization.data_source?.organization?.label === "Government Unit";
	}

	const getCurrentOrgName = () => {
		return currentUser.current_organization.data?.name;
	}

	const getOrgNameById = (orgId) =>{
		return currentUser.organizations.find(o => o.id === orgId)?.name;
	}

	const getCurrentSubscription = () =>{
		return currentUser.current_subscription;
	}

	const getCurrentMfIds = () =>{
	    const org = currentUser.organizations.find(o => o.id === currentUser.current_organization_id);
	    return org.medical_facilities.map(f => f.id);
  	}

	return{
		screen,
		currentUser,
		dispatch,
		isAuthenticated,
		localeDispatch,
		hasDepartments,
		getCurrentOrgName,
		getOrgNameById,
		getCurrentSubscription,
		getCurrentMfIds	
	}
}

export {useCurrentUserHook};