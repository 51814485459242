import BaseService from "../../services/BaseService";
import env from '../../env';
import axios from 'axios';

class UserRequestService extends BaseService {

  async getAutoFillByOrgId(req) {
    return axios.get(env.health_backend + 'public/organization/options', { params: req });
  }

  async sendVerifyEmail(req) {
    return axios.post(env.health_backend + 'health/user_request/verify_email', req);
  }

  async uploadCsv(req) {
    return await axios.post(env.health_backend + 'health/csv_upload/user_requests', req)
  }

  async bulkInsert(req) {
    return await axios.post(env.health_backend + 'health/bulk_insert/user_requests', req)
  }

  async getTrainingExamineeList(req) {
    return await axios.get(env.health_backend + 'training/examinee/list', { params: req })
  }

  async addSurvey(req) {
    return await axios.post(env.health_backend + 'health/user_request/add_survey', req)
  }

  async surveyReport(req) {
    return await axios.post(env.health_backend + 'health/user_request/survey_report', req)
  }

  async getLeaderboardReport(req) {
    return await axios.post(env.health_backend + 'health/user_request/leaderboard', req)
  }

  async downloadSurvey(req) {
    return await axios.get(env.health_backend + 'health/csv/user_surveys', { params: req })
  }

  async getAllSurvey(req){
    return await axios.get(env.health_backend + 'health/user_surveys', { params: req })
  }

  async checkSurveyDone(req){
    return await axios.get(env.health_backend + 'health/user_survey/exists', { params: req })
  }

  async getUserRegServey(req){
    /*req = {
      "user_request_id":user_reg.id
      "form_id":1161
    }*/
    return await axios.get(env.health_backend + 'health/user_reg/survey', { params: req })
  }

}

export default new UserRequestService('health_backend', 'health/user_requests');