import React from "react"

const Badge = ({text}) => {
  const defaultCss = 'badge badge-success fw900 f12'
  return (
    <div className={defaultCss}>{text}</div>
  )
}

const NotificationBadge = ({count, css, position="top"}) =>{
  const defaultCss = css?css:(position==='bottom'?
    'notification-count brd-100p text-center t-18 p-t-2 no-border bg-lred fw900 font-12'
    :
    'notification-count brd-100p notification-f11 fw900')

  return(
    <span className={defaultCss}>{count}</span>
  )
}

export {Badge, NotificationBadge};