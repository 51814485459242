import React, { useEffect, useState, useRef } from "react"
import DynamicForm from "../../FormBuilder/DynamicForm"
import FormContainer from '../../FormBuilder/FormContainer'
import { useCurrentUserHook } from "../../Authentication/useUserHook"

function TraineeReferenceForm(props) {
  let { current: scope } = useRef({ formFn: {}});
  const {currentUser} = useCurrentUserHook()
  const [isFormRendered, setFormRendered] = useState(false)
  const [post, setPost] = useState({})

  const params = props.match.params
  const path = props.match.path
  const history = props.history

  const get = () => {
    
  }

  useEffect(() => {
    scope.form = {}
    scope.data = {}
    scope.formFn = {form_type: "trainee_reference_form"}
    scope.formMode = "create-form-submissions"
    if (params.id) {
      get()
      scope.formMode = "edit-form-submissions"
    } else if (!isFormRendered) {
      setFormRendered(true)
    }
  }, [])

  const create = (form) => {
    
  }

  const update = (form) => {
    
  }

  const onSubmitPageRedirect = (cancel) => {
   
  }

  const onCancel = () => onSubmitPageRedirect(true)

  if (isFormRendered) {
    return (
      <FormContainer post={post} isPopupMode={props.isPopupMode}>
        <DynamicForm formMode={scope.formMode}
          formId={params.formId}
          form={scope.form}
          data={scope.data}
          formFn={scope.formFn}
          onCreate={create}
          onUpdate={update}
          post={post}
          onCancel={onSubmitPageRedirect}
          setPost={setPost}
          isPopupMode={props.isPopupMode}/>
      </FormContainer>
    )
  } else {
    return null
  }
  
}

export default TraineeReferenceForm;