import React from "react"
import useStyle from "../../../../hooks/useStyle"
import ApexChart from "../../../Charts/ApexChart"
import DynamicTable from "../../../Common/DynamicTable"
import { EmptyRowAlert } from '../../../Common/TableView'

const SymptomsTable = (props) =>{
  useStyle('/css/charts_layout_filter.css', true)

  const {formFields, addNotes, addChecklist, sort, symptoms, openForm, component, dataSources, graphData, Footer, requestParams} = props

  const OptionsEle = ({item}) => (
    <td className="text-center">
      <span id="record_edit" onClick={e=>openForm(component, item.id)} className="fas fa-edit m-r-15 font-18 text-muted"/>  
      <span className="far fa-comment m-r-15 font-18 text-muted" onClick={e=>addNotes(e, component.sid)}/>
      <span className="far fa-check-circle m-r-15 font-18 text-muted" onClick={e=>addChecklist(e, component.sid)}/>
    </td>
  )

  return(
    <>
      {graphData.bar_chart.length > 0 &&
        <div className="col-md-6 p-5">
          <div className="card-title-gray flex justspacebetween p20">
            <h4 className="fw700 f18 text-left">
              Geographic Analysis
            </h4>
          </div>
          <div className="card-body p20 clearfix">
            <ApexChart id="chat_3" chartType="bar" 
            title="Record Symptoms" data={graphData.bar_chart} />
            <Footer type="symptoms_bar_chart" />
          </div>
        </div>
      }
        
      <div className="col-md-6 p-5">
        <div className="table-responsive card-body">
          <DynamicTable requestParams={requestParams} onSort={sort} 
            formFields={formFields} formDataList={symptoms} 
            tClass="table" OptionsEle={OptionsEle}/>

          {symptoms.length === 0 &&
            <EmptyRowAlert/>
          }
        </div>
      </div>
    </>
  )    
}

export default SymptomsTable;