export default {
  OrganizationForm:439,
  HealthProfileForm:439,
  HealthJourneyForm:471, //433
  HealthTreatmentForm:436,
  HealthActivityForm:440,
  HealthChildForm:487,
  HealthChildFormBasic:501,
  HealthFamilyForm:471,
  HealthJournalForm:473,
  HealthPlannerForm:476,
  HealthFacilityForm:470,
  HealthHelpRegistration:496,
  HealthForumNewDiscussion:494,
  HealthForumReplyDiscussion:495,
  HealthCounselingForm:485,
  PreScheduleEventForm:524,
  NutritionCareAlertForm:537,
  DataSourceForm:536,
  UploadMeasureGrowthMCJForm:535,
  ScreeningEventForm:542,
  SubscriptionForm:544,
  OrganizationMemberForm: 547,
  MappingForms:['503', '477', '486'],
  mcjMotherForms:[500, 507, 576, 498],
  McjMeasureGrowthForm:503,
  TrainingProgramForm:550,
  TrainingSectionForm:551,
  TrainingContentForm:552,
  AssessBfForm:477,
  TrainingAssessment:554,
  AssessmentSession:1158,
  ContactForm:571,
  FeedbackForm:578,
  UserRequestForm:517,
  FormFieldNote:584,
  CaseReportForm:585,
  IPAScheduleForm:586,
  AppRequestForm:587,
  AntenatalCare:507,
  PeriodicCheck:576,
  ProteinIntake:583,
  OrgInitiative:591,
  ResourceInventory:509,
  ResourceSupply:538,
  ResourceStock:592,
  OrgMemberAssessment:593,
  OrgOnboarding:597,
  ValidatePublicUser:619,
  ExamineeRegForm:1156,
}