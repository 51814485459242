import React, {useState, useEffect, useCallback, useRef} from "react"
import useModal from '../../hooks/useModal'
import Cs from '../../services/CommonService'
import env from "../../env"
import {InputTranslate} from "../Locale/InputTranslate"
import CTLazyLoad from "../Common/CTLazyLoad"
import {MediaFilterList} from '../MediaFiles/MediaList'
import GenericModal from '../Modals/GenericModal'
import CheckAccess from '../Roles/CheckAccess' 
import { DivRichTextView } from "./RichTextView"
import FileUploadService from "../../services/FileUploadService"

const SummerNoteEditor = (props) =>{
  return(
    <CTLazyLoad 
      css={[
        '/fonts/css/summernote-lite.min.css',
        '/scripts/summernote/jquery-3.4.1.slim.min.js', 
        '/scripts/summernote/summernote-lite.min.js'
      ]}
      preRender='lazy'>
      
      <SummerNoteEditorJquery {...props}/>
    </CTLazyLoad>
  )
}

const SummerNoteEditorJquery = ({id, currentUserEmail, content, onContentChange, required, dataType, dataId, formData, restrictMediaFiles, formFn={}, uploadPrivacyType='private', isImgCloneEnabled, isCodeViewEnabled, disabled}) => {
  
  const [isOpen, setIsOpen] = useState(false)
  const [retry, setRetry] = useState(0)
  const [isInitialized, setIsInitialized] = useState(false)
  const [disableTranslate, setDisableTranslate] = useState(true)
  const textAreaRef = useRef(null)
  const isDevUser = CheckAccess.isDevUser(currentUserEmail)

  const { isOpen: isImageFilterOpen, toggleModal: toggleImageFilter} = useModal()

  useEffect(() =>{
    return ()=>{
      try{
        console.log('destroy  '+id)
        window.jQuery('#summernote_'+id).summernote('destroy')
      }catch(e){
        
      }
    }
  }, [])

  useEffect(() => {
    if(isOpen && !isInitialized){
      setEditor()
      /*importScript('', setEditor, window.jQuery)
      importScript('', setEditor, true)*/
    }

    if(isOpen){
      //console.log('add click')
      window.addEventListener('click', blur)
    }else{
      //console.log('remove click')
      try{
        isInitialized && onContentChange(window.jQuery('#summernote_'+id).summernote('code'), id)
      }catch(e){
        console.log(e)
      }
      window.removeEventListener('click', blur)
    }

    return ()=>{
      window.removeEventListener('click', blur)
    }
  }, [isOpen, retry])

  /*
    window.jQuery('#summernote').summernote('disable');
  */

  /*useEffect(() => {
    if(isOpen && isInitialized){
      const elements = document
      .getElementById('summernote_parent_'+id)
      .getElementsByClassName('note-view')
      if(elements.length > 0){
        elements[0].innerHTML += `<button type="button" id="${id}_translate_btn" class="note-btn">
          <i class="fas fa-globe"/>
        </button>`

        const translateBtn = document
        .getElementById(`${id}_translate_btn`)
        .onclick = function(){
          setDisableTranslate(!disableTranslate)
        }
      }
    }
  }, [isInitialized, isOpen])*/

  const onFileUpload = (files) =>{
    try {
      var FileSize = files[0].size / 1024 / 1024 // in MB
      if (FileSize > 2) {
        return
      }else{
        
      }

     if(files.length>0){
        let imageData = {'fileable_type':dataType, 'fileable_id':dataId, 'is_rich_text':true}
        FileUploadService.uploadFileToUrl(files[0], uploadPrivacyType, imageData).then((res)=> {
          insertImage(res.data.file_upload)
        }, (err)=>{

        })
      }
    } catch (e) {
      console.log(e)
    }
  }

  const insertImage = (file_upload) =>{
    const fileImage = Cs.getIconByType(file_upload.url || file_upload.file)
    const img = window.jQuery('<img>').attr({ src: fileImage })
    window.jQuery('#summernote_'+id).summernote('insertNode', img[0])
  }
 
  const removeImage= (file) => {
    try {
      file = file.replace(env.file_url, "")
      FileUploadService.deleteFileFromUrl(file).then(function(res) {
        if(res.status === 200 || res.status === 404){

        }
      })
    } catch (e) {
      console.log(e)
    }
  }

  const setEditor = () =>{
    setTimeout(() => {
      try{  
        if(window.jQuery && window.jQuery('#summernote_'+id).summernote){
          const jqsm = window.jQuery('#summernote_'+id)
          
          //console.log('isInitialized '+id)

          const insert = restrictMediaFiles?['link','hr']:['link','picture','video','hr']
          jqsm.summernote({
            height: 100,
            focus: true,     
            toolbar: [
              ['headline', ['style']],
              ['style', ['bold', 'italic', 'underline', 'superscript', 'subscript','strikethrough', 'clear']],
              ['fontface', ['fontname']],
              ['textsize', ['fontsize']],
              ['color', ['color']],
              ['alignment', ['ul', 'ol', 'paragraph', 'lineheight']],
              ['height', ['height']],
              ['table', ['table']],
              ['insert', insert],
              ['view', [
                'fullscreen', 
                (isDevUser || isImgCloneEnabled)?'cloneImage':'',
                (isDevUser || isCodeViewEnabled)?'codeview':''
              ]] //, 'help'
            ],
            buttons: {
              cloneImage: function (context) {
                const ui = window.jQuery.summernote.ui;
                // create button
                const button = ui.button({
                  contents: '<i class="far fa-images"/>',
                  tooltip: 'Copy Image',
                  click: function () {
                    toggleImageFilter()
                    // invoke insertText method with 'hello' on editor module.
                    //context.invoke('editor.insertText', 'hello');
                  }
                });

                return button.render();   // return button as jquery object
              }
            },
            fontNames: ['roboto_regular'],
            fontNamesIgnoreCheck: ['roboto_regular'],
            addDefaultFonts: false,
            dialogsInBody: true,
            fontSizes: ['4','6','8', '9', '10', '11', '12', '14', '16', '18', '20', '22', '24', '36', '48' , '64', '82', '150'],
            callbacks: {
              onImageUpload: function(files) {
                // upload image to server and create imgNode...
                //$summernote;
                onFileUpload(files)
              },
              onMediaDelete : function(target) {
                // alert(target[0].src) 
                removeImage(target[0].src)
              },
              onChange: function(contents, $editable) {
                //Commented because causing validation issue in add question modal
                //console.log('onChange:', contents, $editable);
                 onContentChange(contents, id)
              },
              /*onBlur: function(e) {
                console.log(e)
                
                console.log('Editable area loses focus');
              }*/
            } 
          })

          jqsm.summernote('code', (formData[id] || null) )
          jqsm.summernote('fontName', "roboto_regular")
          jqsm.summernote('lineHeight', 1.2)
          jqsm.summernote('fontSize', 14)

          setIsInitialized(true)
        }

      }catch(e){
        console.log(e.message)
      }finally{
        console.log('retry '+retry)
        retry < 3 && setRetry(retry+1)
      }  
    }, 0)
  } 

  const blur = useCallback((e) => {
    try{
      //console.log(!document.getElementById('summernote_parent_'+id).contains(e.target))
      if(!document.getElementById('summernote_parent_'+id).contains(e.target)){
        //window.jQuery('#summernote_'+id).summernote('destroy');  
        setIsOpen(false)
      }
    }catch(e){
      console.log(e.message)
    }
  }, [])

  const insertRichText = (text) =>{
    const e = window.jQuery('#summernote_'+id)
    e.summernote('editor.restoreRange')
    e.summernote('editor.focus')
    e.summernote('editor.insertText', text)
  }

  const editorSaveRange = () => {
    window.jQuery('#summernote_'+id).summernote('editor.saveRange')
  }

  //console.log(id +'  '+ isOpen)

  return(
    <>
      <div id={'summernote_parent_'+id} className={disabled ? "disabled no-click" : ""}>
        <input name={id} required={required} className="hidden"/>
        
        <div onClick={()=>setIsOpen(true)}>
          <DivRichTextView  
            className="form-input form-input-gray form-input-full pre-wrap plainTxt2 ht-50 rich-text scroll-y" 
            style={{display:isOpen?'none':'block'}}
            html={formData[id]}/>
        </div>

        <div style={{display:isOpen?'block':'none'}}>
          <div id={'summernote_'+id} ref={textAreaRef}/>
        </div>

        {!disableTranslate && isInitialized && isOpen && formData[formFn.localeKey] &&
          <InputTranslate isRichText={true} 
            inputFieldId={'summernote_parent_'+id}
            insertRichText={insertRichText}
            editorSaveRange={editorSaveRange}
            localeId={formData[formFn.localeKey]}
            disableTranslate={disableTranslate}/>
        }

        {isOpen && 
          <TranslateBtn 
            disableTranslate={disableTranslate} 
            setDisableTranslate={setDisableTranslate} />
        }
      </div>

      {isImageFilterOpen &&
        <GenericModal component={MediaFilterList} title="Media List"
          isOpen={isImageFilterOpen} toggleModal={toggleImageFilter}
          fileableId={null} fileableType={formFn.form_type}
          onFileCloneSuccess={insertImage}/>
      }
    </>
  )
 
}

const TranslateBtn = ({disableTranslate, setDisableTranslate}) =>{
  return(
    <button type="button" 
      className={'note-btn z-idx-1 summernote-btn'+(disableTranslate?'':' highlight')} 
      onClick={()=>setDisableTranslate(!disableTranslate)}>
      <i className="fas fa-globe"/>
    </button>
  )
}

export default SummerNoteEditor;