import React, {useEffect, useState} from 'react'

const CTLazyLoad = (props) => {
  const [loading, setLoading] = useState(true)
  const [links, setLinks] = useState([])

  let CssURLs= props.css || []
  let preRender = props.preRender || 'eager'

  useEffect(()=>{
    /*const fetchURL = (url) => axios.get(url)

    const promiseArray = CssURLs.map(fetchURL)

    Promise.all(promiseArray).then((data) => {
      addLinkToHead()
      setLoading(false)
    }).catch((err) => {
      setLoading(true)
    })*/
    
    addLinkToHead()

    return () => {
      removeLinkFromHead()
      //links = []
    }
  }, [])

  const checkResourceLoadComplete = (loaded_resource_count) =>{
    if(loaded_resource_count == links.length){
      setLoading(false)
    }
  } 

  const addLinkToHead = () =>{
    let loadedResourceCount = 0
    CssURLs.forEach((u, i) => {
      let link = null
      if(u.includes('.css')){
        if(document.head.querySelectorAll(`link[href="${u}"]`).length==0){  
          link = document.createElement('link')
          link.setAttribute('rel', 'stylesheet')
          link.setAttribute('type', 'text/css')
          link.async = false
          link.setAttribute('href', u)
          link.onload = function(){
            loadedResourceCount += 1
            checkResourceLoadComplete(loadedResourceCount)
          }
          link.onerror = function(){
            console.error('Resource loading failed')
          }
          document.head.appendChild(link)
          links.push(link)
        }else{
          checkResourceLoadComplete(loadedResourceCount)//if all resource loaded by default
        }  
      }else if(u.includes('.js')){
        if(document.querySelector('script[src="' + u + '"]')==null){
          const script = document.createElement('script');
          script.src = u;
          script.id = u;
          script.async = true;
          script.onload = function(){ 
            loadedResourceCount += 1  
            checkResourceLoadComplete(loadedResourceCount)
          }
          script.onerror = function(){
            console.error('Resource loading failed')
          }
      
          document.body.appendChild(script);
          links.push(link)
        }else{
          checkResourceLoadComplete(loadedResourceCount)//if all resource loaded by default
        }
      }
    })
  }

  const removeLinkFromHead = () =>{
    for(const l of links){
      if(l && l.outerHTML.includes('.css')){
        try{
          document.head.removeChild(l)
        }catch(e){
          console.log(e.message)
        }
      }else if(l && l.outerHTML.includes('.js')){  
        try{
          document.body.removeChild(l);
        }catch(e){
          console.log(e.message)
        }
      }
    }
  }

  if(preRender == 'eager'){
    return (
      <>
        {loading && <div className="spinner"/>}
        <div className={loading?"hidden":""}>
          {props.children}
        </div>
      </>
    )
  }else{
    return (
      <>
        {!loading && 
          <>
            {props.children}
          </>
        }
      </>
    )
  }

}

export default CTLazyLoad;