import env from '../env';
import axios from 'axios';
import BaseService from './BaseService';
const url = `${env.health_backend}health/form_alerts`

class FormAlertService extends BaseService {

	async get(req) {
		return axios.get(`${url}`, { params: req });
	}

}

export default new FormAlertService('health_backend', 'health/form_alerts');