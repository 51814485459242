import React, {useState} from 'react'
import FormHelper from '../../../components/FormBuilder/FormHelpers'
import SummerNoteEditor from '../../../components/Common/SummerNoteEditor'
import {FieldLabel, Description, RequiredMsg} from '../FieldLabel'
import {CueTip} from '../../../components/Common/CueTooltip';

const InputRichText = ({user, renderField, field, position, formData, readOnly, errors, formFn, isFormWizard, formId, openFieldLabelModal, currentLocale}) => {
  //useStyle('smartblock')

  const [showError, setShowError] = useState(false);

  if(!renderField){
    return null
  }

  let inputAttributes = {
    'id': field.client_id,
    'name': field.client_id,
    'placeholder': field.placeholder,
    'disabled': readOnly 
  }

  let parentAttributes = {
    'tooltip': field.tooltip
  }

  let labelAttributes = FormHelper.setLabelStyle(field, isFormWizard, parentAttributes);
  
  let validation = {
    required: field.required
  }

  const onContentChange = (html) =>{
    formData[field.client_id] = html
    setError()
  }

  const setError = () => {
    if(field.required){
      errors[field.client_id].invalid = (formData[field.client_id] && formData[field.client_id].length>0)?false:true;
      errors[field.client_id].touched = true;
      if(errors[field.client_id].invalid){
        errors.invalid[field.client_id] = true;
      }else{
        delete errors.invalid[field.client_id]
      }
      setShowError(!showError)
      formFn.refreshFormSubmit();
    }
  }

  const Error = () => {
    if(field.required && (errors[field.client_id].touched || errors.isSubmitted) && errors[field.client_id].invalid){
      return(
        <div className="errormsg">
          <div>
            <span className="fas fa-exclamation-triangle m-r-5"/>
            <RequiredMsg {...{field, currentLocale}}/>
          </div>
        </div>
      )
    }
    return null;
  }

  FormHelper.setMinMaxValidation(field, inputAttributes);
  //FormHelper.setDisabled(field, inputAttributes);
  //FormHelper.checkFieldValidity(field, formData, errors);

  return (
    <div {...parentAttributes}>
      <div className="flex coldir m-b-20 tooltip">
        <CueTip 
          positionCss={position>1?'top':'bottom'}
          tooltip={field.tooltip}
          currentLocale={currentLocale}
          locale={field.locale?.tooltip}/>
        <FieldLabel field={field} 
          isFormWizard={isFormWizard}
          labelAttributes={labelAttributes} 
          currentLocale={currentLocale}
          openFieldLabelModal={openFieldLabelModal}/>
        <SummerNoteEditor id={field.client_id} onContentChange={onContentChange} 
          content={formData[field.client_id]} formData={formData} required={field.required} 
          restrictMediaFiles={field.restrict_media_files} formFn={formFn}
          currentUserEmail={user.email} uploadPrivacyType={field.privacy_type}/>
        {isFormWizard &&  <Description description={field.description}/>}
        <Error/>
      </div>
    </div> 
  )

}

export default InputRichText;