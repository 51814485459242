import { useState, useEffect } from 'react';

const useOnLine = () => {
  
  const [isOnline, setOnline] = useState(window.navigator.onLine);
  const [connectionStatus, setConnectionStatus] = useState("");

  useEffect(()=>{
    window.onoffline = (e) => {
      setOnline(false);
      console.log("The network connection is online.");
      setConnectionStatus("Not connected.");
    }

    window.ononline = (e) => {
      setOnline(true);
      console.log("The network connection is online.");
      setConnectionStatus();
      /*if(navigator.connection.rtt > 1000){
        setConnectionStatus("Slow connection.");
      }else{
        setConnectionStatus("");
      }*/
    }

    /*logNetworkInfo();
    navigator.connection.addEventListener('change', logNetworkInfo);

    return () => {
      navigator.connection.removeEventListener('change', logNetworkInfo);
    }*/
  }, [])

  const logNetworkInfo = () => {
    if(navigator.connection.rtt > 1000){
      setConnectionStatus("Slow connection.");
    }else{
      setConnectionStatus("");
    }

    // Network type that browser uses
    console.log('         type: ' + navigator.connection.type);

    // Effective bandwidth estimate
    console.log('     downlink: ' + navigator.connection.downlink + ' Mb/s');

    // Effective round-trip time estimate
    console.log('          rtt: ' + navigator.connection.rtt + ' ms');

    // Upper bound on the downlink speed of the first network hop
    console.log('  downlinkMax: ' + navigator.connection.downlinkMax + ' Mb/s');

    // Effective connection type determined using a combination of recently
    // observed rtt and downlink values: ' +
    console.log('effectiveType: ' + navigator.connection.effectiveType);
    
    // True if the user has requested a reduced data usage mode from the user
    // agent.
    console.log('     saveData: ' + navigator.connection.saveData);
    
    // Add whitespace for readability
    console.log('');
  }

  return {
    isOnline,
    connectionStatus
  }

};

export default useOnLine;