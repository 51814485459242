import React, {useState, useContext} from "react"
import {usePageLocale} from "./../../hooks/useLocale"
import { useCurrentUserHook } from "../Authentication/useUserHook"

const Table = (props) => {
  return (
    <table className="table table-stripe" id={props.id}>
      {props.children}
    </table>
  )
}

const THead = (props) => {
  return (
    <thead className={props.className}>
      {props.children}
    </thead>
  )
}

const TH = (props) => {
  return (
    <th className={props.className} onClick={()=>props.onClick && props.onClick()}>
      {props.children}
    </th>
  )
}

const TR = (props) => {
  return (
    <tr className={props.className} onClick={()=>props.onSelect && props.onSelect()}>{props.children}</tr>
  )
}

const TD = (props) => {
  return (
    <td className={props.className} 
      onClick={()=>props.onSelect && props.onSelect()}
      colSpan={props?.colSpan}>{props.children}</td>
  )
}

const TBody = (props) => {
  return (
    <tbody className={props.className}>{props.children}</tbody>
  )
}            

const EmptyRowAlert = ({}) =>{
  const {currentUser} = useCurrentUserHook()
  const {labelLocale:tableHeadLocale} = usePageLocale(currentUser.current_locale, 'commonTableHeaders')

  return(
    <div className="text-center f16 m-t-15">
      {/*There is no data to display. Click "Add" or "+" to add details.*/}
      {tableHeadLocale(4)}
    </div>
  )
}

export {Table, THead, TH, TR, TD, TBody, EmptyRowAlert};