import React, {useState} from 'react';
import FormHelper from '../../../components/FormBuilder/FormHelpers';
import { DivRichTextView, LabelRichTextView } from '../../Common/RichTextView';

const InputSelect = ({field, formData, readOnly, errors, forceUpdate, formFn}) => {
  
  const [showError, setShowError] = useState(false);

  let inputAttributes = {
    'id': field.client_id,
    'name': field.client_id,
    'placeholder': field.placeholder,
    'disabled': readOnly,
    'required': field.required
  }

  let parentAttributes = {
    'tooltip': field.tooltip
  }

  let labelAttributes = {
    'className':`regTitles2 ${field.required && 'req-field'}`,
    'id':'label_'+field.client_id
  }
  
  let validation = {
    required: field.required
  }
  
  let op_kv = field.options_key_value;

  const onSelect = (value) =>{
    if(value=='null'){
      formData[field.client_id]=null;
    }else{
      formData[field.client_id] = FormHelper.isNumeric(value);
    }
    setError(false)
    forceUpdate();
    FormHelper.setFormTitle(field, formData, value, op_kv)
  }

  const setError = (reset_state) => {
    if(field.required){
      const name = field.client_id;
      errors[name].invalid = formData[field.client_id]==null?true:false;
      errors[name].touched = true;
      if(errors[name].invalid){
        errors.invalid[name] = true;
      }else{
        delete errors.invalid[name]
      }
      if(reset_state){
        setShowError(!showError);
        formFn.refreshFormSubmit();  
      }
    }
  }

  //FormHelper.setDisabled(field, inputAttributes);
  //FormHelper.checkFieldValidity(field, formData, errors);

  const Error = () => {
    if(field.required && (errors[field.client_id].touched || errors.isSubmitted) && errors[field.client_id].invalid){
      return(
        <div className="errormsg">
          <div>{field.label} is required.</div>
        </div>
      )
    }
    return null;
  }

  return (
  	<div className="form-group col-xs-12" {...parentAttributes}>
  	  <LabelRichTextView {...labelAttributes} html={field.label}></LabelRichTextView>
      <div className="fts-13 m-b-5">{field.cue_prompt_description}</div>
      <Error/>
      <div className="popup-menu-grid" data-tip data-for={`tooltip_${field.client_id}`}>
        {(op_kv && op_kv.options) 
          ? <OptionsObjList list={op_kv.options} onSelect={onSelect} selectedVal={formData[field.client_id]}/> 
          : <OptionsStringList list={field.options} onSelect={onSelect} selectedVal={formData[field.client_id]}/>
        }  
      </div>
  	  <DivRichTextView className="fts-13 rich-text rich-text-2" html={field.description}/>
      {/*<CueTooltip id={field.client_id} description={field.tooltip}/>*/}
 	  </div> 
  )
}

/*
 <span className={`chip chip-medium bg-gray1-dark ${ o.value == selectedVal && 'bg-ltint'} `} key={k} onClick={e=>onSelect(o.value)}>
      <i style={bgColor} className={`${(o.icon || 'fas fa-plus')}`}></i>
      <strong className="black-light-1 font-500">{o.label}</strong>
    </span>

    <span className={`chip chip-medium bg-gray1-dark ${ o == selectedVal && 'bg-ltint'} `} key={k} onClick={e=>onSelect(o)}>
    <i className={`${(o.icon || 'fas fa-plus')} bg-green1-dark`}></i>
    <strong className="black-light-1 font-500">{o}</strong>
  </span>
*/

const OptionsObjList = ({list, onSelect, selectedVal}) => list.map((o, k) =>{
  if(o.read_only=='yes')return null;
  
  return(
    <div className={`ver-icons ${ o.value == selectedVal && 'bg-ltint'} `} onClick={e=>onSelect(o.value)} key={k}>
      <span>
        { o.icon ? <i className={`${o.icon}`} style={{ color: o.icon_color }}/>
        : <i className="circle_num">{k+1}</i>}
      </span>
      <div className="popup-menu-title">
        {o.label}
      </div>
      <i className="fas fa-chevron-right text-muted pull-right"/>
    </div>
  )
})

const OptionsStringList = ({list, onSelect, selectedVal}) => list.map((o,k) =>
  <div className={`ver-icons ${ o == selectedVal && 'bg-ltint'} `} onClick={e=>onSelect(o)} key={k}>
      <span>
        <i className="circle_num">{k+1}</i>
      </span>
      <div className="popup-menu-title">
        {o}
      </div>
      <i className="fas fa-chevron-right text-muted pull-right"/>
    </div>
)

export default InputSelect;