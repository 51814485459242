import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import ChatUsers from './ChatUsers'
import ChatConversation from './ChatConversation'
//import {socket} from "../Common/Socket"

const ChatModal = ({ isOpen, toggleModal, currentUser}) => {
  const [chatUser, setChatUser] = useState();
  const [newMessage, setNewMessage] = useState();

  const closeModal = () =>{
    setChatUser()
    toggleModal()
  }

  /*socket.openChatUser = (res) =>{
    res.data.author.user_id = res.data.author.id
    !isOpen && toggleModal()
    setChatUser(res.data.author) 
  }

  socket.onChatMessageReceived = (res) =>{
    if(chatUser && res.data.author.id == chatUser.user_id){
      setNewMessage(res.data)  
    }
  }*/

  if(!isOpen)return null;

  //useEscape(toggleModal);
  
  return(ReactDOM.createPortal(
    <div className="ml-modal">
      <div className="ml-modal-content ml-animate-zoom bg-white">
        {!chatUser && <ChatUsers isOpen={isOpen} toggleModal={toggleModal} setChatUser={setChatUser} toggleModal={toggleModal}/>}
        {chatUser && <ChatConversation newMessage={newMessage} setChatUser={setChatUser} chatUser={chatUser} toggleModal={closeModal}/>}
      </div>
    </div>, document.body)
  )
}

export default ChatModal;