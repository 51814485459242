import { useEffect } from 'react';

const useEscape = (callback) => {
  
  useEffect(() => {
    document.addEventListener("keydown", handleEscape)

    return () => {
      document.removeEventListener("keydown", handleEscape)
    }
  }, []);

  const handleEscape = (e) => {
    if (e.keyCode === 27) callback(e)
  }

};

export default useEscape;