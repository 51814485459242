import React, {useState} from 'react';
import FormHelper from '../../../components/FormBuilder/FormHelpers';
import ImageMapModal from '../ImageMapModal'
import {FieldLabel, Description, RequiredMsg} from '../FieldLabel'
import {CueTip} from '../../../components/Common/CueTooltip';

const InputRichImageMap = ({renderField, field, position, formData, readOnly, errors, formFn, isFormWizard, user, openFieldLabelModal, currentLocale}) => {
  const [showError, setShowError] = useState(false);
  const [imageMapData, setImageMapData] = useState();
  const [isImageMapOpen, setIsImageMapOpen] = useState(false);

  if(!renderField){
    return null
  }

  let KEY_NAME = 'SummerHTMLImageMapCreator';

  let inputAttributes = {
    'id': field.client_id,
    'name': field.client_id,
    'placeholder': field.placeholder,
    'disabled': readOnly 
  }

  let parentAttributes = {
    'tooltip': field.tooltip
  }

  let labelAttributes = FormHelper.setLabelStyle(field, isFormWizard, parentAttributes);
  
  let validation = {
    required: field.required
  }

  const openImageMap = (e) => {
    let imageMapData = formData[field.client_id] || {}
    imageMapData.user_id = user.id
    window.sessionStorage.setItem(KEY_NAME, JSON.stringify(imageMapData));
    setImageMapData(imageMapData)
    setIsImageMapOpen(true);
  }

  const closeImageMap = (e) =>{
    let imageMapData = JSON.parse(window.sessionStorage.getItem(KEY_NAME));
    formData[field.client_id] = imageMapData;
    setIsImageMapOpen(false)
  }

  const setError = (e) => {
    if(field.required){
      const name = field.client_id;
      errors[name].invalid = formData[name]==null?true:false;
      errors[name].touched = true;
      if(errors[name].invalid){
        errors.invalid[name] = true;
      }else{
        delete errors.invalid[name]
      }
    }
  }

  const Error = () => {
    if(field.required && (errors[field.client_id].touched || errors.isSubmitted) && errors[field.client_id].invalid){
      return(
        <div className="errormsg">
          <div>
            <span className="fas fa-exclamation-triangle m-r-5"/>
            <RequiredMsg {...{field, currentLocale}}/>
          </div>
        </div>
      )
    }
    return null;
  }

  //FormHelper.setDisabled(field, inputAttributes);
  
  return (
    <div className="form-group col-xs-12 col-sm-6 tooltip" title="tooltip">
      <CueTip 
        positionCss={position>1?'top':'bottom'}
        tooltip={field.tooltip}
        currentLocale={currentLocale}
        locale={field.locale?.tooltip}/> 
      <FieldLabel field={field} 
        isFormWizard={isFormWizard}
        labelAttributes={labelAttributes} 
        currentLocale={currentLocale}
        openFieldLabelModal={openFieldLabelModal}/>
      <label onClick={e=>openImageMap()} className="butn btn-default w-100p bg-highlight color-white" >   
        Open Image Map {isImageMapOpen ? <i className="fa fa-times notification-close"/> :<i className="fa fa-paperclip white" />}
      </label>  
      <Description description={field.description} currentLocale={currentLocale}
          locale={field.locale?.description} />
      <Error/>
      {isImageMapOpen && <ImageMapModal imageMapData={imageMapData} onClose={closeImageMap}/>}
    </div> 
  )
} 

export default InputRichImageMap;