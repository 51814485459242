const SideMenuRoute = (history, homePage, currentUser) => {
  switch(homePage?.sid) {
    case 'upcoming':
      history.push("/health/upcoming/dashboard");
      break;
    case 'user_reg_request':
      history.push("/user/request_list");
      break;
    case 'members':
      history.push(`/${currentUser.current_organization.data.name}/members/${currentUser.current_organization_id}`);
      break;
    case 'training':
      history.push("/list/training_programs");
      break;
    case 'reports':
      history.push("/infant/chart/state");
      break;
    case 'add_org_member_data':
      history.push("/data/entry/members");
      break;
    case 'facilities':
      history.push(`/health/list/facilities/${currentUser.current_organization_id}/${currentUser?.current_subscription?.id}`);
      break;
    case 'roles':
      history.push(`/health/role/infant_journey/${currentUser.id}/${currentUser.current_organization_id}/${currentUser?.current_subscription?.id}`);
      break;
    case 'guides':
      history.push(`/health/planner`);
      break;  
    case 'interventions':
      history.push(`/templates/nutrition_cares`);
      break;
    case 'training':
      history.push(`/list/training_programs`);
      break;
    case 'training_management':
      history.push(`/org/training_manager/${currentUser.current_organization_id}`);
      break;  
    case 'calendar':
      history.push(`/schedule/event`);
      break;
    case 'data_explorer':
      history.push(`/infant/measurements`);
      break;  
    case 'counseling':
      history.push(`/infant/list/counseling`);
      break;
    /*case 'what_new':
      history.push(`/org/whatsnew/${digitalContent.what_new?.child_form}/${currentUser.current_organization_id}`)
      break; */ 
    case 'data_choices':
      history.push(`/list/forms`);
      break;
    case 'training_users':
      history.push(`/user/training_programs`);
      break;  
    case 'quiz':
      history.push(`/list/quiz`);
      break;  
    case 'collections_management':
      history.push(`/org/collections/${currentUser.current_organization_id}`);
      break;  
    case 'org_member_recent_activities':
      history.push("/members/mcj/activity");
      break;
    case 'f2f_training_dashboard':
      history.push("/preworkshop/quiz/report"); 
      break; 
      /*Practice Entries, Pre-Training Activities*/
    default:
      history.push("/health/table");
  }
}

export {
  SideMenuRoute
}