import React, {useState} from 'react';
import FormHelper from '../../../components/FormBuilder/FormHelpers';
import {FieldLabel, Description, RequiredMsg} from '../FieldLabel'
import {CueTip} from '../../../components/Common/CueTooltip';

const InputRating = ({renderField, field, position, formData, readOnly, errors, formFn, isFormWizard, forceUpdate, openFieldLabelModal, currentLocale}) => {
  let [model, setModel] = useState(formData[field.client_id]);
  let [showError, setShowError] = useState(false);
  
  if(!renderField){
    return null
  }

  let inputAttributes = {
    'name': field.client_id,
    'disabled':readOnly
  }
  
  let parentAttributes = {
    'tooltip': field.tooltip,
    'id': field.client_id
  }
  
  let validation = {
    "required": field.required
  }

  let labelAttributes = FormHelper.setLabelStyle(field, isFormWizard, parentAttributes);
  let op_kv = field.options_key_value;

  //FormHelper.setDisabled(field, inputAttributes);
  //FormHelper.checkFieldValidity(field, formData, errors);

  const toggle = (value) => {
    if(model == value){
      setModel(0)
    }else{
      setModel(value);
    }
    formData[field.client_id] = value;
    setError();
    setTimeout(function() {
      forceUpdate();
    }, 0);
  }

  const setError = (event) => {
    if(field.required){
      const name = field.client_id;
      errors[name].invalid = formData[field.client_id]==null?true:false;
      errors[name].touched = true;
      if(errors[name].invalid){
        errors.invalid[name] = true;
      }else{
        delete errors.invalid[name]
      }
      setShowError(!showError);
      formFn.refreshFormSubmit();
    }
  }
  
  const Error = () => {
    if(field.required && (errors[field.client_id].touched || errors.isSubmitted) && errors[field.client_id].invalid){
      return(
        <div className="errormsg">
          <div>
            <span className="fas fa-exclamation-triangle m-r-5"/>
            <RequiredMsg {...{field, currentLocale}}/>
          </div>
        </div>
      )
    }
    return null;
  }

  const Rating = () =>{  
    return <div>
      <span className="font-20">
        <i className={`${1<=model?'fas green':'far'} fa-star`} onClick={e => toggle(1)}></i>
        <i className={`${2<=model?'fas green':'far'} fa-star`} onClick={e => toggle(2)}></i>
        <i className={`${3<=model?'fas green':'far'} fa-star`} onClick={e => toggle(3)}></i>
        <i className={`${4<=model?'fas green':'far'} fa-star`} onClick={e => toggle(4)}></i>
        <i className={`${5<=model?'fas green':'far'} fa-star`} onClick={e => toggle(5)}></i>
      </span>
    </div>
  }

  return (
  	<div {...parentAttributes}>
      <div className="flex coldir m-b-20 tooltip">
        <CueTip 
          positionCss={position>1?'top':'bottom'}
          tooltip={field.tooltip}
          currentLocale={currentLocale}
          locale={field.locale?.tooltip}/>
    	  <FieldLabel field={field} 
          isFormWizard={isFormWizard}
          labelAttributes={labelAttributes} 
          currentLocale={currentLocale}
          openFieldLabelModal={openFieldLabelModal}/>
        <Rating/>
    	  <span className="fts-13">{field.description}</span>
        <Description description={field.description} currentLocale={currentLocale}
          locale={field.locale?.description} />
        <Error/>
 	    </div>
    </div> 
  )

}

export default InputRating;