import env from "../env";
import axios from "axios";

class LocaleService {
  static async googleTranslate(req) {
    const { status, data } = await axios.post(env.backend + "chat_bot/google_translate", req);
    if (data.error) {
      window.alert(data.error.message);
    }
    return data;
  }

  static async googleInputTools(params) {
    const { data } = await axios.get(env.health_backend + 'health/inputtools', { params });
    if (data[0] === "SUCCESS") {
      return data[1];
    }
    return null;
  }
  
}
export default LocaleService;
