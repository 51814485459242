import React, { Fragment, useState, useEffect } from "react"
import Cs from "../../services/CommonService"
import FileUploadService from '../../services/FileUploadService'
import useModal from "../../hooks/useModal"
import { LinkFontAwesomeIcon } from "../Common/ImageIcons"
import GenericModal from '../Modals/GenericModal'
import DataSourceService from "../../services/DataSourceService"
import FormTemplateService from "../../services/FormTemplateService"

let searchKeyword = null;
let timer = null;
const SEARCH_WAIT_INTERVAL = 1000;
const MediaFilterList = ({searchType='data_source', fileableId, fileableType, onFileCloneSuccess, searchFormTemplateId}) => {
  const [dataSources, setDataSources] = useState({})
  const [list, setList] = useState([])
  const [formFields, setFormFields] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(()=>{
    getFormFields()
  }, [])

  const getDataSourceList = () => { 
    setLoading(true)
    const req = {
      'fields':['data_type'],
      'per_page' : 15
    }
    req.search = searchKeyword
    if(searchFormTemplateId){
      req.form_template_id = searchFormTemplateId
      req.form_fields_params = {
        'ids':[searchFormTemplateId]
      }
    }else if(!isNaN(searchKeyword)){
      req.data_source_id = [searchKeyword] 
    }
    DataSourceService.getDataSource({'data_source_params':req}).then(data => {
      setDataSources(data || [])
      setLoading(false)
    })
  }

  const getFileUploads = () =>{
    setLoading(true)
    const req = {
      'context':'form_search',
      'search':searchKeyword 
    }
    FileUploadService.getAll(req).then((res)=>{
      setList(res.data.file_uploads)
      setLoading(false)
    })
  }

  let getFormFields = () => { 
    const formIds = []
    if(fileableType === 'health.counseling'){
      formIds.push(616) //Counseling form id
    }
    if(formIds.length > 0){
      FormTemplateService.getFormFields({
        'ids':formIds, 
        'fields':['description'],
      }).then((res)=>{
        if(res.status === 200){
          setLoading(true)
          const fields = res.data.form_fields[616]
          let imageList = []
          for(const f of fields){
            const urls = Cs.extractUrlFromRichTxt(f.description)
            imageList = [...imageList, ...urls]
          }
          setFormFields(imageList)
          setLoading(false)
        }
      })
    }
  }

  const cloneImage = (clone_url) =>{
    FileUploadService.clone({
      'url': clone_url,
      'fileable_type': fileableType,
      'fileable_id': fileableId
    }).then(({status, data})=>{
      if(status === 201){
        onFileCloneSuccess(data.file_upload)
      }
    })
  }

  const handleChange=(search)=>{
    let triggerChange = null
    if(searchType === 'data_source'){
      triggerChange = getDataSourceList
    }else if(searchType === 'file_upload'){
      triggerChange = getFileUploads
    }
    clearTimeout(timer)
    searchKeyword = search
    timer = setTimeout(triggerChange, SEARCH_WAIT_INTERVAL)
  }

  return (
    <div id="ct">
      <div className="row">
        {loading ? 
          <div className="spinner"/>
          :
          <>
            <div className="row">
              <div className="col-md-4">
                <div className="card-body flex aligncenter justifycenter coldir p10">
                  <input className="form-input form-input-gray form-input-full" 
                  placeholder="Search"
                  onChange={e=>handleChange(e.target.value)}/>
                </div>
              </div>
              <div className="col-md-4">
                
              </div>
            </div>
            {searchType === 'data_source' &&
              <div className="row">
                <DataSourceImageList dataSourceList={dataSources} cloneImage={cloneImage}/>
              </div>
            }
            {searchType === 'file_upload' &&
              <div className="row">
                {list.map((l, k)=>
                  <Image key={k} url={l.url} cloneImage={cloneImage}/>
                )}
              </div>
            }
            {formFields?.length > 0 &&
              <div className="row">
                {formFields.map((u, k)=>
                  <Image key={k} url={u} cloneImage={cloneImage}/>
                )}
              </div>
            }
          </>
        }
      </div>
    </div>
  )
}

const DataSourceImageList  = ({dataSourceList, cloneImage}) => Object.values(dataSourceList).map((dataSource, pi)=>{

  const dataTypes = Object.values(dataSource.data_type || {})
  //const imageTypes = dataTypes.filter((t)=> 
  //['multi_image_upload', 'public_multi_image_upload', 'image_upload'].indexOf(t.type)>-1)
  
  return(
    <Fragment key={pi}>
      {dataSource?.options?.map((o, ci)=>
        <Fragment key={ci}>
          {dataTypes.map((type, i)=>
            <ImageContainer type={type} option={o} cloneImage={cloneImage} key={i}/>
          )}
        </Fragment>
      )}
    </Fragment>
  )
})

const ImageContainer = ({type, option, cloneImage})=>{
  if(['multi_image_upload', 'public_multi_image_upload'].indexOf(type.type)>-1){
    return <ImageList lists={option[type.key]} cloneImage={cloneImage}/>
  }else if(['image_upload', 'public_image_upload'].indexOf(type.type)>-1){
    return <Image url={option[type.key]} cloneImage={cloneImage}/>
  }

  return null
}

const Image = ({url, cloneImage}) => {
  if(url){
    return(
      <div className="col-md-3 text-center">
        <img src={Cs.getIconByType(url)} className="img-responsive w-250 ht-150 p-5"/> 
        <div className="m-t-25" onClick={()=>cloneImage(url)}>
          <div className="ellipsis">{Cs.getFileName(url)}</div>
          <input type="checkbox" readOnly />
        </div>   
      </div>
    )
  }

  return null
}

const ImageList = ({lists=[], cloneImage}) => lists ? lists.map((url, k)=>{
  return <Image url={url} cloneImage={cloneImage} key={k}/>
}):null

const MediaListBtn = ({fileableId, fileableType, onFileCloneSuccess}) => {
  const { isOpen: isImageFilterOpen, toggleModal: toggleImageFilter} = useModal()

  return(
    <>
      <LinkFontAwesomeIcon 
        iconCss="far fa-images font-18 white"
        onSelect={() => toggleImageFilter()}/>

      {isImageFilterOpen &&
        <GenericModal component={MediaFilterList} title="Media List"
          isOpen={isImageFilterOpen} toggleModal={toggleImageFilter}
          fileableId={fileableId} fileableType={fileableType}
          onFileCloneSuccess={onFileCloneSuccess}/>
      }
    </>
  )
}

export {MediaFilterList, MediaListBtn};