export default (state, action) => {
  const {type, payload} = action
  switch (type) {
    case "SET_SUBSCRIPTION_ACCESSES":
      return {
        ...state,
        'currentLocale':payload.currentLocale,
        'subscriptionAccesses':{...payload.subscriptionAccesses},
      };
    case "CLEAR":
      return {
        ...state,
        'currentLocale':null,
        'subscriptionAccesses':{}, 
      };
    default:
      return state;
  }
};