import React from "react"
import {Link} from "react-router-dom"
import {CueTip} from "./CueTooltip"

const CalenderBtn = ({id, onSelect, tooltip}) => {
  return (
    <button id={id} type="button" onClick={()=>onSelect()} className="btn btn-icon-calender btn-responsive text-white m-r-20 tooltip">
      Calendar
    </button>
  )
}

const ClearFiltersBtn = ({ onSelect }) => {
  return (
    <button type="button" onClick={() =>onSelect()} className="btn white bg-red-3 p-7i w-100p">
      Clear all filters
    </button>
  )
}

const AddBtn = ({id, onSelect, extraClassName, tooltip}) => {
  const css = extraClassName || 'btn-responsive'
  return (
    <button id={id} type="button" onClick={(e)=>onSelect(e)} className={`btn btn-icon-add text-white m-r-5 tooltip ${css}`}>
      Add
    </button>
  )
}

const FontAwesomeLink = ({id, link, name, iconCss, colorCss, tooltip, isResponsive=true}) => {
  const btnCss = `btn ${isResponsive?'btn-responsive':''} btn-primary bg-green ${colorCss?colorCss:''} text-white m-r-5 tooltip`
  return (
    <Link id={id} to={link} className={btnCss}>
      <span className={`${iconCss} m-r-5`}/>
      {name}
    </Link>
  )
}

const DeleteBtn = ({id, onSelect, tooltip}) =>{
  return(
    <button id={id} type="button" onClick={()=>onSelect()} className="btn btn-icon-delete btn-responsive text-white tooltip">
      Delete
    </button>
  )
}

const FontAwesomeBtn = ({id, onSelect, name, css, iconCss, colorCss, tooltip, isResponsive=true, children}) => {
  const btnCss = `btn ${isResponsive?'btn-responsive':'btn-sm'} btn-primary bg-green ${colorCss?colorCss:''} ${css?css:''} text-white tooltip`
  return (
    <button id={id} type="button" onClick={()=>onSelect()} className={btnCss}>
      <span className={`${iconCss} m-r-5`}/>
      {name?<span className={isResponsive?'hide-xs':''}>{name}</span>:null}
      <CueTip tooltip={tooltip} positionCss="bottom"/>
      {children}
    </button>
  )
}

const tabMoreIcon = <span className="fas fa-angle-right"/>

export {CalenderBtn, AddBtn, DeleteBtn, FontAwesomeLink, FontAwesomeBtn, tabMoreIcon, ClearFiltersBtn};