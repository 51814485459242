import React, { useState, useContext, useEffect, useRef, useMemo } from "react"
import HealthCategoryService from "../HealthCategoryService"
import ConfirmMenu from "../../Modals/ConfirmMenu"
import CommentsModal from "../../Modals/CommentsModal"
import useModal from "../../../hooks/useModal"
import useStyle from '../../../hooks/useStyle'
import CheckAccess from '../../Roles/CheckAccess'
import Cs from "../../../services/CommonService"
import GenericModal from "../../../components/Modals/GenericModal"
import querystringify from "querystringify"
import InfantJourneyTable from "./InfantJourneyTable"
import { DataSourceMenu } from "../../Modals/DataSourceMenu"
import UpcomingSchedulesTable from "../Health/Calendars/UpcomingSchedulesTable"
import UsersRolesPopup from '../../DataAccesses/UsersRolesPopup'
import { usePageTitle } from "../../../hooks/pageHead"
import { CTTooltip } from "../../Common/CueTooltip"
import SideMenuBar from '../../Common/SideMenuBar'
import JourneyCard from "./JourneyCard"
import NutritionCareNotification from '../../NutritionCares/NutritionCareNotification'
import { NotificationPopup } from "../../Common/NotificationPopup"
import { OptionsObjList } from '../../Common/FormInput'
import { usePageLocale } from '../../../hooks/useLocale'
import { LocalizeContext } from "../../../contexts/Localize/LocalizeStateProvider"
import LoadMoreBtn from '../../Common/LoadMoreBtn'
import { FilterBtn, ActiveFilters } from './Filter'
import { NewFeatures } from '../../DigitalContent/NewFeaturesPopup'
import { JourneyContext } from './JourneyContext'
import { NavIcon } from '../../Common/MenuItem'
import { useIdbKeyval } from '../../../hooks/useIdbKeyval'
import { CacheContext } from "../../../contexts/Cache/CacheStateProvider"
import { useCurrentUserHook } from "../../Authentication/useUserHook"

const HealthJourneyList = (props) => {
  useStyle('/css/tile_components.css', true)

  usePageTitle('Journey List')

  const queryParam = querystringify.parse(props.location.search)

  const { screen, currentUser, dispatch} = useCurrentUserHook()

  const {localeDispatch} = useContext(LocalizeContext)
  const {cacheState, cacheDispatch} = useContext(CacheContext)
  let {journeyState:{journeyList, pagemeta, dataSource}, journeyDispatch} = useContext(JourneyContext)

  const isDevUser = CheckAccess.isDevUser(currentUser.email)
  const roleType = useMemo(() => CheckAccess.getRoleType(currentUser), [currentUser.id])
  const {current:scope} = useRef({
    'requestParams':Cs.getSessionStore('mcjCaseRequestParams', true) || {}, 
    'selectedJourney':{}
  }) 
  const preFilter =  useMemo(() => {
    const filter = scope.requestParams?.filter || {};
    /*if(currentUser.current_organization?.record_id != 59 && (roleType === 'mentor' || roleType === 'supervisor')){
      return {
        ...filter, 
        'weight_zscore_label_in':['SUW', 'MUW', 'Mild']
      }
    }*/
    return filter;
  },[currentUser.id])
  
  const [currentpage, setCurrentpage] = useState(scope.requestParams.page || 1)
  const [loading, setLoading] = useState(true)
  const [visibleJourneyList, setVisibleJourneyList] = useState([])
  const [filter, setFilter] = useState(preFilter)
  const [loadDataFromAPI, setLoadDataFromAPI] = useState(journeyList.length === 0)
  const [search, setSearch] = useState(scope.requestParams?.search)

  const { labelLocale } = usePageLocale(currentUser.current_locale, 'journeyListPage')
  const { isOpen, toggleModal:toggleDeleteModal } = useModal()
  const { isOpen: isMenuOpen, toggleModal: toggleMenuModal } = useModal()
  const { isOpen: isJourneyMenuOpen, toggleModal: toggleJourneyMenuModal } = useModal()
  const { isOpen: isCommentOpen, toggleModal:toggleCommentModal } = useModal()
  const { isOpen: isUpcomingSchedulesOpen, toggleModal:toggleUpcomingSchedulesModal} = useModal()
  const { isOpen: isUserRoleOpen, toggleModal: toggleUserRole } = useModal()
  const { isOpen: isNutritionCareOpen, toggleModal:toggleNutritionCareModal } = useModal()
  const { isOpen: isWhatsNewOpen, toggleModal: toggleWhatsNew } = useModal(true)
  const {addToJourneyCacheList} = useIdbKeyval('cacheList')
  
  const pregnancyStageKv = useMemo(() => Cs.listToObj(dataSource?.pregnancy_stage?.options, 'value'), [dataSource?.pregnancy_stage]);

  const {permission, assessmentPermission, mcjFilter} = useMemo(() =>{ 
    return {  
      "permission": CheckAccess.getMemberPermissionBySid(currentUser, 'menu', 'case_list'),
      "assessmentPermission": CheckAccess.getMemberPermissionBySid(currentUser, 'case_add_on', 'case_assessment'),
      "mcjFilter": CheckAccess.destructUser(currentUser).permissions?.mcj_filter
    }
  }, [currentUser.id, currentUser.current_organization_id, currentUser.current_medical_facility_id])

  useEffect(()=>{
    //here load data for page 1 and on page change
    if(scope.requestParams.page != currentpage){
      setParams(currentpage)
      getJourneyList('APPEND')
    }
  }, [currentpage])

  useEffect(() => {
    if(!loading){ //added to avoid duplicate api call for 1st time and from query param search
      setParams(1) //set page to 1
      getJourneyList('REPLACE')
    }
  }, [search, filter])

  useEffect(() => {
    if(!loading){ 
      setFilter({});
    }
  }, [currentUser.current_organization_id, currentUser.current_medical_facility_id])

  useEffect(()=>{
    if(!loading){ //added to avoid duplicate api call for 1st time and from query param search
      setParams(1, true) //set page to 1
      getJourneyList('APPEND')
    }
  }, [currentUser.current_locale])

  useEffect(()=>{
    if(!loading && scope.requestParams.search != props.searchString)
      setSearch(props.searchString);
  }, [props.searchString])

  useEffect(()=>{
    onLoadedPageSelect(pagemeta.current_page)
  }, [journeyList])

  const setParams = (page = currentpage, resetLocale=false) =>{
    setCurrentpage(page);
    scope.requestParams = {
      'page':page,
      'per_page':10,
      'sort_column':'last_update_at',
      'sort_direction':'desc',
      'user_id':currentUser.id,
      'search': search, //filter search or main header search
      'search_by':filter.search_by,
      'is_active':filter.is_active,
      'organization_id':currentUser.current_organization_id,
      'medical_facility_id':currentUser.current_medical_facility_id,
      'sub_facility_id':currentUser.current_sub_facility_id,
      'id':queryParam.id,
      'filter':filter
    }

    if(page === 1 || resetLocale){
      scope.requestParams = {
        ...scope.requestParams,
        'page_locale':{
          'data_source_ids':[3929, 3936],
          'locale':currentUser.current_locale,
        }
      }
    }

    if(dataSource == null || resetLocale){
      scope.requestParams = {
        ...scope.requestParams,
        'data_source_params': {
          'locale':currentUser.current_locale,
          'data_source_id': [3643]
        }
      }
    }

    Cs.setSessionStore('mcjCaseRequestParams', {'filter':filter, 'search':search}, true)
  }

  let getJourneyList = async (dispatchType='REPLACE') => { 
    if(loadDataFromAPI){
      setLoading(true)
      HealthCategoryService.getAll(scope.requestParams).then((res)=>{
        if(res.status === 200){
          if(scope.requestParams.response_type === 'csv'){
            scope.requestParams.response_type = null;
            let dlnk = document.getElementById('dwnldLnk');
            let csv = "data:text/csv;charset=utf-8,%EF%BB%BF" + encodeURI(res.data);
            dlnk.download = currentUser?.current_organization?.data?.name+'_'+ (Cs.getCurrentDateTime('MMM-DD-YYYY')) +'_Caselist.csv';
            dlnk.href = csv;
            dlnk.click();
            setLoading(false);
          }else{
            //setVisibleJourneyList(res.data.journey_profile)
            onAfterApiCall(res.data.journey_profile);
            journeyDispatch({
              'type': dispatchType,//(props.searchString)?"REPLACE":"APPEND"
              'payload': {
                'journeyList': res.data.journey_profile,
                'pagemeta': res.data.meta,
                'dataSource': res.data.data_source_list,
              }
            })
            if(currentpage === 1){
              //Calling localeDispatch on each paginate will re-render whole app.
              localeDispatch({
                'type': "UPDATE",
                'payload': {
                  'journeyListPage':(res.data.page_locale?.home_page_elements),
                  'selectPopup':(res.data.page_locale?.general_labels)
                }
              })
            }
            setLoading(false)
          }
        }
      })
    }else{
      //Data already in cache
      //onLoadedPageSelect(pagemeta.current_page)
      setLoading(false)
      //Load next request from API 
      setLoadDataFromAPI(true)
    }
  }

  const onLoadedPageSelect = (pageNumber) =>{
    if(pageNumber > 0){
      const endIdx = pageNumber * scope.requestParams.per_page;
      const startIdx = endIdx - scope.requestParams.per_page;
      setVisibleJourneyList([...(journeyList.slice(startIdx, endIdx))]);
      /*if(pagemeta.current_page != pageNumber){
        journeyDispatch({
          'type': "UPDATE_PAGEMETA",
          'payload': {
            'pagemeta':{...pagemeta, 'current_page':pageNumber},
          }
        })
      }*/
    }
  }

  const onAfterApiCall = (journeyProfiles) =>{
    for(let j of journeyProfiles){
      j.day_since_last_notification = (Cs.getAge(j.last_notification_at, null,  false, 0, "days") || 0);
    }
  }

  const downloadAsCsv = () => {
    scope.requestParams.page = 1;
    scope.requestParams.per_page = 1000;
    scope.requestParams.response_type = 'csv';
    if(mcjFilter?.trainee_batch){
      //download only batch trainee user data
      scope.requestParams.filter = {...filter, is_f2f_trainee_user: true};
    }
    getJourneyList();
  }

  const deleteJourney = (e) => {
    e.preventDefault(e)
    HealthCategoryService.delete(scope.selectedJourney.id).then((res) => {
      if (res.status === 204) {
        journeyDispatch({
          'type':"DELETE",
          'id':scope.selectedJourney.id
        })
      }
    })
    toggleDeleteModal(e)
  }

  const update = (idx, req, callbackFn) =>{
    HealthCategoryService.update(req).then((res)=>{
      if(res.status === 204){
        journeyDispatch({
          'type':"UPDATE",
          'idx':idx,
          'payload':req
        })
        callbackFn && callbackFn(req)
      }
    })
  }

  const addDelete = (j) => {
    scope.selectedJourney = j
    toggleDeleteModal()
  }

  const routeJourney = (journey)=>{
    addToJourneyCacheList(journey)
    Cs.routeJourney(journey, props)
  }

  const openNotification = (journey) =>{
    scope.selectedJourney = journey
    toggleNutritionCareModal()
  }

  const routeJourneyForm = (ds) =>{
    journeyDispatch({'type': "CLEAR"})
    Cs.routeJourneyForm(ds, props.history)
  }

  const openUserRole = (journey) => {
    scope.selectedJourney = journey
    toggleUserRole()
  }

  const updateSubFacility = (id) =>{
    dispatch({
      type: "APPEND",
      payload: {current_sub_facility_id: id}
    })
  }

  const undoDelete = (id) =>{
    HealthCategoryService.update({"id":id, "is_active":true}).then((res)=>{
      if(res.status === 204){
        journeyDispatch({
          'type': "DELETE",
          'id': id
        })
      }
    })
  }

  const onBatchDelete = async () =>{
    const {status} = await HealthCategoryService.delete(null, {
      "delete_by_filter":1,
      'user_id':currentUser.id,
      'search': search, 
      'search_by':filter.search_by,
      'is_active':filter.is_active,
      'organization_id':currentUser.current_organization_id,
      'medical_facility_id':currentUser.current_medical_facility_id,
      'filter':filter
    })
    if(status === 204){
      setParams(1); //set page to 1
      getJourneyList('REPLACE');
    }
  }

  if(!currentUser.current_subscription?.journeys){
    return(
      <div className="m-t-100">
        <NotificationPopup title="Sorry, No Permission to access this page right now." iconClass="fas fa-circle-info lred"/>
      </div>
    )
  }

  const caseProps = {
    visibleJourneyList, dataSource, pregnancyStageKv, routeJourney, 
    openNotification, currentUser, openUserRole, labelLocale, 
    update, toggleJourneyMenuModal, undoDelete, assessmentPermission, permission,
    isAssessmentEnabled: roleType,
    onDelete: addDelete,
    currentLocale: currentUser.current_locale
  }

  return (
    <>
      <div id="ct">
        <div className="app-content">
          <div className="app-header flex justspacebetween m-t-15">
            <div className="m-l-5">
              <div className="app-title m-r-20">{labelLocale(1)}</div>
              <div className="underline f16 m-r-15">
                {pagemeta.total} Items
              </div>
              {currentUser.sub_facilities?.length >= 2 ? 
                <select className="form-control input-sm fw500 pull-right"
                  onChange={(e)=>updateSubFacility(e.target.value)}
                  defaultValue={currentUser.current_sub_facility_id}
                  placeholder="SubFacility">
                    <OptionsObjList list={currentUser.sub_facilities} idKey='id' labelKey='name'/>
                </select>
                :
                null
              }
              <ActiveFilters showBatchDelete={permission.batch_delete} filter={filter} setFilter={setFilter} onBatchDelete={onBatchDelete}/>
            </div>
                
            <div className="flex aligncenter rgap-5-xs">
              {permission?.upcoming_schedules ? 
                <NavIcon id="upcoming_schedules" 
                  label="View Upcoming visits" 
                  dataTip={labelLocale(2)} 
                  onSelect={toggleUpcomingSchedulesModal}
                  className="bg-highlight round-btn float-right m-r-5" 
                  localeId="2"
                  icon="far fa-calendar-alt"/>
                : null
              }
                
              <NavIcon id="add_new_journey" 
                label="Launch a new health journey"  
                dataTip={labelLocale(3)} 
                onSelect={toggleJourneyMenuModal}
                className="bg-highlight round-btn float-right m-r-5" 
                localeId="3"
                icon="fas fa-plus"/>

              {permission?.filters ? 
                <FilterBtn filter={filter} setFilter={setFilter} 
                  requestParams={scope.requestParams} 
                  setSearch={setSearch} search={search}
                  dataTip={labelLocale(17)}
                  className="bg-highlight round-btn float-right m-r-5"/>
                : null
              }

              {permission?.csv_download ? 
                <NavIcon id="csv" dataTip={labelLocale(18)}
                  onSelect={()=>downloadAsCsv()}
                  className="bg-highlight round-btn float-right m-l-5" 
                  icon="fas fa-download"/>
                :
                null
              }
            </div>
          </div>
          
          {loading ? 
            <div className="spinner"/>
            :    
            <>
              {screen.xs ?
                <div className="row m-t-30">  
                  <JourneyCards {...caseProps}/>
                </div> 
                :
                <InfantJourneyTable 
                  requestParams={scope.requestParams}
                  getJourneyList={getJourneyList} 
                  journeyList={visibleJourneyList} 
                  journeyDispatch={journeyDispatch} 
                  openUserRole={openUserRole}
                  pregnancyStageKv={pregnancyStageKv}
                  update={update} routeJourney={routeJourney}
                  openNotification={openNotification}
                  labelLocale={labelLocale} 
                  toggleJourneyMenuModal={toggleJourneyMenuModal}
                  currentLocale={currentUser.current_locale}
                  isAssessmentEnabled={roleType && assessmentPermission?.write}
                  undoDelete={undoDelete}
                  assessmentPermission={assessmentPermission}
                  permission={permission}
                  {...props}/>  
              }
              
              {pagemeta.total > 10
                ? <LoadMoreBtn currentPage={pagemeta.current_page}
                    totalItems={pagemeta.total}
                    itemsPerPage={pagemeta.per_page}
                    onPageChange={setCurrentpage}
                    label={labelLocale(15)}
                    onLoadedPageSelect={onLoadedPageSelect}/>  
                : null
              } 
            </>
          } 
        </div>
      </div>

      <CTTooltip />
      <ConfirmMenu isOpen={isOpen} toggleModal={toggleDeleteModal} success={deleteJourney}/>
      {isCommentOpen && 
        <CommentsModal isOpen={isCommentOpen} 
        toggleModal={toggleCommentModal} 
        item={scope.selectedJourney} type="journey_profile"/>
      }
      {isJourneyMenuOpen && 
        <DataSourceMenu toggleModal={toggleJourneyMenuModal} 
        datasourceList={currentUser.current_subscription?.journeys}
        dataSourceId={3419}
        onSelect={routeJourneyForm}/>
      }
      {isUpcomingSchedulesOpen &&
        <GenericModal component={UpcomingSchedulesTable} 
          isOpen={isUpcomingSchedulesOpen} 
          toggleModal={toggleUpcomingSchedulesModal} 
          title={labelLocale(7)} />
      }
      {isUserRoleOpen && 
        <GenericModal
          component={UsersRolesPopup}
          isOpen={isUserRoleOpen}
          toggleModal={toggleUserRole}
          itemType="journey_profile"
          itemId={scope.selectedJourney.id}
          showRoles="true"
          showOrgMembers="true"
          memberRoleId={scope.selectedJourney.member_role_id}
          medicalFacilityId={scope.selectedJourney.medical_facility_id}/>
      }
      {isNutritionCareOpen && 
        <GenericModal component={NutritionCareNotification} 
          toggleModal={toggleNutritionCareModal} 
          isOpen={isNutritionCareOpen}
          itemType="journey_profile" 
          itemUuid={scope.selectedJourney.id} 
          journeyProfileId={scope.selectedJourney.id} 
          mobileNo={scope.selectedJourney.data.cell_number}
          countryCode={scope.selectedJourney.data.country_code}/>
      }
      <SideMenuBar pageIds={['health_cases']}/>
      {isWhatsNewOpen?
        <NewFeatures isOpen={isWhatsNewOpen} toggleModal={toggleWhatsNew}/>
        :
        null
      }
    </>
  )
}

const JourneyCards = (props) => props.visibleJourneyList.length > 0 ? props.visibleJourneyList.map((j, i)=>
    <div className="col-md-4" key={i}>
      <JourneyCard journey={j} index={i} {...props}/>
    </div>
  ):<NotificationPopup title={props.labelLocale(16)}
      onSuccess={props.toggleJourneyMenuModal}  
      iconClass="fas fa-circle-info lred"/>


export default HealthJourneyList;