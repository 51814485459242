let screenDefault = {
  xs:false,
  sm:false,
  md:false,
  lg:false
}

export default function getBreakpoint() {
    const screen = {...screenDefault};
    const w = window.innerWidth;
    screen.width = w;
    screen.height = window.innerHeight;
    (w < 768) ? screen.xs=true : ((w < 992) ? screen.sm=true : ((w < 1200) ? screen.md=true : screen.lg=true));
    return screen;
}