import React, {useState} from 'react';
import {CueTip} from '../../../components/Common/CueTooltip'
import FormHelper from '../../../components/FormBuilder/FormHelpers';
import Cs from '../../../services/CommonService';
import {FieldLabel, Description, RequiredMsg} from '../FieldLabel';
import {RecordAudioSafari, AudioPlayer} from '../../Common/RecordAudio';
import FileUploadService from '../../../services/FileUploadService';

const InputAudioRecording = ({labelLocale, renderField, field, position, formData, readOnly, errors, formFn, isFormWizard, formId, openFieldLabelModal, currentLocale}) => {
  const [model, setModel] = useState(formData[field.client_id]);
  const [showError, setShowError] = useState(false);
  const [fileSizeError, setFileSizeError] = useState(false);
  const [fileVirusError, setFileVirusError] = useState(false);
  const [showUploadProgress, setShowUploadProgress] = useState(false);
  const [loading, setLoading] = useState(false);

  if(!renderField){
    return null
  }

  let inputAttributes = {
    'type':'file',
    'id': 'file_selector_'+field.client_id,
    'name': field.client_id,
    'placeholder': field.placeholder,
    'disabled':readOnly
  }
  
  let parentAttributes = {
    'tooltip': field.tooltip
  }

  let labelAttributes = FormHelper.setLabelStyle(field, isFormWizard, parentAttributes);
  let privacyType = field.privacy_type || 'private';

  const deleteAudio= (file) => {
    try {
      FileUploadService.deleteFileFromUrl(file).then(function(res) {
        if(res.status == 204 || res.status == 404){
          setModel(null)
          formData[field.client_id] = null;
          setError();
        }
      })
    } catch (e) {
      alert(e);
    }
  }

  const onUploadSuccess = ({file_upload}) =>{
    const f = file_upload.url || file_upload.file
    setModel(f);
    formData[field.client_id] = f;
    setError();
  }

  //FormHelper.setDisabled(field, inputAttributes);

  const setError = (event) => {
    if(field.required){
      const name = field.client_id;
      errors[name].invalid = formData[field.client_id]==null?true:false;
      errors[name].touched = true;
      if(errors[name].invalid){
        errors.invalid[name] = true;
      }else{
        delete errors.invalid[name]
      }
      setShowError(!showError);
      formFn.refreshFormSubmit();
    }
  }
  
  const Error = () => {
    if(field.required && (errors[field.client_id].touched || errors.isSubmitted) && errors[field.client_id].invalid){
      return(
        <div className="errormsg">
          <div>
            <span className="fas fa-exclamation-triangle m-r-5"/>
            <RequiredMsg {...{field, currentLocale}}/>
          </div>
        </div>
      )
    }
    return null;
  }

  const FileError = () => {
    return(
      <div className="errormsg">
        {fileSizeError && <div>File Size is Large. Max Size {(field.max_file_size || 2)} MB.</div>}
        {fileVirusError && <div>File Is infected with virus.</div>}
      </div>
    )
  }

  const Audio = () =>  {
    return(   
      <div className="upload-sm-card">
        <span className="upload-delete" onClick={() => deleteAudio(model)}>×</span>
        <span>
          <i className={'f40 '+Cs.getIconByType(model)}/>
          <div>{Cs.getFileName(model)}</div>
        </span>
        <AudioPlayer url={model}/>
      </div>
    )
  }

  return (
    <>
    	<div {...parentAttributes}>
        <div className="flex coldir m-b-20 tooltip">
          <CueTip 
            positionCss={position>1?'top':'bottom'}
            tooltip={field.tooltip}
            currentLocale={currentLocale}
            locale={field.locale?.tooltip}/>
      	  <FieldLabel field={field} 
            isFormWizard={isFormWizard}
            labelAttributes={labelAttributes} 
            currentLocale={currentLocale}
            openFieldLabelModal={openFieldLabelModal}/>
          { model ? 
            <Audio/>
            :
       	    <RecordAudioSafari 
              setLoading={setLoading}
              onUploadSuccess={onUploadSuccess}/>
          }
          <Description description={field.description} currentLocale={currentLocale}
            locale={field.locale?.description}/>
          <Error/>
          <FileError/>
   	    </div>
      </div> 
    </>
  )
}

export default InputAudioRecording;