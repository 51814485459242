import env from '../../../../env';
import axios from 'axios';
import BaseService from '../../../../services/BaseService';

class SubFacilityService extends BaseService {

	async getMemberSubFacilities(req) {
		return await axios.get(`${env.health_backend}health/get_member_sub_facilities`, { params: req });
	}

}

export default new SubFacilityService('health_backend', 'health/medical_sub_facilities');