import React from "react";
import { FormFieldCard } from "../Common/FormFieldsView";

const ReviewFormInputPopup = ({reviewFields, inputData, inputDataSource, onSuccess, toggleModal, okLabel, cancelLabel, footerInfo, formFieldViewEle}) =>{
  return(
    <div id="ct">
      <div className="app-content-section m-t-10">
        <div className="p-15 f15 red br-btm-dgrey">{footerInfo}</div>
        <div className="card-content p-15 scroll-auto">
          <FormFieldCard formFields={reviewFields} data={inputData} dataSource={inputDataSource} formFieldViewEle={formFieldViewEle}/>
        </div>
        <div className="text-center">
          <button type="button" onClick={()=>onSuccess()} className="dropbtn bg-submit tooltip">
            {okLabel}
          </button>
          <button type="button" onClick={()=>toggleModal()} className="dropbtn bg-cancel tooltip">
            {cancelLabel}
          </button>
        </div>
      </div>
    </div>
  )
}

export default ReviewFormInputPopup;