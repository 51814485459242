import env from '../../../env';
import BaseService from "../../../services/BaseService";
import axios from 'axios';

class TrainingSessionService extends BaseService {
	
	async getTrainingSessionByFilter(req) {
		return await axios.get(
			`${env.health_backend}health/autofill/training_sessions`, 
			{"params": req }
		);
	}
};

export default new TrainingSessionService('health_backend', 'health/training_sessions');