import { createContext } from "react";
import GetBreakpoint from "./GetBreakpoint";

export const AuthContext = createContext();
 
let screen = GetBreakpoint();

export const initialState = {
  isAuthenticated: false,
  user: {},
  token: null,
  showPageLoader: true,
  screen: screen
};