import React, {useContext, useState, useEffect} from "react"
import querystringify from "querystringify"
import {AuthContext} from "../../contexts/Authentication/AuthStateProvider"
import AuthenticationService from "../../services/AuthService"
import useStyle from '../../hooks/useStyle'
import {Link} from "react-router-dom"
import Cs from "../../services/CommonService"
import GeoLocation from "../Common/GeoLocation"
import {usePageTitle} from "../../hooks/pageHead"
import env from "../../env"
import useAuth from './useAuth' 

let geoData = {};
const Login = (props) => {
  useStyle('/css/login.css', true) 
  usePageTitle('Login')

  let params = props.match.params
  let queryParam = querystringify.parse(props.location.search)

  const { state: authState, dispatch } = useContext(AuthContext)
  const { user, setUser, handleFormSubmit, routeUser } = useAuth(props.history, dispatch, geoData)
  //const {} = useOTP()
  const [count, setCount] = useState(Math.random())
  const [isGoogleSSOLoaded, setGoogleSSOLoaded] = useState(false)
  const [emailSuggestion, setEmailSuggestion] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  /*useEffect(()=>{*/
    //uncomment jul 31
    //rm - registration_mode
    /*if(queryParam.rm){
      setDefaultRegmode(queryParam.rm === 'phone_no'?'phone_no':'email')
    }else if(localStorage.ct_health_rm){
      setDefaultRegmode(localStorage.ct_health_rm)
    }*/
  /*}, [])*/

  const setDefaultRegmode = (rm) => setUser({...user, registration_mode:rm});

  const handleInputChange = (event) => {
    setUser({
      ...user,
      [event.target.name]: event.target.value,
    })
  }

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const handleEmailChange = (event) => {
    let { name, value } = event.target;

    const typoRegex = /@gmial|@gmali|@gmal|@gmila|@gmil/gi;
    if(typoRegex.test(value)) {
      setEmailSuggestion("Did you mean @gmail.com?");
    }else{
      setEmailSuggestion("");
    }

    setUser({
      ...user,
    [name]:value,
    });
  };
   const correctEmailDomain = () => {
    const correctedEmail = user.email.replace(/@gmial|@gmali|@gmal|@gmila|@gmil/gi, "@gmail.com");
    setUser({
      ...user,
      email: correctedEmail
    });
    setEmailSuggestion("");
  };

  const setAuthType = (type='') => {
    setUser({
      ...user,
      registration_mode: type,
    })
  }
  
  useEffect(() => {
    if(authState.isAuthenticated){
      routeUser(authState.user)
    }
    setAuthType(Cs.getCookie('ct_login'))
    invitationLinkVisited()
  }, [authState.isAuthenticated])

  const invitationLinkVisited = () => {
    if(params.organization_member_id){
      AuthenticationService.inviteLinkVisited(params.organization_member_id)
    }
  }

  function handleCredentialResponse(response) {
    //console.log("Encoded JWT ID token: " + response.credential);
    user.registration_mode = 'google_sso'
    user.token = response.credential
    setUser({...user})
    handleFormSubmit()
  }

  useEffect(() => {
    const scriptTag = document.createElement('script');
    try{
      /*window.addEventListener('resize', (event) => {
        console.log(event)
      });*/

      //window.onGoogleLibraryLoad
     
      scriptTag.src = 'https://accounts.google.com/gsi/client';
      scriptTag.async = true;
      scriptTag.defer = true;
      scriptTag.onload = () => {
        setGoogleSSOLoaded(true);

        window.google.accounts.id.initialize({
          client_id: env.google_sso.client_id,
          callback: handleCredentialResponse
        })
        
        window.google.accounts.id.renderButton(
          document.getElementById("gooleBtn"),
          { 
            theme: "outline", 
            size: "large", 
            width: document.getElementById("gooleBtn")?.offsetWidth,
            height: document.getElementById("gooleBtn")?.offsetHeight,
          }  // customization attributes
        )
        
        //window.google.accounts.id.prompt(); // also display the One Tap dialog
      };
      scriptTag.onerror = () => {
        setGoogleSSOLoaded(false);
      };

      document.body.appendChild(scriptTag);
    }catch(e){
      console.error(e.message)
    }

    return () => {
      document.body.removeChild(scriptTag);
    };
  }, []);

  return (
    <div id="ct">
      {user.isSubmitting ? <div className="spinner"/>:null}
      <GeoLocation geoData={geoData} getAddress="true"/>
      <section className="login-page flex justspacebetween">
        <form className="login-left wid-50 pos-rel flex aligncenter">
          <div className="login-form">
            <h2 className="title m-b-10">Welcome</h2>
            <p className="text-lite-gray m-b-30">
              Select your preferred login method
            </p>
            <div className="wk-center">
              {isGoogleSSOLoaded ?
                <div onClick={()=>setAuthType('google_sso')} id="gooleBtn">
                  Google Login
                </div>
                : null
              }
              
              {queryParam.t == 1 ? 
                <a className="login-btn m-t-10" target="_blank"
                  href="https://wa.me/15550504177?text=Sign me into Cuedwell">
                  <i className="fab fa-whatsapp f16"/> 
                  <span className="m-l-22p f15">Sign in with Whatsapp</span>
                </a>
                :null
              }
                
              <div id="email_login" onClick={()=>setAuthType('email')} className="login-btn m-t-10">
                <i className="far fa-envelope-open f16"/>
                <span className="m-l-22p f15">Sign in with Email</span>
              </div>

              <div id="mobile_login" onClick={()=>setAuthType('phone_no')} className="login-btn">
                <i className="fas fa-mobile-alt f16"/> 
                <span className="m-l-22p f15">Sign in with Phone No</span>
              </div>
            </div>
            
            {['email', 'phone_no'].indexOf(user.registration_mode) > -1 ?
              <>
                <p className="text-lite-gray m-b-10 m-t-20">
                  Enter your sign in details below
                </p>
                {user.registration_mode === 'email' &&
                  <div className="form-fields flex coldir m-b-20">
                    <label className="form-label m-b-10">Email</label>
                    <input
                      type="email"
                      className="form-input fulwid"
                      placeholder="Enter your email"
                      name="email"
                      onChange={handleEmailChange}
                      value={user.email}
                      required
                    />
                    {emailSuggestion && (
                      <p className="email-suggestion" onClick={correctEmailDomain} style ={{ cursor: 'pointer', color: 'red'}}>
                        {emailSuggestion}
                      </p>
                    )}
                  </div>
                }
                {user.registration_mode === 'phone_no' &&
                  <div className="form-fields flex coldir m-b-20">
                    <label className="form-label m-b-10">Mobile</label>
                    <input 
                      className="form-input fulwid"
                      value={user.phone_no}
                      type="number" 
                      defaultValue={user.phone_no}
                      placeholder="9999999999"
                      name="phone_no"
                      onChange={handleInputChange}
                      required/>
                  </div>
                }
                <div className="form-fields flex coldir">
                  <label className="form-label m-b-10">Password</label>
                  <input
                    type={showPassword ? "text" : "password"}
                    className="form-input"
                    placeholder="password"
                    name="password"
                    onChange={handleInputChange}
                    value={user.password}
                    required
                  />
                  <span
                    onClick={togglePasswordVisibility}
                    className="pos-abs r-10 t-75p tf-ty-50p cursor-pointer">
                    {showPassword ? (
                      <i className="far fa-eye-slash"></i>
                    ) : (
                      <i className="far fa-eye"></i>
                    )}
                  </span>
                </div>
                {user.errorMessage && (
                  <p className="m-t-5 error">{user.errorMessage}</p>
                )}
                {user.registration_mode === 'email' ?  
                  <Link to="/forget_password" className="tright m-t-5 m-b-15 text-link">Forgot password</Link>
                  : null
                }
                <p className="f1r m-t-10">
                  By signing in, you agree to the <a href="/about_us/terms_of_service" target="_blank" className="text-link">
                  <strong>Terms</strong>
                  </a> and <a href="/about_us/privacy_policy" target="_blank" className="text-link">
                    <strong>Privacy policy.</strong>
                  </a>
                </p>
                <button disabled={user.isSubmitting}
                  onClick={handleFormSubmit}
                  className="btn btn-primary fulwid m-t-15 f16 fw500 tcenter text-white">
                  Sign in
                </button>
              </>
              :
              null  
            }
            <div className="m-t-10"> 
              {/*<p>
                <Link to="/contact" className="text-link">
                  <strong>Register</strong>
                </Link>
                &nbsp;to request an account.
              </p>*/}
            </div>
          </div>
        </form>
        <div className="login-right tcenter justifycenter wid-50 pos-rel flex aligncenter">
          <div className="login-content">
            <a href="#" className="m-b-20">
              <img className="w-115" src="images/logo/cuetree_Icon.png" alt="" />
            </a>
            <h3 className="fw500 m-b-15">Transform health and wellbeing journeys</h3>
            <p className="text-lite-gray m-b-20 wid-80 w-80 wid-100-xs mx-auto">
               {Cs.displayRandomPhrase(Math.floor(4*count))}
            </p>
            <div className="text-lite-gray badge badge-md badge-content m-b-20 mx-auto fw500">
              From Screening to extended care
            </div>
            <div className="m-t-10 mx-auto">
              <ul className="flex gap-5 justifycenter">
                <li>
                  <a href="#">
                    <img src="images/fb-icon.png" alt="" />
                  </a>
                </li>
                <li>
                  <a href="#">
                    <img src="images/instagram-icon.png" alt="" />
                  </a>
                </li>
                <li>
                  <a href="#">
                    <img src="images/twitter-icon.png" alt="" />
                  </a>
                </li>
                <li>
                  <a href="#">
                    <img src="images/linked-icon.png" alt="" />
                  </a>
                </li>
                <li>
                  <a href="#">
                    <img src="images/whatsapp-icon.png" alt="" />
                  </a>
                </li>
              </ul>
            </div>
            <div className="text-center m-t-50">
              <p className="text-lite-gray f12">
                <i className="m-l-15 m-r-5 far fa-copyright" aria-hidden="true"/> 
                {new Date().getFullYear()} IPIPAL INC. All Rights Reserved.
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Login;