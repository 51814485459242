import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { AuthContext } from "../contexts/Authentication/AuthStateProvider";

const PrivateRoute = ({component: Component, ...rest}) => {
	const { state:{isAuthenticated, redirectAllRouteToUrl, user:currentUser} } = useContext(AuthContext);
    
    if(!isAuthenticated){
    	return(
    		<Route {...rest} render={props => <Redirect to="/signin"/>} />
    	)
    }else if(!currentUser.current_organization && currentUser.organizations.length == 0){
        /*User is not part of any organization. Route to org profile form.*/
  		return(
  			<Route {...rest} render={props => <Redirect to="/profile"/>} />
    	)
    }else if(currentUser.current_organization?.is_active == false){
        /*Organization is Inactive*/
        return(
            <Route {...rest} render={props => <Redirect to="/switch/organization"/>} />
        )
    }else if(
        !currentUser.current_member?.is_tos_agreed
        && currentUser.current_organization?.data?.consent_terms_privacy === 1
      ){
        //User Consent before using tha app
        return(
            <Route {...rest} render={props => <Redirect to="/organization/consent"/>} />
        )
    }else if(!currentUser.current_member || currentUser.current_member?.is_active === false){
        //User is not a member of organization or medical facility
        return(
            <Route {...rest} render={props => <Redirect to="/switch/organization"/>} />
        )
    }/*else if(currentUser.current_member?.home_page?.sid === 'pre_training_assessment'){
        //Only allowed to pre training list page and case registration form
        if(rest.path === '/health/form/:form_id/:id?'){
            return <Route {...rest} render={(props) => <Component {...rest} {...props}/>} />
        }else{
            return <Route {...rest} render={(props) => <PreTrainingCaseList {...rest} {...props}/>} />
        }
    }*/else if(redirectAllRouteToUrl){
        return(
            <Route {...rest} render={props => <Redirect to={redirectAllRouteToUrl}/>} />
        )
        //Don't allow user to other routes unless lock_in_current_page === false or null
    }else{
    	return(
    		<Route {...rest} render={(props) => <Component {...rest} {...props}/>} />
    	)
    }
}

export default PrivateRoute;