import React, { useState } from "react"
import Cs from "../../services/CommonService"
import FormSubmissionReviewService from './FormSubmissionReviewService'
import { UserName } from '../UserProfile/UserInfoPopup'

const FormSubmissionReview = ({editReview, formId, fieldClientId, fieldIndex, itemId, itemType, journeyProfileId, organizationId}) => {
	const [isReviewOpen, toggleReview] = useState(false)
	const [review, setReview] = useState(editReview || {
		'form_id':formId,
		'field_client_id':fieldClientId,
		'field_index':fieldIndex,
		'organization_id':organizationId,
		'item_id':itemId,
		'item_type':itemType,
		'journey_profile_id':journeyProfileId
	})

	const onInputChange = (name, value) =>{
    	setReview({...review, [name]:value})
	}

	const onSubmit = async (e) =>{
		e.preventDefault();
		if(review.id){
			review.status = 'open'
			const {status} = await FormSubmissionReviewService.update(review)	
			toggleReview(false)
		}else{
			const {status, data} = await FormSubmissionReviewService.create(review)
			if(status === 201){
				setReview(data.review)		
				toggleReview(false)
			}
		}
	}

	if(isReviewOpen){
		return(
			<form className="row m-t-5">
				<div className="col-xs-12">
			 		<label className="form-label font-15 m-b-5 req-field">Write Review</label>
			        <textarea maxLength="100" onChange={(e) => onInputChange(e.target.name, e.target.value)}
			          className="form-control" placeholder="Review Message" 
			          name="message" defaultValue={review.message} autoFocus required>
			        </textarea>
			        <div className="text-muted">Maximum 100 Characters</div>
			    </div>
				<div className="col-xs-12 col-sm-6 p-r-5">
					<button className="ml-button ml-block m-t-5 ml-padding bg-highlight white"
						disabled={review.message==null || review.message?.length < 1}
						onClick={(e)=>onSubmit(e)}> 
		        		<b>{review.id?'Update':'Create'}</b>
		      		</button>   
		    	</div>
			    <div className="col-xs-12 col-sm-6 p-r-5">
			    	<button className="ml-button ml-block m-t-5 m-b-5 ml-padding bg-cancel white"
			     		onClick={()=>toggleReview(!isReviewOpen)}> 
			         	<b>Close</b>
			      	</button>   
			    </div> 
	    	</form>
		)	
	}else{
		return (
		  <>
			<span onClick={()=>toggleReview(!isReviewOpen)} className="far fa-comment-alt badge-2 m-t-10 font-16"/>
			<p className="font-15 lred">{review.message}</p>
			<UserName id={review.user?.id}>
			   <p className="font-15 lred">{review.user?.name}</p>
			</UserName>
			{(review.status === 'close' && review.form_updated_at) ? 
				<span>Form updated : {Cs.formatUpdateDate(review.form_updated_at)}</span>
				:
				null
			}
		  </>
		)
	}
	
}

export {FormSubmissionReview}