import React, {useState, Fragment} from 'react';
import {CueTip} from '../../../components/Common/CueTooltip'
import FormHelper from '../../../components/FormBuilder/FormHelpers';
import CommonService from '../../../services/CommonService';
import ImageEditor from "../../ImageEditor/ImageEditor";
import GenericModal from "../../Modals/GenericModal";
import useModal from "../../../hooks/useModal";
import {FieldLabel, Description, RequiredMsg, FieldReview, FieldPoint} from '../FieldLabel';
import {MediaFilterList} from '../../MediaFiles/MediaList'
import FileUploadService from '../../../services/FileUploadService';

let selectedFile = null;

const InputFileUpload = ({labelLocale, renderField, field, position, formData, readOnly, errors, formFn, isFormWizard, formId, openFieldLabelModal, currentLocale, fieldReview}) => {
  const [model, setModel] = useState(formData[field.client_id]);
  const [showError, setShowError] = useState(false);
  const [fileSizeError, setFileSizeError] = useState(false);
  const [fileVirusError, setFileVirusError] = useState(false);
  const [showUploadProgress, setShowUploadProgress] = useState(false);

  const { isOpen:isImageEditorOpen, toggleModal:toggleImageEditorModal } = useModal();
  const { isOpen: isFileSearchOpen, toggleModal: toggleFileSearch} = useModal();

  if(!renderField){
    return null
  }

  let inputAttributes = {
    'type':'file',
    'id': 'file_selector_'+field.client_id,
    'name': field.client_id,
    'placeholder': field.placeholder,
    'disabled':readOnly
  }
  
  let parentAttributes = {
    'tooltip': field.tooltip
  }

  let validation = {required: true}
  let labelAttributes = FormHelper.setLabelStyle(field, isFormWizard, parentAttributes);
  let privacyType = field.privacy_type || 'private';

  const onChange= event => { 
    if('image/svg+xml image/gif'.includes(event.target.files[0].type)){
      uploadFileToUrl(event.target.files, event.target)  
    }else if(field.edit_image){
      selectedFile = event.target.files[0]
      toggleImageEditorModal()
    }else{
      uploadFileToUrl(event.target.files, event.target)  
    }
  }

  const uploadFileToUrl = (files, ele, blob) => {
    try {
      let file = null;
      if(files){
        let FileSize = files[0].size / 1024 / 1024; // in MB
        if (FileSize > (field.max_file_size || 2)) {
          ele.value = null
          setFileSizeError(true)
          return;
        }else{
          file = files[0]
          setFileSizeError(false)
        }  
      }else if(blob){
        file = blob
        toggleImageEditorModal()
      }else{
        return
      }

      if(file){
        setShowUploadProgress(true)
        let imageData = {
          'fileable_type':formFn.form_type, 
          'fileable_id':formId,
          'journey_profile_id':formFn.journey_profile_id
        }
        FileUploadService.uploadFileToUrl(file, privacyType, imageData).then((res)=> {
          if(res.status == 201){
            insertImage(res.data.file_upload)
           /* let data = res.data;
            setModel(data.file_upload.url);
            formData[field.client_id] = data.file_upload.url;
            setError();*/  
          }else if(res.status == 422){
            setFileVirusError(true)
          }
          ele.value = null;
          setShowUploadProgress(false)
        }, (err)=>{
          setShowUploadProgress(false)
        })
      }
    } catch (e) {
      setShowUploadProgress(false)
      console.log(e)
    }
  }

  const removeImage= (file, e) => {
    try {
      e.stopPropagation();
      FileUploadService.deleteFileFromUrl(file).then(function(res) {
        if(res.status == 204 || res.status == 404){
          setModel(null)
          formData[field.client_id] = null;
          setError();
        }
      })
    } catch (e) {
      alert(e);
    }
  }

  const insertImage = (file_upload) =>{
    const f = file_upload.url || file_upload.file
    setModel(f);
    formData[field.client_id] = f;
    setError();
  }

  //FormHelper.setDisabled(field, inputAttributes);
  //FormHelper.checkFieldValidity(field, formData, errors);

  const setError = (event) => {
    if(field.required){
      const name = field.client_id;
      errors[name].invalid = formData[field.client_id]==null?true:false;
      errors[name].touched = true;
      if(errors[name].invalid){
        errors.invalid[name] = true;
      }else{
        delete errors.invalid[name]
      }
      setShowError(!showError);
      formFn.refreshFormSubmit();
    }
  }
  
  const Error = () => {
    if(field.required && (errors[field.client_id].touched || errors.isSubmitted) && errors[field.client_id].invalid){
      return(
        <div className="errormsg">
          <div>
            <span className="fas fa-exclamation-triangle m-r-5"/>
            <RequiredMsg {...{field, currentLocale}}/>
          </div>
        </div>
      )
    }
    return null;
  }

  const FileError = () => {
    return(
      <div className="errormsg">
        {fileSizeError && <div>File Size is Large. Max Size {(field.max_file_size || 2)} MB.</div>}
        {fileVirusError && <div>File Is infected with virus.</div>}
      </div>
    )
  }

  const Image = () =>  {
    return(   
      <div className="upload-sm-card">
        <span className="upload-delete" onClick={e => removeImage(model, e)}>×</span>
        <a href="#">
          <img className="w-85" src={CommonService.getIconByType(model)} alt="img"/>
          <span>{CommonService.getFileName(model)}</span>
        </a>
      </div>
    )
  }

  return (
    <>
    	<div {...parentAttributes}>
        <div className="flex coldir m-b-20 tooltip">
          <CueTip 
            positionCss={position>1?'top':'bottom'}
            tooltip={field.tooltip}
            currentLocale={currentLocale}
            locale={field.locale?.tooltip}/>
      	  <FieldLabel field={field} 
            isFormWizard={isFormWizard}
            labelAttributes={labelAttributes} 
            currentLocale={currentLocale}
            openFieldLabelModal={openFieldLabelModal}/>
     	    { model ? <div><Image/></div> :
            <Fragment>
              {!showUploadProgress &&
                <label className={`butn btn-default w-100p bg-highlight color-white ${readOnly && 'input-readonly'}`} 
                  htmlFor={`file_selector_${field.client_id}`}
                  data-tip data-for={`tooltip_${field.client_id}`}>   
                    {labelLocale(6)}
                </label>
              }
              {showUploadProgress &&
                <label className="butn btn-default w-100p bg-highlight color-white" >   
                  Uploading...
                </label>
              }
              {field.enable_search && !showUploadProgress &&
                <label onClick={()=>toggleFileSearch()} 
                  className={`butn btn-default w-100p bg-highlight color-white m-t-5 ${readOnly && 'input-readonly'}`}>   
                    Search File
                </label>
              }
              <input {...inputAttributes} className="hidden" onChange={e => onChange(e)} />
            </Fragment> 
          }
          <FieldPoint userRefId={formFn.userRefId} clientId={field.client_id} 
            point={field.point} currentLocale={currentLocale}  
            negPoint={field.incorrect_point}/>
          <Description description={field.description} 
            currentLocale={currentLocale}
            locale={field.locale?.description}/>
          <FieldReview review={fieldReview}/>
          <Error/>
          <FileError/>
   	    </div>
      </div> 
      {isImageEditorOpen && 
        <GenericModal
          widthCss="w-100p"
          component={ImageEditor}
          selectedFile={selectedFile}
          saveImage={uploadFileToUrl}
          title="Image Editor"
          isOpen={isImageEditorOpen}
          toggleModal={toggleImageEditorModal}
          minWidth = {field.min_width}
          minHeight = {field.min_height}
          maxWidth = {field.min_width}
          maxHeight = {field.min_height}/>
      }
      {isFileSearchOpen &&
        <GenericModal 
          searchType="data_source"
          searchFormTemplateId={477}
          component={MediaFilterList} 
          title="Media List"
          isOpen={isFileSearchOpen} 
          toggleModal={toggleFileSearch}
          fileableId={null} 
          fileableType={formFn.form_type}
          onFileCloneSuccess={insertImage}/>
      }
    </>
  )
}

export default InputFileUpload;