import React, {useState, useEffect} from "react";
import importScript from '../../hooks/importScript'
import {ApexResponsive} from '../../constants/DataSourceList'

let options = {}

const ApexChart = (props) => {
  let {id, chartType, className, data, title, xlabel, chartCopyRight, chartWidth, chartHeight} = props
  let [legend, setLegend] = useState(props.legend || {show:true, position:'right'})

  useEffect(() => {
    importScript('/scripts/apexcharts/apexcharts.min.js', setChart, window.ApexCharts);

    if(chartType === 'bar'){
      options[id] = {
        series: [{
          name: 'Name',
          data: data
        }],
        chart: {
          type: 'bar',
          height: chartHeight || 380
        },
        legend: legend,
        plotOptions: {
          bar: {
            barHeight: '100%',
            distributed: true,
            horizontal: true,
            dataLabels: {
              position: 'bottom'
            },
          }
        },
        colors: ['rgb(255, 69, 96)', 'rgb(0, 143, 251)', 'rgb(3, 171, 13)', 'rgb(254, 176, 25)', 'rgb(255, 69, 96)', 'rgb(119, 93, 208)'],
        dataLabels: {
          enabled: true,
          textAnchor: 'start',
          style: {
            colors: ['white'],
            fontSize: '12px',
            fontWeight: 100,
          },
          formatter: function (val, opt) {
            return opt.w.globals.labels[opt.dataPointIndex] + ":  " + val
          },
          offsetX: 0,
        },
        stroke: {
          width: 1,
          colors: ['#fff']
        },
        yaxis: {
          labels: {
            show: false
          }
        },
        title: {
          text: title,
          align: 'center',
          floating: true,
          style: {
            fontSize:  '16px',
          },
        },
        tooltip: {
          theme: 'dark',
          x: {
            show: false
          },
          y: {
            title: {
              formatter: function () {
                return ''
              }
            }
          }
        }
      }
    }else if(chartType === 'treemap'){
      legend.show = false

      options[id] = {
        series: [
          {
            data: data
          }
        ],
        legend: legend,
        chart: {
          height: chartHeight || 350,
          type: 'treemap'
        },
        title: {
          text: title,
          align: 'center',
          style: {
            fontSize:  '16px',
          },
        },
        colors: [
          '#3B93A5',
          '#F7B844',
          '#ADD8C7',
          '#EC3C65',
          '#CDD7B6',
          '#C1F666',
          '#D43F97',
          '#1E5D8C',
          '#421243',
          '#7F94B0',
          '#EF6537',
          '#C0ADDB'
        ],
        plotOptions: {
          treemap: {
            distributed: true,
            enableShades: false
          }
        }
      }
    }else if(chartType === 'pie'){
      legend.show = false

      options[id] = {
        series: [],
        chart: {
          width: chartWidth || 475,
          type: 'pie',
        },
        labels: [],
        title: {
          text: title,
          align: 'center',
          style: {
            fontSize:  '16px',
          },
        },
        legend: legend,
        responsive:ApexResponsive.pieChart
      }

      for (let i = 0; i < data?.length; i++) {
        options[id].series.push(parseInt(data[i].y));
        options[id].labels.push(data[i].x || 'Unknown');
      }
    }else if(chartType === 'donut'){
      legend.show = false

      options[id] = {
        series: [],
        chart: {
          width: chartWidth || 475,
          type: 'donut',
        },
        labels: [],
        title: {
          text: title,
          align: 'center',
          style: {
            fontSize:  '16px',
          },
        },
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                name: {
                  show: true,
                },
                value: {
                 show: true,
                }
              }
            }
          }
        },
        legend: legend,
        responsive:ApexResponsive.pieChart
      }

      for (let i = 0; i < data?.length; i++) {
        options[id].series.push(parseInt(data[i].y));
        options[id].labels.push(data[i].x || 'Unknown');
      }
    }

  }, [])

  function setChart(){
    setTimeout(() => {
      options[id].chartEle = new window.ApexCharts(document.querySelector(`#chart_${id}`), options[id]);
      options[id].chartEle.render();  
    }, 0);
  }

  useEffect(()=>{
    if(options[id] && options[id].chartEle){
      options[id].chartEle.updateOptions({legend: legend})
      //window.ApexCharts.exec('chart_'+id, 'updateLegends', {legend: legend}, false, true);
    }
  }, [legend])

  return(
    <div className={className}>
      <div id={'chart_'+id}/>
      <div className="flex justspacebetween coldir-xs rowrev">
        <button className="btn bg-grey m-b-15 m-r-10" 
          onClick={()=>setLegend({...legend, show:!legend.show})}
        >Legends</button>
        <div className="font-12 lgrey">
          <i className="m-l-15 m-r-5 far fa-copyright" aria-hidden="true"/> 
          {new Date().getFullYear()} IPIPAL Inc. 
          All rights reserved.
        </div>
      </div>
    </div>
  )
 
}

export default ApexChart;