import React, {useState, useEffect, useMemo} from "react"
import {Link} from "react-router-dom"
import useStyle from "../../../hooks/useStyle"
import useModal from "../../../hooks/useModal"
import GenericModal from '../../Modals/GenericModal'
import PaginationSM from '../../Common/PaginationSM'
import {EmptyRowAlert} from '../../Common/TableView'
import CheckAccess from '../../Roles/CheckAccess'
import NotesService from '../../../services/NotesService'
import NoteCard from './NoteCard'
import { useCurrentUserHook } from "../../Authentication/useUserHook"

const FormFieldNotesList = ({formId, field, permission}) => {
  useStyle('/css/tile_components.css', true)

  const [requestParams, setRequestParams] = useState({
    'page':1,
    'per_page':15,
    'noteable_type':'form_field',
    'noteable_field_id':field.index,
    'form_fields_params':{
      'ids':[584]
    }
  })
  const [loading, setLoading] = useState(true)
  const [currentpage, setCurrentpage] = useState(1)
  const [pagemeta, setPagemeta] = useState({})
  const [notes, setNotes] = useState([])
  let [formProps, setFormProps] = useState([])
  const [formFields, setFormFields] = useState([])

  const { isOpen: isFormOpen, toggleModal: toggleFormOpen} = useModal()

  useEffect(() => {
    requestParams.page = currentpage
    getAll()
  }, [currentpage])

  const getAll = async () =>{
    if(permission?.read){
      setLoading(true)
      const {status, data} = await NotesService.getAll(requestParams)
      if(status === 200){
        setPagemeta(data.meta)
        setFormFields(data.form_fields)
        setNotes(data.notes)
        setLoading(false)
      }
    }
  }

  const onDelete = async (id)=>{
    if(permission?.write){
      setLoading(true)
      const {status} = await NotesService.delete(id)
      if(status === 204){
        setNotes(notes.filter(n=>n.id!==id))
        setLoading(false)
      }
    }
  }

  /*
  const onFormSubmit = () =>{
    toggleFormOpen()
  }

  const addNotes = (id) =>{
    setFormProps({
      'onFormSubmit':onFormSubmit,
      'isPopupMode':true,
      'isOpen':isFormOpen,
      'toggleModal':toggleFormOpen,
      'match':{
        'params':{
          'id': id,
        }
      },
      'location':{
        'search':`?annotation_form_id=${formId}&field_index=${field.index}`,
      }
    })
    toggleFormOpen()
  }

  if(isFormOpen){
    return <GenericModal component={Form} {...formProps}/>
  }*/

  const FormFieldNotesList = () => notes && notes.map((item, i) => (
    <div className="col-md-4" key={i}>
      <NoteCard note={item} formFieldsList={formFields} onDelete={onDelete} index={i}/>
    </div>
  ))

  return (
    <div id="ct">
      {loading ? 
          <div className="spinner"/>
          :
          <div className="p-5">
            <div className="app-header flex justspacebetween m-t-10">
              <div className="flex coldir">
                <div className="app-title m-r-20">Annotated References</div>
                <div className="font-15">{pagemeta?.total} Items</div>
              </div>
              <div className="flex aligncenter">
                {permission?.write ?
                  <Link data-tip="Add Notes" 
                    to={`/add/formfield/notes/${field.formTemplateId}?noteable_type=form_field&noteable_field_id=${field.index}`}
                    target="_blank"
                    className="bg-highlight round-btn float-right m-l-15 m-t-4">
                      <i className="fas fa-plus"/>
                  </Link>
                  :
                  null
                }
              </div>
            </div>

            <div className="row m-b-30 m-t-10">
              {notes.length === 0 ? <EmptyRowAlert/>:<FormFieldNotesList/>}

              <PaginationSM
                className="pagination-bar"
                currentPage={currentpage}
                totalItems={pagemeta.total}
                itemsPerPage={pagemeta.per_page}
                onPageChange={setCurrentpage}
                siblingCount={2}/>
            </div>
          </div>
      }
    </div>
  )
}

const FieldAnnotationBtn = ({field, formId, className}) => {
  const {currentUser} = useCurrentUserHook()
  //const currentOrgMember = currentUser?.current_member
  const css = className || 'm-l-15 font-18'
  const { isOpen: isNotesOpen, toggleModal: toggleNotesOpen} = useModal()
  const permission = useMemo(() => 
    CheckAccess.getMemberPermissionBySid(currentUser, 'form', 'field_annotation'), [currentUser.id]
  )

  if(permission?.read){
    return(
      <>
        <span className={css} onClick={(e)=>{e.stopPropagation();toggleNotesOpen()}}>
          <i className="far fa-sticky-note f18"/>
        </span>

        {isNotesOpen &&
          <GenericModal component={FormFieldNotesList} 
            isOpen={isNotesOpen} title="Notes" widthCss="w-99p"
            toggleModal={toggleNotesOpen}
            field={field} formId={formId}
            permission={permission}/>
        }
      </>
    )    
  }

  return null
  
}

export {FormFieldNotesList, FieldAnnotationBtn};