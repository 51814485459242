import React from "react";
import useModal from "../../hooks/useModal";
//check useToggle

const useFilterHook = ({filter = {}}) => {

  const getCount = (name) => {
    const filterValue = filter[name];
    return Array.isArray(filterValue) ? filterValue.length : (filterValue ? 1 : 0);
  }

  return {
    getCount
  }

}

const FilterAccordionPanel = (props) => {
  const { title, count, isOpen:isOpenDefault } = props;
  const { isOpen, toggleModal } = useModal(isOpenDefault);

  return(
    <>
      <h5 className="text-center sub-title" onClick={()=>toggleModal()}>
        <span className={'pull-left m-t-4 m-l-5 ' + (isOpen ? 'fas fa-angle-down' : 'fas fa-angle-right')} />
        <span className="m-l-10 font-16">{title}</span>
        {count > 0 && (
          <span className="accordion-count">{count}</span>
        )}
      </h5>
      {isOpen ? <div>{props.children}</div> : null}
    </>
  ) 
}

const FilterPill = ({ label, count,filterKeys, filter, setFilter}) => {
  
  const clearSectionFilters = () => {
    filterKeys.forEach(key => delete filter[key]);
    setFilter({ ...filter });
  };
  return (
    count > 0 ? (
      <span className="filter-pill">
        {label} ({count})
        <button className="close-pill-btn" onClick={clearSectionFilters}>
          &times;
        </button>
      </span>
    ) : null
  );
};

export {FilterAccordionPanel, useFilterHook, FilterPill};