import React, { useEffect, useState, Fragment } from 'react';
import useStyle from '../../hooks/useStyle';
import { Link } from "react-router-dom";
import env from '../../env';
import TrainingProgramsService from './TrainingProgramsService';
import { useReadStatus } from '../ReadStatus/useReadStatusHook';
import { usePageLocale } from "../../hooks/useLocale";
import { ParaRichTextView } from '../Common/RichTextView';
import { useCurrentUserHook } from '../Authentication/useUserHook';

let profileImage = 'images/avatars/2s.png';

const TraineeUserManager = (props) => {
  useStyle('/css/card.css', true);

  const {currentUser} = useCurrentUserHook()
  const [trainingPrograms, setTrainingPrograms] = useState([]);
  const [assessments, setAssessments] = useState([]);
  const {updateReadStatus} = useReadStatus();

  const { labelLocale:pageLocale, localeDispatch } = usePageLocale(currentUser.current_locale, "userTrainingPageLocale");

  useEffect(() => {
    getAll();
  }, [currentUser.id])

  let getAll = () => {
    TrainingProgramsService.getTraineeContent().then(({status, data})=>{
      if(status === 200){
        setTrainingPrograms(data.training_programs);
        setAssessments(data.assessments || []);
        localeDispatch({
          'type': "UPDATE",
          'payload': {
            'userTrainingPageLocale': data.page_locales.training_user_screens,
          }
        })
      }
    })
  }

  const getProfileImg = (n) =>{
    if(n.sender && n.sender.profile_image_url){
      const userImg = env.file_url+n.sender.profile_image_url;
      return userImg;
    }
    return profileImage;
  }

  const openProgram = (program) =>{
    if(program.is_viewed == 0)
      updateReadStatus(null, {'item_id':program.id, 'item_type':'training_program'});
  }

  const TrainingProgramsView = ({}) => trainingPrograms.map((n, k) =>  (
    <Fragment key={k}>
      <div className="card-title flex justspacebetween border-dashed-sm m-t-20 p-b-15">
        <div className="flex">
          <div className="card-icon brd-100p card-icon-brown-bg flex aligncenter justifycenter m-r-15">
            <img src="" className="icon-gray" width="50"/>
          </div>
          <div>
            <h4 className="fw700 f14 card-heading">{n.data.program_name || n.data.host}</h4>
            <p className="text-gray f12 m-b-0">{n.data.location} </p>
            <p className="text-gray f12 m-b-0">{n.data.start}</p>
            {n.data.is_public == 1 ?
              <a className="flex aligncenter m-t-10" target="_blank"
                href={`https://wa.me?text=${window.location.origin}/user/training_program/${n.id}?org_id=${currentUser.current_organization_id}`}>
                  <i className="fab fa-whatsapp color-whatsapp f24"/> 
                  <span className="m-l-10 f14 underline">{pageLocale(4)}</span>
              </a>
              : null
            }
          </div>
        </div>
        <div className="flex aligncenter justifycenter" onClick={()=>openProgram(n)}>
          <span className="badge-2 m-r-10">{n.view_count}</span>
          <Link to={`/user/training_program/${n.id}`} className="flex aligncenter justifycenter brd-10">
          <i className="fas fa-angle-right f16"></i>
          </Link>
        </div>
      </div>
    </Fragment>
  ))

  const RecentAssessmentsView = ({}) => assessments.map((n, k) =>  (
    <Fragment key={k}>  
      <div className="card-title flex justspacebetween border-dashed-sm m-t-20 p-b-15">
        <div className="flex">
          <div className="card-icon brd-100p card-icon-brown-bg flex aligncenter justifycenter m-r-15">
            <img src={getProfileImg(n)} className="icon-gray" width="50"/>
          </div>
          <div>
            <h4 className="fw700 f14 card-heading">{n.data.subject_name}</h4>
            <p className="text-gray f10 m-b-0">
              {n.data.date_time}
            </p>
            <ParaRichTextView className="text-gray f10 m-b-0" html={n.data.details} ></ParaRichTextView>
          </div>
        </div>
        <Link to={`/question/${n.id}?is_preview=true`} className="arrow-icon flex aligncenter justifycenter brd-10">
          <i className="fas fa-angle-right f16"></i>
        </Link>
      </div>
    </Fragment>
  ))

  return (
    <Fragment>
      <div id="ct">
        <div className="app-content">
          <div className="app-header flex justspacebetween m-t-15">
            <div className="flex aligncenter">
              <div className="app-title m-r-20" title="Training Dashboard">{pageLocale(1)}</div>
            </div>
            <div className="flex aligncenter">
              {/*<div className="badge badge-sm brd-30 badge-dark text-white fw900 f12">
                This Month
              </div>*/}
            </div>
          </div>
          <div className="app-content-section m-t-30 m-t-0-xs">
            <div className="row">
              <div className="col-md-6">
                <div className="card card-highlite card-yellow-bg flex aligncenter justspacebetween pos-rel m-b-15">
                  <div>
                    <h4 className="fw700 card-heading" title="Programs">{pageLocale(2)}</h4>
                  </div>
                  <div className="card-number m-r-10">{trainingPrograms.length}</div>
                </div>
              </div>
              {/*<div className="col-md-6">
                <div className="card card-highlite card-blue-bg flex aligncenter justspacebetween pos-rel m-b-15">
                  <div>
                    <h4 className="fw700 card-heading">Review</h4>
                    <a href="/list/assessments/" className="f12">Tab to view more</a>
                  </div>
                  <div className="card-number m-r-10">{assessments.length}</div>
                </div>
              </div>*/}
            </div>
            <div className="row m-t-30">
              <div className="col-md-6">
                <div className="bg-white brd-10 p20 m-b-15">
                  <div className="card-title flex aligncenter border-dashed-sm p-b-15">
                    <div className="card-icon brd-100p card-icon-brown-bg flex aligncenter justifycenter m-r-15">
                      <img src="./images/pin-icon.png" alt="" />
                    </div>
                    <div>
                      {/*Most Recent Training Program entries*/}
                      <h4 className="fw700 card-heading">{pageLocale(3)}</h4>
                      <p className="text-gray f12">Over all</p>
                    </div>
                  </div>
                  <div className="card-content">
                    <TrainingProgramsView/>
                  </div>
                </div>
              </div>
              {/*<div className="col-md-6">
                <div className="bg-white brd-10 p20 m-b-15">
                  <div className="card-title flex aligncenter border-dashed-sm p-b-15">
                    <div className="card-icon brd-100p card-icon-brown-bg flex aligncenter justifycenter m-r-15">
                      <img src="./images/pin-icon.png" alt="" />
                    </div>
                    <div>
                      <h4 className="fw700 card-heading">Most Recent Training Quiz entries</h4>
                      <p className="text-gray f12">Over all journey</p>
                    </div>
                  </div>
                  <div className="card-content">
                    <RecentAssessmentsView/>
                  </div>
                </div>
              </div>*/}
            </div>
          </div>
        </div>
      </div>      
    </Fragment>
  )
}

export default TraineeUserManager;