import React, { useState, useEffect } from 'react';
import FilterSlider from "../../Common/FilterSlider";
import { CheckBox } from "../../Common/FormInput";
import useModal from '../../../hooks/useModal';
import { useToggle } from '../../../hooks/useToggle';
import Cs from "../../../services/CommonService";
import RoleService from "../../Roles/RoleService";
import FormHelper from "../../FormBuilder/FormHelpers";
import { useMedicalFacilityAutofillHook } from "../../Organizations/MedicalCareFacility/useMedicalFacilityApi";
import useTrainingProgramHook from '../useTrainingProgramHook';
import { FilterAccordionPanel, FilterPill, useFilterHook } from '../../Common/AccordionPanel';
import { ClearFiltersBtn } from '../../Common/Button';

const Filters = ({openSideNav, trainingSessions, selectionStatus, filter, setFilter, preFilter, organizationId, f2fTraineeIssues, requestParams, getList, pageViewSid}) =>{
  const [loading, setLoading] = useState(true);
  const [roles, setRoles] = useState([]);
  const [filterKv, setFilterKv] = useState({});
  const {toggleIdx, toggle:togglePan} = useToggle();
  const {getCount} = useFilterHook({filter});

  const {
    dataSource,
    getFilters,
    getTaluka,
    getVillages,
    getDepartmentPkId,
    talukas, setTalukas,
    villages, setVillages
  } = useMedicalFacilityAutofillHook({filter, setLoading, organizationId});

  useEffect(() => {
    getFilters();
  }, [])

  if(!openSideNav) return null;
  
  const getRoles = async ()=>{
    if(!filter.department) return;
    const req = {
      'organization_id':organizationId,
      'department_dsl_id':getDepartmentPkId(filter.department)
    }
    const {status, data} = await RoleService.getRoleAutofill(req);
    if(status === 200){
      setRoles(data);
    }
  }

  const onInputChange = (value, name) => {
    if(value && value != filter[name]){
      filter[name] = value;
    }else{
      delete filter[name];
    }
    setFilter({...filter});
  }

  const toggleFilter = (value, name) => {
    if(value!=null && value != filter[name]){
      filter[name] = value;
      setFilter({...filter});
    }else{
      delete filter[name];
      setFilter({...filter});
    }
    if(name === 'department') {
      delete filter.talukas;
      delete filter.role_record_ids;
      delete filter.villages;

      setRoles([]);
      setTalukas([]);
      setVillages([]);

      setFilter({...filter});

      getRoles();
      getTaluka();
    }
  }

  const filterExists = (value, name) => filter[name] == value;
  
  const toggleListFilter = (value, name) => {
    const setter = (list) => {
      if(list?.length === 0){
        delete filter[name]
        setFilter({...filter});
      }else{
        setFilter({...filter, [name]:list});
      }
      if(name === 'talukas'){
        if(filter.department == "2"){
          //ICDS
          getVillages({'talukas':list});
        }
      }
    }
    FormHelper.toggleListString(value, filter[name], setter);
  }

  const filterListExists = (value, name) => FormHelper.existsListString(value, filter[name]);

  return (
    <div className="filter-box-scroll p-5">

      {loading ? <div className="spinner"/> : null}

      <div>
        <div className="bg-white">
          <FilterAccordionPanel
            title="Status"
            count={getCount('selection_status') + getCount('email_is_null') + getCount('org_member_is_null') + getCount('no_login')}
            isOpen={filter.selection_status || filter.email_is_null || filter.org_member_is_null || filter.no_login}>
            <div className="mxh-250 scroll-auto m-t-10">
              <CheckBox list={selectionStatus}
                labelKey="label" valueKey="sid"
                toggle={toggleFilter} exists={filterExists} name="selection_status" />

              <CheckBox list={[{ "label": "No Email", "sid": true }]}
                labelKey="label" valueKey="sid"
                toggle={toggleFilter} exists={filterExists} name="email_is_null" />

              <CheckBox list={[{ "label": "No Activation", "sid": true }]}
                labelKey="label" valueKey="sid"
                toggle={toggleFilter} exists={filterExists} name="org_member_is_null" />

              <CheckBox list={[{ "label": "No Login", "sid": true }]}
                labelKey="label" valueKey="sid"
                toggle={toggleFilter} exists={filterExists} name="no_login" />
            </div>
          </FilterAccordionPanel>
        </div>

        <TrainingSessionsFilter 
          organizationId={organizationId}
          toggleFilter={toggleFilter}
          filterExists={filterExists} 
          trainingSessions={trainingSessions}
          filter={filter}/>

        <div className="bg-white">
          <FilterAccordionPanel
            title="Departments"
            count={getCount('department')}
            isOpen={filter.department}>
            <div className="mxh-250 scroll-auto m-t-10">
              <CheckBox list={dataSource.health_department_india?.options || []}
                labelKey="label" valueKey="value"
                toggle={toggleFilter} exists={filterExists} name="department" />
            </div>
          </FilterAccordionPanel>
        </div>
        
        <div className="bg-white">
          <FilterAccordionPanel
            title="Roles"
            count={getCount('role_record_ids')}
            isOpen={filter.role_record_ids}>
            <div className="mxh-250 scroll-auto m-t-10">
              <CheckBox list={roles}
                labelKey="role" valueKey="record_id"
                toggle={toggleListFilter} exists={filterListExists} name="role_record_ids" />
            </div>
          </FilterAccordionPanel>
        </div>

        <div className="bg-white">
          <FilterAccordionPanel
            title="Taluka/Block/Project"
            count={getCount('talukas')}
            isOpen={filter.talukas}>
            <div className="mxh-250 scroll-auto m-t-10">
              <CheckBox list={talukas}
                labelKey="taluka" valueKey="taluka"
                toggle={toggleListFilter} exists={filterListExists} name="talukas" />
            </div>
          </FilterAccordionPanel>
        </div>

        {filter.department == "2" ?
          <div className="bg-white">
            <FilterAccordionPanel
              title="Sector/Village"
              count={getCount('villages')}
              isOpen={filter.villages}>
              <div className="mxh-250 scroll-auto m-t-10">
                <CheckBox list={villages}
                  labelKey="village" valueKey="village"
                  toggle={toggleListFilter} exists={filterListExists} name="villages" />
              </div>
            </FilterAccordionPanel>
          </div>
          : null
        }

        <div className="bg-white">
          <FilterAccordionPanel
            title="Record User Activity"
            count={getCount('f2f_training_issue')}
            isOpen={filter.f2f_training_issue}>
            <div className="mxh-250 scroll-auto m-t-10">
              <CheckBox list={f2fTraineeIssues}
                labelKey="label" valueKey="sid"
                toggle={toggleFilter} exists={filterExists}
                name="f2f_training_issue" />
            </div>
          </FilterAccordionPanel>
        </div>

      </div>
      <div className="p-5 m-b-10">
        <ClearFiltersBtn
          onSelect={() => { setFilter({}); }}>
          Clear all filters
        </ClearFiltersBtn>
      </div>
    </div>
  )
}

const FilterBtn = (props) =>{
  const { isOpen:isFilterOpen, toggleModal:toggleFilterModal } = useModal();
  const filterCount = Cs.objCountNonNull(props.filter);
  
  return(
    <>
      <span data-tip="Filter"
        className="bg-highlight round-btn float-right m-l-15"
        onClick={() => toggleFilterModal()}>
        <i className="fas fa-sliders-h" />
        {filterCount > 0 ? <span className="badge-2 bg-black-light-1 up">{filterCount}</span> : ''}
      </span>

      <FilterSlider component1={Filters}
        openSideNav={isFilterOpen}
        setOpenSideNav={toggleFilterModal}
        title="Filters (Field Selection)"
        {...props} />
    </>
  )
}

const TrainingSessionsFilter = ({organizationId, toggleFilter, filterExists, trainingSessions, filter}) =>{
  let {
    list:trainingProgramList,
    getTrainingSessionByFilter
  } = useTrainingProgramHook({"organization_id": organizationId});

  const {getCount} = useFilterHook({filter});

  useEffect(() => {
    if(!trainingSessions){
      getTrainingSessionByFilter({
        "assigned_org_id": organizationId,
        "is_f2f_training": '1'
      })
    }
  }, [])

  return (
    <div className="bg-white">
      <FilterAccordionPanel
        title="Trainee Program"
        count={getCount('training_session_id')}
        isOpen={filter?.training_session_id}>
        <div className="mxh-250 scroll-auto m-t-10">
          <CheckBox
            list={trainingSessions ? trainingSessions : trainingProgramList}
            labelKey="info"
            valueKey="id"
            toggle={toggleFilter}
            exists={filterExists}
            name="training_session_id"
          />
        </div>
      </FilterAccordionPanel>
    </div>
  )
}

const ActiveFilters = ({filter ,setFilter}) => {

  const {getCount} = useFilterHook({filter});

  return (
    <div>
      <FilterPill filter={filter} setFilter={setFilter}
        label="Status"
        count={getCount('selection_status') + getCount('email_is_null') + getCount('org_member_is_null') + getCount('no_login')}
        filterKeys={['selection_status', 'email_is_null', 'org_member_is_null', 'no_login']}
      />

      <FilterPill filter={filter} setFilter={setFilter}
        label="Department"
        count={getCount('department')}
        filterKeys={['department']}
      />

      <FilterPill filter={filter} setFilter={setFilter}
        label="Record User Activity"
        count={getCount('f2f_training_issue')}
        filterKeys={['f2f_training_issue']}
      />

      <FilterPill filter={filter} setFilter={setFilter}
        label="Z-score (Weight)"
        count={getCount('weight_zscore_label_in')}
        filterKeys={['weight_zscore_label_in']}
      />

      <FilterPill filter={filter} setFilter={setFilter}
        label="Roles"
        count={getCount('role_record_ids')}
        filterKeys={['role_record_ids']}
      />

       <FilterPill filter={filter} setFilter={setFilter}
        label="Taluka/Block/Project"
        count={getCount('talukas')}
        filterKeys={['talukas']}
      />

      <FilterPill filter={filter} setFilter={setFilter}
        label="Trainee Program"
        count={getCount('training_session_id')}
        filterKeys={['training_session_id']}
      />

      <FilterPill filter={filter} setFilter={setFilter}
        label="Sector/Village"
        count={getCount('villages')}
        filterKeys={['villages']}
      />
  </div>
  );
};

export {Filters, FilterBtn, TrainingSessionsFilter, ActiveFilters};