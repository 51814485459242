import React from "react";
import { useMemo } from "react";
//import ActivityMenuModal from "../MotherInfantJourney/Activities/List/ActivityMenuModal"
import { PhoneIcon, MapIcon, LinkMapIcon, LinkMailIcon, LinkUserIcon, LinkDeleteIcon, LinkFontAwesomeIcon } from "../../Common/ImageIcons";
import Cs from '../../../services/CommonService';
import { NotificationBadge } from "../../Common/BadgePill";
import { Path, MultiplePregnancy, WeightZscorePill, StatuDropDown, RecordId, HighRiskPregnancy } from './Common';
import { UserName } from '../../UserProfile/UserInfoPopup';
import { useActivityHook } from "../MotherInfantJourney/Activities/List/useActivityHook";

const MobileRequired = ({labelLocale}) => <font className="c-bemedian">{labelLocale(13)}</font>;
const BabyNameRequired = ({labelLocale}) => <font className="c-bemedian">{labelLocale(12)}</font>;
const AddressRequired = ({labelLocale}) => <font className="c-bemedian">Address Not Provided</font>;

const Infant = (props) => {
  let {journey, permission, dataSources, onDelete, undoDelete, openUserRole, routeJourney, openNotification, index, update, pregnancyStageKv, currentLocale, labelLocale, isAssessmentEnabled} = props;
  let {data, data_source:ds} = journey;
  let child = journey.child || {data:{}};
  
  const {ageFormat} = useActivityHook(child);
  const babyAgeAgo = useMemo(() => ageFormat(null, Cs.getCurrentDateTime()), [journey.id]);

  const addressInfo = (data.taluka || data.address || '') +' '+ (data.village || '') +' '+ (data.awc_name_number || '');
  const mobileNo = (data.cell_number_formatted || data.cell_number);
  
  return(
    <div className="bg-white brd-10 card-shadow m-b-15">
      <div className="card-content p-15">
        <div className="scroll-auto ht-165">
          <div className="flex justspacebetween">
            <h4 className="flex cgap-10 aligncenter text-left">
              <span className="f18">{journey.record_id}</span> 
              - 
              <HighRiskPregnancy isHighRisk={data.is_high_risk_pregnancy} />
              <span className="f18">{data.mother_name || data.patient_name}</span>
            </h4>
            <StatuDropDown id={journey.id} status={journey.status} statusReason={journey.status_reason} {...{update, index, currentLocale}}/>
          </div>
          
          <div className="flex aligncenter cgap-10 m-b-10 f15">
            <span>
              {child.data.baby_name ? ` ${child.data.baby_name}` : <BabyNameRequired labelLocale={labelLocale}/>}
            </span>
            <WeightZscorePill j={journey}/>
          </div>
        
          <div className="flex aligncenter m-b-10">
            <div className="flex aligncenter m-r-20">
              <PhoneIcon title={mobileNo || <MobileRequired labelLocale={labelLocale}/>}/>
            </div>
            <div className="flex aligncenter m-r-20">
              <MapIcon title={addressInfo || <AddressRequired labelLocale={labelLocale}/>}/>
            </div>
            <div className="flex aligncenter">
              <MultiplePregnancy child={child}/>
            </div>
          </div>

          <div className="flex aligncenter m-b-10">
            <div className="flex coldir m-r-20">
              <UserName className="dblock" id={journey.last_update_by} role={journey.member_role}>
                {journey?.last_updated_user?.name}
              </UserName>
              <div>{journey?.member_role?.role}</div>
            </div>
            <div className="flex aligncenter m-r-20">
              {Cs.formatUpdateDate(journey.last_update_at)}
            </div>
          </div>

          <div className="dblock">
            {babyAgeAgo}
            <Path j={journey} pregnancyStageKv={pregnancyStageKv} currentLocale={currentLocale}/>
          </div>
        </div>
        <div className="m-t-10 d-flex justspacebetween aligncenter">
          <RecordId j={journey}/> 
          {journey.status != 'closed' && 
            <div className="flex aligncenter ml-auto">
              <LinkMapIcon id="path_details" 
                tooltip={labelLocale(19)}
                onSelect={()=>routeJourney(journey)}>
                  <NotificationBadge 
                    count={journey.schedule_today_count}/>
                  <NotificationBadge
                    position="bottom" 
                    count={journey.note_count + journey.audio_file_count}/>
              </LinkMapIcon>
              {journey.nutrition_care_alert_count > 0 &&
                <LinkMailIcon id="notification" 
                  count={journey.nutrition_care_alert_count} 
                  tooltip={labelLocale(23)}
                  onSelect={()=>openNotification(journey)} 
                  className="far fa-envelope m-l-10 font-18 text-muted"/>
              }
              <LinkUserIcon count={journey.shared_journey_count} tooltip={labelLocale(20)} onSelect={()=>openUserRole(journey)}/>
              {permission?.delete 
                ? <>{journey.is_active 
                      ? <LinkDeleteIcon id="delete_journey" tooltip={labelLocale(21)} onSelect={()=>onDelete(journey)}/>
                      : <LinkDeleteIcon id="undo_delete" onSelect={()=>undoDelete(journey.id)}/>
                    }
                  </>
                : null
              }
              {isAssessmentEnabled ? 
                <LinkFontAwesomeIcon 
                  count={journey.assessment_count}
                  link={`/case/${journey.id}/assessments/${journey.created_by}`} 
                  iconCss="font-16 white far fa-list-alt" 
                  parentCss="table-icon-lgrey m-l-10"
                  tooltip={labelLocale(22)}/>
                :
                null
              }
              {journey.child ?
                <LinkFontAwesomeIcon id="chart" 
                  tooltip={labelLocale(24)}
                  link={{
                    pathname: `/health/chart/${journey.id}`, 
                    state: {journey:journey, child: child},
                  }}
                  parentCss="table-icon-lgrey m-l-10"
                  iconCss="font-18 white fas fa-chart-line" />
                :
                null
              }
            </div>
          }
        </div>
      </div>
    </div>
  )  
}

export default Infant;