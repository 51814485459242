import React, { useState, useEffect } from "react"
import OrganizationMemberService from "./OrganizationMemberService"
import { LeafletMap } from '../../Common/Leaflet'
import GenericModal from '../../Modals/GenericModal'
import { LinkFontAwesomeIcon } from "../../Common/ImageIcons"
import useModal from "../../../hooks/useModal"
import FH from "../../FormBuilder/FormHelpers"
import { useCurrentUserHook } from "../../Authentication/useUserHook"

const MemberGeoDataModal = ({filter={}, organizationId, medicalFacilityId, memberUserId}) => {

  const {currentUser} = useCurrentUserHook()
  const [geoData, setGeoData] = useState({})
  const [loading, setLoading] = useState(true)
  const [dataType, setActiveType] = useState({'journey':true, 'measure_growth':true})
  let [group, setGroup] = useState()
  let [map, setMap] = useState()
  let [journeyLabelColors, setJourneyLabelColors] = useState(['#00A2FF', 'green', 'red'])
  let [growthLabelColors, setGrowthLabelColors] = useState(['rgb(245, 77, 89)', '#DECE06', '#00a86b', '#00A2FF'])
  const [position, setPosition] = useState({'latitude':19.997454, 'longitude':73.789803})

  useEffect(() => {
    if(filter.report_type === "geo_location"){
      getGoeData()
    }
  }, [filter]);

  useEffect(() => {
    if(map)toggleGeoData();
  }, [journeyLabelColors, growthLabelColors])

  const getGoeData = () => {
    const req = {
      organization_id:organizationId,
      user_id:memberUserId,
      ...filter
    }
    OrganizationMemberService.getGeoData(req).then(({status, data})=>{
      if(status === 200){
        const orgData = currentUser.current_organization.data
        if(orgData?.latitude && orgData?.longitude){
          setPosition(orgData)
        }
        setGeoData(data)
        setLoading(false)
        if(group){
          group.clearLayers();
          addCustomMarker(map, data)
        }
      }
    })
  }

  const marketStyle = {
    'journey':{}, 
    'measure_growth':{'borderRadius': '1rem 1rem 1rem'}
  }

  const getMarkerHtmlStyles = (color, key, toString=true) =>{
    if(toString)
      return `background-color: ${color};border-radius: ${marketStyle[key].borderRadius}`
    else
      return {'backgroundColor': color, 'borderRadius': marketStyle[key].borderRadius}
  }

  const addCustomMarker = (map, geoMap=geoData) =>{
    setMap(map)
    const markerArray = []
    for (const key in geoMap) {
      const lits = geoMap[key]
      let i = lits?.length || 0
      
      while(i--) {
        const g = lits[i]
        if(key === 'journey'){
          g.color = g.color || '#00A2FF'
        }
        if(key === 'measure_growth'){
          g.color = g.color || '#00A2FF'
        }
        if(g.latitude && g.longitude){
          const markerHtmlStyles = getMarkerHtmlStyles(g.color, key)

          const custIcon =  window.L.divIcon({
            className:"custom-div-icon",
            iconAnchor: [-15,10],
            labelAnchor: [0, 0], 
            popupAnchor: [0, -36], 
            html: `<span class="map-pin pos-rel" style="${markerHtmlStyles}" />`
          })
          markerArray.push(window.L.marker([g.latitude, g.longitude], {icon: custIcon}))
        }
      }  
    }

    if(markerArray.length > 0){
      let group = window.L.featureGroup(markerArray).addTo(map);
      setGroup(group);
      //map.fitBounds(group.getBounds()); //set view boundry to view all marker
    }
  }
  const toggleData = (type) =>{
    if(dataType[type]){
      delete dataType[type];
    }else{
      dataType[type] = true;
    }
    toggleGeoData()
  }

  const toggleGeoData = () =>{
    group.clearLayers();  
    let filteredData = {'journey':[], 'measure_growth':[]};
    let activeLabels = [];

    for(const t in dataType){
      for(const d of geoData[t]){
        if(t === 'journey' && journeyLabelColors.indexOf(d.color) > -1){
          filteredData[t].push(d);
        }else if(t === 'measure_growth' && growthLabelColors.indexOf(d.color) > -1){
          filteredData[t].push(d);
        }
      } 
    }
    setActiveType({...dataType});
    addCustomMarker(map, filteredData);
  }

  const toggleJourneyLabel = (color) =>FH.toggleListString(color, journeyLabelColors, setJourneyLabelColors);
  const existJourneyLabel = (color) => FH.existsListString(color, journeyLabelColors);

  const toggleGrowthLabel = (color) =>FH.toggleListString(color, growthLabelColors, setGrowthLabelColors);
  const existGrowthLabel = (color) => FH.existsListString(color, growthLabelColors);

  if(loading)
    return <div className="spinner"/>

  return(
    <>
      <div className="col-xs-12 flex coldir-xs">
        <div className="p-10">
          <div className="flex alignflexend m-r-10">
            <input type="checkbox" className="checkbox-sm m-r-5" 
              id="journey" name="journey" checked={dataType.journey} 
              onChange={()=>toggleData('journey')}/>
            <label htmlFor="journey">Mother Case</label>
          </div>
          <div className="flex aligncenter m-r-10" onClick={()=>toggleJourneyLabel('#00A2FF')}>
            <span className={existJourneyLabel('#00A2FF')?'fas fa-check-circle':'far fa-circle'}/>
            <span className="map-pin m-l-10 m-r-10" style={getMarkerHtmlStyles('#00A2FF', 'journey', false)}/>
            No protein intake done
          </div>
          <div className="flex aligncenter m-r-10" onClick={()=>toggleJourneyLabel('green')}>
            <span className={existJourneyLabel('green')?'fas fa-check-circle':'far fa-circle'}/>
            <span className="map-pin m-l-10 m-r-10" style={getMarkerHtmlStyles('green', 'journey', false)}/>
            Protein intake greater then 80 grams
          </div>
          <div className="flex aligncenter m-r-10" onClick={()=>toggleJourneyLabel('red')}>
            <span className={existJourneyLabel('red')?'fas fa-check-circle':'far fa-circle'}/>
            <span className="map-pin m-l-10 m-r-10" style={getMarkerHtmlStyles('red', 'journey', false)}/>
            Protein intake less then 80 grams
          </div>
        </div>
        <div className="p-10">
          <div className="flex alignflexend m-r-10">
            <input type="checkbox" className="checkbox-sm m-r-5" 
              id="measure_growth" name="measure_growth" checked={dataType.measure_growth} 
              onChange={()=>toggleData('measure_growth')}/>
            <label htmlFor="measure_growth">Child Weight Zscore</label>
          </div>
          <div className="flex aligncenter m-r-10" onClick={()=>toggleGrowthLabel('rgb(245, 77, 89)')}>
            <span className={existGrowthLabel('rgb(245, 77, 89)')?'fas fa-check-circle':'far fa-circle'}/>
            <span className="map-pin m-l-10 m-r-10" style={getMarkerHtmlStyles('rgb(245, 77, 89)', 'measure_growth', false)}/>
            SUW
          </div>
          <div className="flex aligncenter m-r-10" onClick={()=>toggleGrowthLabel('#DECE06')}>
            <span className={existGrowthLabel('#DECE06')?'fas fa-check-circle':'far fa-circle'}/>
            <span className="map-pin m-l-10 m-r-10" style={getMarkerHtmlStyles('#DECE06', 'measure_growth', false)}/>
            MUW
          </div>
          <div className="flex aligncenter m-r-10" onClick={()=>toggleGrowthLabel('#00a86b')}>
            <span className={existGrowthLabel('#00a86b')?'fas fa-check-circle':'far fa-circle'}/>
            <span className="map-pin m-l-10 m-r-10" style={getMarkerHtmlStyles('#00a86b', 'measure_growth', false)}/>
            Mild
          </div>
        </div>
      </div>
      <LeafletMap 
        lat={position.latitude} long={position.longitude}
        addCustomMarker={addCustomMarker}
        zoomOffset={-1}
        title="Map"/>
    </>
  )
}

const GeoDataIcon = ({filter, organizationId, medicalFacilityId, memberUserId, children}) =>{
  const { isOpen:isMapOpen, toggleModal:toggleMapModal } = useModal();

  return(
    <>
      {children != null ? 
        <u onClick={()=>toggleMapModal()}>{children}</u> 
        :
        <LinkFontAwesomeIcon onSelect={()=>toggleMapModal()} iconCss="fas fa-location-dot white"/>
      }

      {isMapOpen &&
        <GenericModal 
          component={MemberGeoDataModal} 
          isOpen={isMapOpen}
          toggleModal={toggleMapModal}
          organizationId={organizationId}
          medicalFacilityId={medicalFacilityId}
          memberUserId={memberUserId}
          filter={filter}/>
      }
    </>
  )
}

export {MemberGeoDataModal, GeoDataIcon};