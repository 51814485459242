import React, { Fragment } from 'react';
import ReactDOM from 'react-dom';
import { Link } from "react-router-dom";
import useStyle from '../../../../../hooks/useStyle';
import { useCurrentUserHook } from '../../../../Authentication/useUserHook';

const TreatmentFilterModal = ({journey_profile_id, data_source, isOpen, toggleModal, filterType, onSelect}) => {
  useStyle('filter_dialog');
  const {currentUser} = useCurrentUserHook()

  let activity_types = Object.values(data_source.menu_treatment_tab.options);

  const FormFilters = () =>(
    <Fragment>
      {activity_types.map((o, k) =>(
        <div className="filter-label" key={k}>
          <Link to={`/health/treatmentForm/${journey_profile_id}/${o.value}/${o.label}/${o.child_form}`} >
            <span>{o.label}</span> 
          </Link>
        </div>
      ))}
    </Fragment>  
  )

  const DataFilters = () =>(
    <Fragment>
      {activity_types.map((o, k) =>(
        <div className="filter-label" key={k}>
          <div onClick={e=>onSelect(o.type)}>
            <span>{o.label}</span> 
          </div>
        </div>
      ))}
    </Fragment>  
  )

  return(ReactDOM.createPortal(
    <Fragment>
      <div className="ml-modal">
        <div className="ml-modal-content ml-card-4 ml-animate-zoom brd-10">
          
          <div className="flex justspacebetween modal-title">
            <h4 className="f18 text-white fw500 p12-10-15">Please select an item</h4>
            <button className="btn-close"
              title="Close Modal" onClick={e=>toggleModal(e)}>×
            </button>
          </div>

          <div className="p-5 scroll-x">
            {filterType=='data_filter' ? <DataFilters/>:<FormFilters/>}
          </div>
            
        </div>
      </div>
    </Fragment>, document.body)
  )

}

export default TreatmentFilterModal;