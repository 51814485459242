import React, { useState } from 'react'
import { FormTitle, FormDescription } from "./FieldLabel"
import { usePageTour } from "../Common/usePageTour"
import useStyle from '../../hooks/useStyle'
import { usePageLocale } from '../../hooks/useLocale'
import { FormRuleAlertIcon } from "./FormRuleAlert"
import { HelpIcon } from "../Help/HelpSidePanel"
import { useCurrentUserHook } from '../Authentication/useUserHook'

const FormContainer = (props) => {
  useStyle('/css/form.css', true)

  let {post, titleTag, formId, locale} = props	
  const tour = usePageTour() 
  const [hideForm, setHideForm] = useState(false)
  const {currentUser} = useCurrentUserHook()
  const langauge = locale || currentUser?.current_locale
  const {labelLocale} = usePageLocale(langauge, 'formLocale')

  try{
    formId = props?.children[0]?.props?.formId
  }catch(e){
    console.log(e.message)
  }

  /*props.formFn.hideForm = () =>{
    setHideForm(true)
  }

  props.formFn.showForm = () =>{
    setHideForm(false)
  }*/

  const openPageTour = () =>{
    try{
      /*template.template_fields[activeKey].child_template_fields.forEach((f)=>{
        setPageTour(
          `form_${template.id}`,
          {id:f.client_id, title:f.label, description:f.label}
        )   
      })*/
      tour && tour.init(true, 'form_tour')
    }catch(e){
      console.log(e.message)
    }
  }

  /*if(hideForm) return null;*/

  return (
    <section id="ct">
      <div className={props.isPopupMode ? 'form p-0 m-t-0' : 'form app-content m-t-30 m-t-20-xs'}>
        <div className="flex justspacebetween modal-title aligncenter">
          <h4 className="f18 f16-xss text-white fw500 p20 p15-xs p10-xss">
            <FormTitle title={post.title} locale={post.locale_title} currentLocale={langauge}/> 
            {titleTag ? <span className="m-l-5"> - {titleTag}</span> : null}
          </h4>
          <HelpIcon recordIds={post.help_id} className={`m-r-15 font-18 ${props.isPopupMode?'m-t-25':''}`}/>
        </div>
        <div className="tab-details">
          <div className="flex justspacebetween aligncenter coldir-xs alignstart-xs form-details">
            <p>
              <FormDescription details={post.details} locale={post.locale_details} currentLocale={langauge}/> 
            </p>
            <p>
              <span className="text-red m-r-5">*</span>
              {labelLocale(1)}
            </p>
          </div>
          <div className="flex rowrev">
            <FormRuleAlertIcon currentUser={currentUser} formId={formId}/>
          </div>
        </div>
        {props.children}
      </div>
    </section>
  )
}

export default FormContainer;

/*
<div className="page-content row">
      <div className={`portlet ${props.isPopupMode && 'p-0 m-t-0'}`} >
        <div id="f_title" className="portlet-heading bg-black color-white">
          <h4 className="portlet-title text-uppercase">
            <FormTitle title={post.title} locale={post.locale_title} 
              currentLocale={currentUser.current_locale}/> 
          </h4>
          <div className="clearfix"/>
        </div>
        <div id="portlet1" className="panel-collapse collapse in">
          <div className="portlet-body p-t-0">
            <div className="row p-7">
              {showFormUpdateLink && 
                <Link className="font-15 pull-right" to={`/form/feedback/${formId}`}>
                  <u>Suggest updates to the forms</u>
                </Link>
              }

              <FormDescription details={post.details} locale={post.locale_details} 
                currentLocale={currentUser.current_locale}/> 

              <label className="pull-right clear">
                <span className="fas fa-chalkboard tour-btn" onClick={()=>openPageTour()}/>
                <span className="lred font-16 m-l-15" id="f_required">*</span>
                {labelLocale(1)}
              </label>
            </div>
            {props.children}
          </div>
        </div>
      </div>
    </div>
*/