import React, { useEffect, useRef } from "react"
import { useHistory } from "react-router-dom"
import ReactDOM from 'react-dom'
import Cs from "../../../services/CommonService"
import ReactTooltip from "react-tooltip"
import CheckAccess from '../../Roles/CheckAccess'
import useModal from '../../../hooks/useModal'
import GenericModal from "../../Modals/GenericModal"
import UsersRolesPopup from '../../DataAccesses/UsersRolesPopup'
import { CueTip } from "../../Common/CueTooltip"
import { useCurrentUserHook } from "../../Authentication/useUserHook"

let portalPath = null;

const PortalSideMenu = (props) => {
  const {currentUser} = useCurrentUserHook()
  const sideNavEl = useRef(null)
  const sideNavMenuEl = useRef(null)
  const history = useHistory()

  let {journey, child, journeyType, menuOptions, openSideNav, setOpenSideNav, permissionKey} = props
  const {current_member} = currentUser

  const { isOpen: isUserRoleOpen, toggleModal: toggleUserRole } = useModal()

  useEffect(() => {
    ReactTooltip.rebuild()
    document.addEventListener("keydown", handleEscape);
    document.addEventListener("click", handleClick, true);

    return () => {
      document.removeEventListener("keydown", handleEscape);
      document.removeEventListener("click", handleClick, true);
    }
  }, [])

  const handleEscape = (e) => {
    if (e.keyCode === 27) setOpenSideNav(false);
  }

  const handleClick = (e) => {
    if (sideNavEl.current && !(sideNavEl.current.contains(e.target) || sideNavMenuEl.current.contains(e.target))) {
      setOpenSideNav(false);
    }
  }

  /*const permission = {
    guides: () => {
      return env.admin_emails.includes(currentUser.email) ? true:false
    }
  }*/

  const Icon = ({icon, icon_color, path}) =>{
    if(CheckAccess.checkUserAccess(current_member, current_member?.permissions?.[permissionKey], path.sid)){
      return(
        <a onClick={e=>routePath(e, path)} className="tooltip">
          <i className={'fa '+icon} style={{color:icon_color}}/>
          <span>{path.label}</span>
          <i className="fa fa-angle-right" />
          <CueTip tooltip={path.hover_text} positionCss="bottom"/>
        </a>
      )  
    }else{
      return null
    }
  }

  const Icons = ({icons}) => icons && icons.map((p, i) => 
    <Icon icon={p.icon} icon_color={p.icon_color} path={p}  key={i}/>
  )

  const routePath = (e, path) => {
    e.stopPropagation();
    if(path.sid === 'share_journey'){
      toggleUserRole()
    }else if(journeyType == 'cancer_journey'){
      Cs.routeCancerPath(path, journey, history)  
    }else if(journeyType == 'infant_journey'){
      Cs.routeInfantPath(path, child, journey, history)
    }
  }

  if(isUserRoleOpen){ 
    return( 
      <GenericModal
        component={UsersRolesPopup}
        isOpen={isUserRoleOpen}
        toggleModal={toggleUserRole}
        itemType="journey_profile"
        itemId={journey.id}
        showRoles="true"
        showOrgMembers="true"
        title="Invite others to this case"
        memberRoleId={journey.member_role_id}
        medicalFacilityId={journey.medical_facility_id}/>
    )
  }

  return (
    ReactDOM.createPortal(
      <div id="menu-main" ref={sideNavEl}
        className={`menu pos-fix r-0 menu-box-path menu-box-detached round-medium ${openSideNav ? "menu-active" : ""} `}
        style={{ marginTop: '40px', width: 260, opacity: 1, display: "block", 'lineHeight': '48px', 'minHeight': '500px' }}>
          
          <div className="menu-icons">
            <p className="under-heading center-text font-800 font-16">
              Journey Menu

              <a href="#" className="close-menu"
                onClick={(e) => {e.preventDefault();setOpenSideNav(false)}}
                style={{'marginBottom':'unset', float: 'right'}}>
                  <i className="fa fa-times color-red2-dark" />
              </a>
            </p>
            
            <div className="clear" />
          </div>

          <div className="content m-t-20 m-b-10">
            <div className="link-list link-list-1" ref={sideNavMenuEl}>
              {journey?<Icons icons={menuOptions?.options || []}/>:<div className="spinner"/>}
            </div>
          </div>
      </div>, document.body
    )
  )
}

export default PortalSideMenu;