import { useEffect, useContext } from 'react';
import { AuthContext } from "../contexts/Authentication/AuthStateProvider";

const usePageTitle = (title) => {
  const { state:{isAuthenticated, user:currentUser} } = useContext(AuthContext);

  useEffect(() => {
    // This will run when the page first loads and whenever the title changes
    document.title = isAuthenticated?`${title} - ${currentUser.name}`:title;
  }, [title])

}

export {usePageTitle};