import React, { useState, useEffect, useRef } from "react"
import Cs from '../../../../services/CommonService'
import { PillDsList } from "../../../Common/NotificationPill"
import GenericModal from "../../../Modals/GenericModal"
import TreatmentForm from "../Form/TreatmentForm"
import useModal from "../../../../hooks/useModal"
import TreatmentService from "../TreatmentService"

const TreatmentTable = (props) => {
  const { journeyProfile, searchString, dataSource} = props;
  const {form_id} = dataSource

  const [loading, setLoading] = useState(false);
  const [currentpage, setCurrentpage] = useState(1);
  const [pagemeta, setPagemeta] = useState([]);
  const [treatmentData, setTreatmentData] = useState({});

  const { isOpen: treatmentFormOpen, toggleModal: toggleTreatmentFormModal } = useModal();

  let {current:scope} = useRef({dataSource:{}, selectedItem:null, treatmentFormProps:null});


  useEffect(() => {
    getTreatmentList()
  }, [searchString, currentpage]);

  let getTreatmentList = () => {
    let req = {
      treatment_form_id: form_id,
      journey_profile_id: journeyProfile.id,
    }

    TreatmentService.getAll(req).then((res) => {
      setTreatmentData(res.data.treatments)
      setLoading(false)
    })
  }

  const routetreatmentForm = (treatment_id, label, id) => {
    scope.treatmentFormProps = {
      match: {
        params: {
          journey_profile_id: journeyProfile.id,
          treatment_id: treatment_id, //ds value check
          treatment_type: label,
          treatment_form_id: form_id,
          id: null,
        },
      },
    }
    scope.treatmentFormProps.component = TreatmentForm
    //call form toggle
    toggleTreatmentFormModal()
  }

  const onTreatmentFormSubmit = () => {
    toggleTreatmentFormModal()
  }

  const TreatmentTable = () => (
    <div className="table-sm table-responsive">
      <span className="bg-highlight round-btn white font-20 m-b-5 pull-right">
        <i className="fas fa-download" />
      </span>
      <span className="bg-highlight round-btn white font-20 m-b-5 pull-right m-r-15" onClick={(e) => routetreatmentForm(2, 437, "Record Medication Prescribed")} >
        <i className="fas fa-plus" />
      </span>
      <table className="shadow-small">
        <thead>
          <tr className="white bg-black">
            <th className="p-2 bold-400 text-center miw-95">Id</th>
            <th className="p-2 bold-400 text-center miw-95">Date</th>
            <th className="p-2 bold-400 text-center miw-95">Name</th>
            <th className="p-2 bold-400 text-center miw-95">Doctor Name</th>
            <th className="p-2 bold-400 text-center miw-95">Notes</th>
            <th className="p-2 bold-400 text-center miw-95">Purpose</th>
            <th className="p-2 bold-400 text-center miw-95">Frequency</th>
            <th className="p-2 bold-400 text-center miw-95">Medication</th>
          </tr>
        </thead>
        <tbody>{treatmentTableData}</tbody>
      </table>
    </div>
  )

  const treatmentTableData = treatmentData.length > 0 && treatmentData.map((a, k) => {
    let ds = a.data_source || {};
    let data = a.data || {};

    return (
      <tr key={k}>
        <td className="text-center" data-title="Id">
          {a.record_id}
        </td>

        <td className="text-center">
          {Cs.formatUpdateDate(data.date_formatted_date, "MMMM DD YYYY")}
        </td>

        <td className="text-center">
          <div>{data.name}</div>
        </td>

        <td className="text-center">
          <div>{data.name_doctor}</div>
        </td>

        <td className="text-center">
          <div>{data.notes}</div>
        </td>

        <td className="text-center">
          <div>{data.purpose}</div>
        </td>

        <td className="text-center">
          <PillDsList list={ds.frequency} dataKey="label" styleName="pill text-left"/>
        </td>

        <td className="text-center">
          <PillDsList list={ds.quantity} dataKey="label" styleName="pill text-left"/>
        </td>
      </tr>
    )
  })

  const DiagnosisTable = () => (
    <div className="table-responsive">
      <span className="bg-highlight round-btn white font-20 m-b-5 pull-right">
        <i className="fas fa-download" />
      </span>
      <span className="bg-highlight round-btn white font-20 m-b-5 pull-right m-r-15" onClick={(e) => routetreatmentForm(1, 436, "Record Appointment Details")} >
        <i className="fas fa-plus" />
      </span>
      <table className="shadow-small">
        <thead>
          <tr className="white bg-black">
            <th className="p-2 bold-400 text-center miw-95">Id</th>
            <th className="p-2 bold-400 text-center miw-95">Date</th>
            <th className="p-2 bold-400 text-center miw-95">Name</th>
            <th className="p-2 bold-400 text-center miw-95">Notes</th>
            <th className="p-2 bold-400 text-center miw-95">Appt</th>
            <th className="p-2 bold-400 text-center miw-95">Follow up</th>
          </tr>
        </thead>
        <tbody>{diagnosisTableData}</tbody>
      </table>
    </div>
  )

  const diagnosisTableData = treatmentData.length > 0 && treatmentData.map((a, k) => {
    let ds = a.data_source || {};
    let data = a.data || {};

    return (
      <tr key={k}>
        <td className="text-center" data-title="Id">
          {a.record_id}
        </td>

        <td className="text-center">
          {Cs.formatUpdateDate(a.created_at, "MMMM DD YYYY")}
        </td>

        <td className="text-center">{data.name}</td>
        
        <td className="text-center">{data.notes}</td>

        <td className="text-center">
          <PillDsList list={ds.appt} dataKey="label" styleName="pill text-left"/>
        </td>
        
        <td className="text-center">
          <PillDsList list={ds.follow_up} dataKey="label" styleName="pill text-left"/>
        </td>
      </tr>
    )
  })

  let components = {
    /*404:symptoms,*/
    436:DiagnosisTable,
    437:TreatmentTable,
    /*403:palliative,*/
  }

  const TagName = components[form_id];  

  if(TagName){
    return (
      <>
        <TagName {...props}/>
        {treatmentFormOpen &&
          <GenericModal toggleModal={toggleTreatmentFormModal}
            isPopupMode="true" onFormSubmit={onTreatmentFormSubmit}
            closeConfirmPopup={true} {...scope.treatmentFormProps}
          />
        }
      </>
    )
  }else{
    return <div>None</div>
  }

}

export default TreatmentTable;