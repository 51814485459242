const useSort = (paginate, callback) => {

  const sort = (predicate) => {
    paginate.sort_direction = (paginate.sort_column === predicate) ? (paginate.sort_direction === "asc"?"desc":"asc") : "asc";
    paginate.sort_column = predicate;
    //paginate.sort_direction = paginate.reverse ? "desc" : "asc";
    callback()
  }

  return {
    sort,
  }
};

export default useSort;