import React, { lazy, Suspense } from 'react';
import PrivateRoute from "../../routes/PrivateRoute";
import PublicRoute from "../../routes/PublicRoute";
const HelpList = lazy(() => import('../../components/Help/List/HelpList'));
const HelpForm = lazy(() => import('../../components/Help/Form/HelpForm'));
const HelpPage = lazy(() => import('./HelpPage'));

const HelpRouter = (props) => (
  <Suspense fallback={<div className="spinner"/>}>
    <PrivateRoute path="/help" component={HelpList} searchString={props.searchString} exact/>
    <PrivateRoute path="/help/create/:id?" component={HelpForm} exact />
    <PublicRoute path="/public/help/aXXMl4b1F0CgmMj" component={HelpPage} exact/>    
  </Suspense>
)

export default HelpRouter;
/*<PrivateRoute path="/news/activity/create/:journey_category_id?/:education_journey_profile_id?" component={JourneyActivityDashboard} exact />*/