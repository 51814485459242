import React, { lazy, Suspense } from 'react';
const PrivateRoute = lazy(() => import("../../routes/PrivateRoute"));
const SchedulesList = lazy(() => import('./List/PreScheduleEventList'));
const SchedulesForm = lazy(() => import('./Form/PreScheduleEventForm'));

const PreScheduleEventRouter = (props) => (
  <Suspense fallback={<div className="spinner"/>}>
    <PrivateRoute path="/schedule/event" component={SchedulesList} searchString={props.searchString} exact />
    <PrivateRoute path="/schedule/event/create/:type/:form_id/:id?" component={SchedulesForm} exact />
    <PrivateRoute path="/schedule/edit/:id" component={SchedulesForm} exact />
  </Suspense>
)

export default PreScheduleEventRouter;