import { useEffect, useContext } from 'react';
import { AuthContext } from "../contexts/Authentication/AuthStateProvider";
import GetBreakpoint from "../contexts/Authentication/GetBreakpoint";

const UpdateBreakPoints = () => {
  const { state:{screen}, dispatch } = useContext(AuthContext);

  useEffect(() => {
	window.addEventListener("resize", onResize);
	
	return(()=>{
		window.removeEventListener("resize", onResize);
	})    
  }, [])

  const onResize = () =>{
  	const screen = GetBreakpoint();
  	if(!equals(screen)){
	  	dispatch({
	      "type": "UPDATE_SCREEN_VIEW_PORT",
	      "payload":{screen}
	    });
	}
  }

  const equals = (updatedScreen) => {
  	/*console.log(JSON.stringify(screen))
  	console.log(JSON.stringify(updatedScreen))*/
    return screen.xs == updatedScreen.xs 
     	&& screen.sm == updatedScreen.sm 
     	&& screen.md == updatedScreen.md 
     	&& screen.lg == updatedScreen.lg 
  };

  return {};
}

export {UpdateBreakPoints};