import React, { Fragment } from "react";

export default function(props) {
	let {config, setConfig} = props;
	const playModes = [{label:'Normal', value:'normal'}, {label:'Bounce', value:'bounce'}]
	const directions = [{label:'Forward', value:'forward'}, {label:'Backward', 'value':"backward"}]

	const setData = (e, name) =>{
		setConfig({...config, [name]:e.target.value})
	}

	const setCheckbox = (name) =>{
		setConfig({...config, [name]:!config[name]})
	}

	const OptionsObjList = ({list, idKey, labelKey}) => list.map((o, k) =>
    	<option key={k} value={o[idKey]}>{o[labelKey]}</option>
  	)

	return (
		<Fragment>
		{JSON.stringify(config)}
		<div className="row">
			<div className="col-xs-12 col-sm-6">
  	  			<label type="number">PLAY MODE</label>
  	  			<select value={config.mode} className="form-control"
  	  			onChange={e=>setData(e, 'mode')}>
              		<option value="" disabled selected>Select an item from list below</option>
            		<OptionsObjList list={playModes} idKey="value" labelKey="label"/>
          		</select>
    		</div> 

    		<div className="col-xs-12 col-sm-6">
  	  			<label type="number">DIRECTION</label>
  	  			<select value={config.direction} className="form-control"
  	  			onChange={e=>setData(e, 'direction')}>
              		<option value="" disabled selected>Select an item from list below</option>
            		<OptionsObjList list={directions} idKey="value" labelKey="label"/>
          		</select>
    		</div> 
		</div>

		<div className="row m-t-10">
			<div className="col-xs-12 col-sm-6">
  	  			<label>WIDTH</label>
 	    		<input type="number" defaultValue={config.width} className="form-control" 
 	    		onChange={e=>setData(e, 'width')}/> 	 
    		</div> 

    		<div className="col-xs-12 col-sm-6">
  	  			<label>HEIGHT</label>
 	    		<input type="number" defaultValue={config.height} className="form-control" 
 	    		onChange={e=>setData(e, 'height')}/> 	 
    		</div> 

    		<div className="col-xs-12 col-sm-6">
  	  			<label>ANIMATION SPEED</label>
 	    		<input type="number" defaultValue={config.speed} className="form-control" 
 	    		onChange={e=>setData(e, 'speed')}/> 	 
    		</div> 
		</div>

		<div className="row m-t-10">
			<div className="col-xs-12 col-sm-6">
				<span className="checkbox-container" onClick={e=>setCheckbox('controls')}>
                	<span>Controls</span>
                	<input type="checkbox" name="controls" checked={config.controls} readOnly/>
                	<span className="checkbox-checkmark"></span>
              	</span>

              	<span className="checkbox-container" onClick={e=>setCheckbox('autoplay')}>
                	<span>Autoplay</span>
                	<input type="checkbox" name="autoplay" checked={config.autoplay} readOnly/>
                	<span className="checkbox-checkmark"></span>
              	</span>
			</div>
			<div className="col-xs-12 col-sm-6">
				<span className="checkbox-container" onClick={e=>setCheckbox('hover')}>
                	<span>Hover</span>
                	<input type="checkbox" name="hover" checked={config.hover} readOnly/>
                	<span className="checkbox-checkmark"></span>
              	</span>

              	<span className="checkbox-container" onClick={e=>setCheckbox('loop')}>
                	<span>Loop</span>
                	<input type="checkbox" name="loop" checked={config.loop} readOnly/>
                	<span className="checkbox-checkmark"></span>
              	</span>
			</div>
		</div>
		<br/>
		</Fragment>
	)
}

/*
  <div className="col-xs-12 col-sm-6">
            <label type="number">BACKGROUND COLOR</label>
            <input type="color" defaultValue={config.background} className="form-control" 
            onChange={e=>setData(e, 'background')}/>  
        </div> 
*/