import React, { useEffect, useState, useRef } from "react"
import DynamicForm from "../../FormBuilder/DynamicForm"
import FormTemplateId from "../../../constants/FormTemplateList"
import CaseAssessmentService from "./CaseAssessmentService"
import FormContainer from '../../FormBuilder/FormContainer'
import env from "../../../env"
import { useCurrentUserHook } from "../../Authentication/useUserHook"

function CaseAssessmentForm(props) {
  let {current:form} = useRef({'assessment':{}, 'data':{}, 'formFn':{}, 'formMode':{}});
  const {currentUser} = useCurrentUserHook()
  const [isFormRendered, setFormRendered] = useState(false)
  const [post, setPost] = useState({})

  const params = props.match.params

  const get = () => {
    CaseAssessmentService.get(params.id).then((res) => {
      if (res.status == 200) {
        form.assessment = res.data.case_assessment
        form.data = form.assessment.data || {}
        setFormRendered(true)
      }
    }).catch((res) => {
      form.assessment = null
    })
  }

  useEffect(() => {
    if(props.assessmentData){
      form.data = Object.assign({}, props.assessmentData)
    }
    
    form.formFn = {
      form_type: "health.case_assessment",
      editPermission: env.admin_emails.includes(currentUser.email)
    }
    form.formMode = "create-form-submissions"
    if (params.id) {
      get()
      form.formMode = "edit-form-submissions"
    } else if (!isFormRendered) {
      setFormRendered(true)
    }
  }, [])

  const create = (assessment) => {
    assessment.assess_user_id = params.assess_user_id;
    assessment.journey_profile_id = params.journey_profile_id;
    assessment.org_member_id = params.org_member_id;
    assessment.organization_id = currentUser.current_organization_id;
    assessment.form_id = FormTemplateId.OrgMemberAssessment;
    CaseAssessmentService.create(assessment).then(({status, data}) => {
      if (status == 201) {
        onSubmitPageRedirect(data.case_assessment)
      }
    })
  }

  const update = (assessment) => {
    CaseAssessmentService.update(assessment).then((res) => {
      if (res.status == 204) {
        onSubmitPageRedirect(assessment)
      }
    })
  }

  const onSubmitPageRedirect = (assessment) => {
    if(props.isPopupMode){
      if(assessment)props.onFormSubmit(assessment)
      props.toggleModal()
    }
    //props.history.push('/org/initiatives')
  }

  if (isFormRendered) {
    return (
      <FormContainer post={post} isPopupMode={props.isPopupMode}>
        <DynamicForm formMode={form.formMode}
          formId={FormTemplateId.OrgMemberAssessment}
          form={form.assessment}
          data={form.data}
          formFn={form.formFn}
          onCreate={create}
          onUpdate={update}
          post={post}
          onCancel={onSubmitPageRedirect}
          setPost={setPost}
          isPopupMode={props.isPopupMode}/>
      </FormContainer>
    )
  } else {
    return null
  }
}

export default CaseAssessmentForm;