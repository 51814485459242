import React from "react";
import { LinkMapIcon, LinkMailIcon, LinkUserIcon, LinkDeleteIcon, LinkFontAwesomeIcon } from "../../Common/ImageIcons";
import { NotificationBadge } from "../../Common/BadgePill";
import { RecordId, StatuDropDown, AdultMetabolicDetails, AdultMetabolicDetails_2 } from "./Common";

const AdultMetabolic = (props) => {
  let {journey, dataSources, onDelete, undoDelete, openUserRole, routeJourney, openNotification, index, update, currentLocale, labelLocale, isAssessmentEnabled} = props;
  let {data, data_source:ds} = journey;

  return(
    <div className="bg-white brd-10 card-shadow m-b-15">
      <div className="card-content p-15">
        <div className="scroll-auto ht-165">
          <div className="flex justspacebetween">
            <h4 className="f18 text-left">
              {journey.record_id} - {data.name}
            </h4>
            <StatuDropDown id={journey.id} status={journey.status} statusReason={journey.status_reason} {...{update, index, currentLocale}}/>
          </div>
          
          <AdultMetabolicDetails j={journey} routeJourney={routeJourney} labelLocale={labelLocale}/>

          <AdultMetabolicDetails_2 j={journey}/>
        </div>
        <div className="m-t-10 d-flex justspacebetween aligncenter">
          <div className="flex cgap-10">
            <RecordId j={journey}/> 
          </div>
          {journey.status != 'closed' && 
            <div className="flex aligncenter ml-auto">
              <LinkMapIcon id="path_details" 
                tooltip={labelLocale(19)}
                onSelect={()=>routeJourney(journey)}>
                  <NotificationBadge 
                    count={journey.schedule_today_count}/>
                  <NotificationBadge
                    position="bottom" 
                    count={journey.schedule_missed_count}/>
              </LinkMapIcon>
              {journey.nutrition_care_alert_count > 0 &&
                <LinkMailIcon id="notification" 
                  count={journey.nutrition_care_alert_count} 
                  tooltip={labelLocale(23)}
                  onSelect={()=>openNotification(journey)} 
                  className="far fa-envelope m-l-10 font-18 text-muted"/>
              }
              <LinkUserIcon count={journey.shared_journey_count} tooltip={labelLocale(20)} onSelect={()=>openUserRole(journey)}/>
              {journey.is_active 
                ? <LinkDeleteIcon id="delete_journey" tooltip={labelLocale(21)} onSelect={()=>onDelete(journey)}/>
                : <LinkDeleteIcon id="undo_delete" onSelect={()=>undoDelete(journey.id)}/>
              }
              {isAssessmentEnabled ? 
                <LinkFontAwesomeIcon 
                  count={journey.assessment_count}
                  link={`/case/${journey.id}/assessments/${journey.created_by}`} 
                  iconCss="font-16 white far fa-list-alt" 
                  parentCss="table-icon-lgrey m-l-10"
                  tooltip={labelLocale(22)}/>
                :
                null
              }
            </div>
          }
        </div>
      </div>
    </div>
  )  
}

export default AdultMetabolic;