import React from "react";
import JourneyBlock from "../List/JourneyBlock";
import TreatmentTable from "../Treatments/List/TreatmentTable";
import InfantActivityTable from "../MotherInfantJourney/Activities/List/InfantActivityTable";
import ChildBlock from "../MotherInfantJourney/Child/ChildBlock";
import ChildHistoryBlock from "../PatientAdditionalInfo/ChildHistoryBlock";
import AssessBFTable from "../MotherInfantJourney/Activities/List/AssessBFTable";
import MedicalFacilityBlock from "../../Organizations/MedicalCareFacility/MedicalFacilityBlock";
import WellnessBlock from "../Wellness/List/WellnessBlock";
import DailyMeasurementTable from "../DailyMeasurements/List/DailyMeasurementTable";
import FamilyMemberList from "../../Organizations/Members/FamilyMemberList";
import GenericActivityTable from "../Health/Activities/List/GenericActivityTable";
import PatientAdditionalInfoView from "../PatientAdditionalInfo/PatientAdditionalInfoView";
import OrgMemberBlock from "../../Organizations/Members/OrgMemberBlock";
import UserRegReqBlock from "../../UserRequests/UserRegReqBlock";

const Components = (props) => {
  let components = {
    journey_profile: JourneyBlock,
    org_member_reg: OrgMemberBlock,
    user_reg_req: UserRegReqBlock,
    diagnosis: TreatmentTable,
    treatment: TreatmentTable,
    child_birth: ChildBlock,
    birth_history: ChildHistoryBlock,
    household_info: ChildHistoryBlock,
    addnal_info: ChildHistoryBlock,
    household_details: ChildHistoryBlock,
    prior_services: ChildHistoryBlock,
    prenatal_care: InfantActivityTable,
    feeding_assessment: InfantActivityTable,
    growth_measurement: InfantActivityTable,
    assess_bf: AssessBFTable,
    assess_cf: InfantActivityTable,
    immunization: InfantActivityTable,
    periodic_check: InfantActivityTable,
    protein_intake: InfantActivityTable,
    /*family_details: ChildHistoryBlock,*/
    adolescent: ChildHistoryBlock,
    cancer_journey : {
      journey_profile: JourneyBlock,
      symptoms:WellnessBlock,
      daily_check:DailyMeasurementTable,
      pain_tracking:WellnessBlock,
      side_effects:WellnessBlock,
      care_giver:FamilyMemberList,
      health_center:MedicalFacilityBlock,
      family_member:FamilyMemberList
    },
    dementia_journey : {
      journey_profile: JourneyBlock,
      symptoms:WellnessBlock,
      daily_check:DailyMeasurementTable,
      pain_tracking:WellnessBlock,
      side_effects:WellnessBlock,
      care_giver:FamilyMemberList,
      health_center:MedicalFacilityBlock,
      family_member:FamilyMemberList
    },
    ckd_journey : {
      journey_profile: JourneyBlock,
      symptoms:WellnessBlock,
      daily_check:DailyMeasurementTable,
      pain_tracking:WellnessBlock,
      side_effects:WellnessBlock,
      care_giver:FamilyMemberList,
      health_center:MedicalFacilityBlock,
      family_member:FamilyMemberList
    },
    /*adult_metabolic : {
      journey_profile: JourneyBlock,
      registration: ChildHistoryBlock,
      assess_sleep:DailyMeasurementTable,
      assess_stress:DailyMeasurementTable,
      diet_recall:DailyMeasurementTable,
      physical_activity:DailyMeasurementTable,
    }*/
  }

  let TagName = null
  if(props.journeyType === "infant_journey"){
    TagName = components[props.componentName]  
  }else if(props.journeyType && components[props.journeyType]?.[props.componentName]){
    TagName = components[props.journeyType][props.componentName]  
  }else{
    TagName = components[props.componentName]  
  }

  if(TagName){
  	return <TagName {...props}/>
  }else if(props.defaultViewType === "activityTable"){
  	return <GenericActivityTable {...props}/>
  }else if(props.defaultViewType === "patientAdditionalInfo"){
    return <PatientAdditionalInfoView {...props}/>
  }
  
}

export default Components