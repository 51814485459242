import env from '../env';
import axios from 'axios';

export default class BaseService {
  
  constructor(base, api_name, client_cache = {post: false, put: false}) {
    this.url = env[base]+`${api_name}`;
    this.postHeader = {
      headers: {
        'client-cache':client_cache.post
      }
    }
    this.putHeader = {
      headers: {
        'client-cache':client_cache.put
      }
    }
  }

  getAll(req){
    return new Promise((resolve, reject) => {
      axios.get(this.url, {params: req}).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        resolve(e);
      })
    })
  }

  get(id, req){
  	//const id = (typeof req === 'object') ? req.id : req;
    return new Promise((resolve, reject) => {
      axios.get(`${this.url}/${id}`, {params: req}).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        resolve(e);
      })
    })
  }

  create(req){
    return new Promise((resolve, reject) => {
      axios.post(this.url, req, this.postHeader).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        resolve(e);
      })
    })
  }

  update(req) {
    return new Promise((resolve, reject) => {
      axios.put(`${this.url}/${req.id}`, req, this.putHeader).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        resolve(e);
      })
    })
  }

  delete(id, req) {
  	//const id = (typeof req === 'object') ? req.id : req;
    return new Promise((resolve, reject) => {
      axios.delete(`${this.url}/${id}`, {params: req}).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        resolve(e);
      })
    })
  }

}