import { useState } from 'react';

const useModal = (initialState=false) => {
  const [isOpen, setIsOpen] = useState(initialState);

  function toggleModal(e) {
  	e && e.preventDefault();
    setIsOpen(!isOpen);
  }

  return {
    isOpen,
    toggleModal,
  }
};

export default useModal;