import React from 'react';
import ReactDOM from 'react-dom';
import { usePageLocale } from '../../hooks/useLocale';
import { useCurrentUserHook } from '../Authentication/useUserHook';

const ConfirmMenu = (props) => props.isOpen ? <ConfirmMenuPopup {...props}/>:null;

const ConfirmMenuPopup = ({toggleModal, success, message }) => {
    const {currentUser} = useCurrentUserHook()
    const {labelLocale} = usePageLocale(currentUser.current_locale, 'confirmMenu')

    return ReactDOM.createPortal(
      <>
        <div className="menu-hider menu-active" style={{ display: "block", transform: "translate(0px, 0px)" }}/>;  
            <div id="menu-confirm" className="menu menu-box-modal round-medium menu-active modal-xs">
            <h1 className="center-text uppercase ultrabold m-t-30 font-16">{message?"Are you sure?":null}</h1>
            <div className="boxed-text-large font-15">
                {message ? 
                    message : 
                    <><p>{labelLocale(1)}</p><p>{labelLocale(2)}</p><p>{labelLocale(3)}</p></>
                }
            </div>
            <div className="content m-l-20 m-r-20 text-center">
                <div className="one-half">
                    <div className="button button-center-large button-s button-round-small bg-submit"
                        onClick={ e => success(e) } label="Yes">
                            {labelLocale(4)}
                    </div>
                </div>
                <div className="one-half last-column">
                    <div className="button button-center-large button-s button-round-small bg-cancel"
                        onClick={ e => toggleModal(e) } label="No">
                            {labelLocale(5)}
                    </div>
                </div>
                <div className="clear" />
            </div>
        </div>
      </>, document.body
    )
}

export default ConfirmMenu;