import React, {useMemo} from 'react';
 
const LoadMoreBtn = props => {
  const {label, onPageChange, onLoadedPageSelect, totalItems, currentPage, itemsPerPage, className} = props;
  const totalPage = useMemo(() => Math.ceil(totalItems / itemsPerPage), [totalItems]);

  const loadMore = () =>{
    (currentPage < totalPage) && onPageChange(currentPage + 1); 
  }

  const pageNumbers = Array.from({length: currentPage}, (_, i) => i + 1)

  return( 
    <div className="text-center">
      <button onClick={()=>loadMore()} className="pill bg-highlight white f14 m-t-10 p-15">{label || 'Load More'}</button>
      <div className="bold-600 f14">
        <select className="input-sm bg-white" defaultValue={currentPage} onChange={e=>onLoadedPageSelect(e.target.value)}>
          {pageNumbers.map((n, i)=> <option key={i} value={n}>{n}</option>)}
        </select> / {totalPage}
      </div>
    </div>
  )
}

export default LoadMoreBtn;
