import React, { useEffect, useState, useContext } from "react";
import {CheckBox} from "./FormInput";
import { CacheContext } from "../../contexts/Cache/CacheStateProvider";
import {useSearch} from '../../hooks/useSearch';
import FormTemplateService from "../../services/FormTemplateService";
import { FilterAccordionPanel, FilterPill, useFilterHook } from "./AccordionPanel";

const FormFieldFilter = (props) => {
  const {formId, filter, setFilter} = props;

  const {cacheState, cacheDispatch} = useContext(CacheContext);
  const formFieldCacheKey = 'formFieldFilter_'+formId;
  const dsCacheKey = 'dataSource_'+formId;

  const [isLoading, setLoading] = useState(true);
  const [formFieldFilter, setFormFieldFilter] = useState(cacheState[formFieldCacheKey] || {});
  const [dataSourceList, setDataSourceList] = useState(cacheState[dsCacheKey] || {});
  const [activeFilters, setActiveFilters] = useState([]);

  useEffect(()=>{
    if(formFieldFilter[formId]){
      setActiveFilters(formFieldFilter[formId]);
      setLoading(false);
    }else{
      getFormFilters();
    }
  }, [formId])

  const getFormFilters = () =>{
    if(!formId) return;
    setLoading(true);
    FormTemplateService.getFormFieldFilters({
      'ids':[formId]
    }).then((data)=>{
      if(data){
        setFormFieldFilter({...formFieldFilter, ...data.form_fields});
        setDataSourceList({...dataSourceList, ...data.data_sources});
        setActiveFilters(data.form_fields[formId]);
        cacheDispatch({
          type: "UPDATE",
          payload: {
            [formFieldCacheKey]:data.form_fields,
            [dsCacheKey]:data.data_sources
          }
        });
      }
      setLoading(false);
    })
  }

  /*useEffect(()=>{
    if(formFields?.length > 0)
      getDataSource()
  }, [])

  const getDataSource = () =>{
    setLoading(true)
    const req = {
      'data_source_params':{
        'parent_key':'data_source_id',
        'data_source_id':formFields.filter(f => f.data_source_id).map(f => f.data_source_id), 
        'per_page' : 30
      }
    }
    Cs.getDataSource(req).then((data)=>{
      if(data){
        setDataSourceList(data)
      }
      setLoading(false)
    })
  }*/

  if(!activeFilters)
    return null;

  if(isLoading)
    return <div className="spinner"/>;

  return(
    <FormFields list={activeFilters}
      dataSourceList={dataSourceList}
      filter={filter} setFilter={setFilter}/>
  )
}

const FormFields = (props) => props.list.map((f, i)=>{

  /*if(!f.is_api_filter)
    return null*/
  //list=[], dataSourceList, filter, setFilter
  
  if(["autocomplete", "textInput"].includes(f.component)){
    return <TextSearch key={i} field={f} {...props}/> 
  }else if(f.data_source_id){
    return <CheckBoxFilter key={i} field={f} {...props}/> 
  }

  return null;
})

const TextSearch = ({field, dataSourceList, filter, setFilter}) =>{
  const {onSearchCallback, searchKeyword, clearSearch} = useSearch((value, name) => 
    setFilter({
      ...filter, 
      [field.client_id]: {'component':field.component, 'value':value}
    })
  );

  return(
    <div className="bg-white">
      <h5 className="font-16 text-center sub-title">
        {field.label}
      </h5> 
      <div className="flex aligncenter">
        <input className={"form-control m-b-5 f17"+(searchKeyword?.length>0?' br-green2':'')} 
          placeholder="Search"
          onChange={e=>onSearchCallback(e)} 
          value={searchKeyword}/>
        <i className="fas fa-times search-close" onClick={()=>clearSearch()}/>
      </div>
    </div>
  )
}

const CheckBoxFilter = ({field, dataSourceList, filter, setFilter}) =>{
  const toggle = (option, name) =>{
      if(option.value!=null && option.value != filter[name]?.value){
        setFilter({
          ...filter, 
          [name]:{'value':option.value, 'component':field.component}
        })
      }else{
        delete filter[name];
        setFilter({...filter});
      }
    }

    const exists = (value, name) =>{
      return filter[name]?.value === value;
    }
    const {getCount} = useFilterHook({filter});

    return(
      <div className="bg-white">
        <FilterAccordionPanel
          title={field.label}
          count={getCount(field.client_id)}
          isOpen={filter[field.client_id]}>
          <div className="mxh-250 scroll-auto m-t-10">
            <CheckBox list={dataSourceList?.[field.data_source_id]?.options || []}
              labelKey="label" valueKey="value"
              toggle={toggle} exists={exists}
              onToggleReturnObject={true}
              name={field.client_id} />
          </div>
        </FilterAccordionPanel>
      </div>
    )
}

const ActiveFormFieldFilters = ({formId, filter, setFilter}) =>{
  const {cacheState, cacheDispatch} = useContext(CacheContext);
  const formFieldCacheKey = 'formFieldFilter_'+formId;
  const {getCount} = useFilterHook({filter});
  
  const formFieldFilter = (cacheState[formFieldCacheKey]?.[formId] || []);

  return(
    <>
      {formFieldFilter.map((f, i) => 
        <FilterPill key={i} filter={filter} setFilter={setFilter}
        label={f.label} count={getCount(f.client_id)}
        filterKeys={[f.client_id]}/>
      )}
    </>
  )
}

export {FormFieldFilter, ActiveFormFieldFilters};