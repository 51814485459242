import env from '../../../env';
import axios from 'axios';
import BaseService from '../../../services/BaseService';

class FacilityService extends BaseService {

  async uploadCsv(req) {
    return await axios.post(`${env.health_backend}health/upload_csv/medical_facilities`, req);
  }

  async report(req) {
    return await axios.get(`${env.health_backend}health/report/medical_facilities`, { params: req });
  }

  async getLocations(req) {
    return await axios.post(`${env.health_backend}locations/medical_facilities`, req);
  }

  async getPhcAutofill(req) {
    return await axios.get(env.health_backend + 'autofill/phc_list', { params: req })
  }

  async getTalukasAutofill(req) {
    return await axios.get(env.health_backend + 'autofill/phc_talukas', { params: req })
  }

  async getVillagesAutofill(req) {
    return await axios.get(env.health_backend + 'autofill/phc_villages', { params: req })
  }

  async getPhcTypesAutofill(req) {
    return await axios.get(env.health_backend + 'autofill/phc_types', { params: req })
  }

}

export default new FacilityService('health_backend', 'health/medical_facilities');