import env from '../env';
import axios from 'axios';
import BaseService from './BaseService';
const url = `${env.health_backend}health/checklists`

class ChecklistService extends BaseService {

  async status(checklist) {
    return axios.put(`${url}/${checklist.id}/status`, checklist);
  }


}

export default new ChecklistService('health_backend', 'health/checklists');
