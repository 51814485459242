import {Workbox} from 'workbox-window';

const RegisterSw = async () => {
	if ('serviceWorker' in navigator) {
	    const wb = new Workbox('/sw.js');

	    wb.addEventListener('installed', event => {
	      /**
	       * We have the condition - event.isUpdate because we don't want to show
	       * this message on the very first service worker installation,
	       * only on the updated
	       */
	      if (event.isUpdate) {
	        if (window.confirm(`New app update is available!. Click OK to refresh`)) {
	          window.location.reload();
	        }
	      }
	    });
	    wb.register();
  	}
}

const UnregisterSw = async ()=>{
	var registrations = await navigator.serviceWorker.getRegistrations();
	if (registrations.length > 0) {
		for (const registration of registrations) {
            await registration.unregister();
        }
    }
}

export {RegisterSw, UnregisterSw}