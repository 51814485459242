import React, { useState } from 'react';

const useToggle = () => {
  const [toggleIdx , setToggleIdx] = useState();

  const toggle = (value) => {
    if(value != toggleIdx){
      setToggleIdx(value);
    }else{
      setToggleIdx()
    }
  }

  return {
    toggleIdx,
    toggle
  }

}

const ToggleIco = ({active, className=''}) =>{
  return <span className={(active?'fas fa-angle-down':'fas fa-angle-up')+' '+className}/>;
}

export {useToggle, ToggleIco};