import { useState, useRef, useMemo } from "react";
import TrainingProgramsService from './TrainingProgramsService';
import TrainingSessionService from './TrainingSession/TrainingSessionService';
import useSort from '../../hooks/useSorting';
import FormTemplateId from "../../constants/FormTemplateList";
import CheckAccess from '../Roles/CheckAccess';
import { useCurrentUserHook } from "../Authentication/useUserHook";

const useTrainingProgramHook = (params) => {
  const {currentUser} = useCurrentUserHook()

  const [list, setList] = useState([]);
  const [formFieldsList, setFormFieldsList] = useState({});
  const [currentpage, setCurrentpage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [pagemeta, setPagemeta] = useState([]);
  const [dataSource, setDataSource] = useState({});

  const permission = useMemo(() => CheckAccess.getMemberPermissionBySid(currentUser, 'training', 'training_admin'), [currentUser.id])

  let {current:scope} = useRef({
    'selectedProgram':{},
    'requestParams':{
      'page':1,
      'per_page':100,
      'filter':{
        'assigned_to_organization_id':params.organization_id
      },
      'form_fields_params':{
        'ids':[FormTemplateId.TrainingProgramForm]
      }
    }
  });

  const getAll = () =>{
    if(permission?.read){
      setLoading(true)
      TrainingProgramsService.getAll(scope.requestParams).then((res)=>{
        if(res.status === 200){
          setList(res.data.training_programs);
          setFormFieldsList(res.data.form_fields);
          setLoading(false);
        }
      })
    }
  }

  const getTrainingProgramByOrg = async (filter) =>{
    setLoading(true);
    const {status, data} = await TrainingProgramsService.getTrainingProgramByOrg({
      "filter":filter
    })
    if(status === 200){
      setList(data);
      setLoading(false);
    }
  }

  const getTrainingSessionByFilter = async (filter) =>{
    setLoading(true);
    const {status, data} = await TrainingSessionService.getTrainingSessionByFilter({
      "filter":filter
    })
    if(status === 200){
      setList(data);
      setLoading(false);
    }
  }

  const {sort} = useSort(scope.requestParams, getAll)

  return {
    scope,
    currentUser,
    list, setList,
    formFieldsList, setFormFieldsList,
    currentpage, setCurrentpage,
    loading, setLoading,
    pagemeta, setPagemeta,
    getAll,
    sort,
    getTrainingProgramByOrg,
    getTrainingSessionByFilter
  }
}

export default useTrainingProgramHook;