import env from '../../../env';
import BaseService from "../../../services/BaseService";
import axios from 'axios';
const url = 'health/organization_member'
class OrganizationMemberService extends BaseService {

	async getJourneyProfiles(req) {
		return await axios.post(`${env.health_backend}${url}/journey_profiles`, req);
	}

	async getGeoData(req) {
		return await axios.post(`${env.health_backend}${url}/geo_data`, req);
	}

	async sendInvite(req) {
		return await axios.post(`${env.health_backend}${url}/send_invite`, req);
	}

	async updateReport(req) {
		return await axios.post(`${env.health_backend}${url}/report/refresh`, req)
	}

	async search(req) {
		return await axios.get(`${env.health_backend}${url}/search`, { params: req })
	}

	async getChartReport(req) {
		return await axios.post(`${env.health_backend}${url}/report/charts`, req)
	}

	async isValidMember(req) {
		const objString = new URLSearchParams(req).toString();
		window.open(`${env.health_backend}validate_member?${objString}`, "_self")	
		/*let res = await axios.get(`${env.health_backend}validate_member`, { params: req })
		return res*/
	}

	async getMCJMemberActivity(req) {
		return await axios.post(`${env.health_backend}${url}/mcj/activity`, req)
	}

	async bulkUpdate(req) {
		return axios.post(`${env.health_backend}${url}/bulk_update`, req)
	}

	async getPptProcessActivity(req){
  		let res = await axios.post(`${env.health_backend}${url}/ppt_process/activity`, req)
	    return res
  	}
}

export default new OrganizationMemberService('health_backend', `${url}s`);