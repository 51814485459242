import React, { useEffect, useState, useContext, useRef } from "react"
import { AuthContext } from "../../../contexts/Authentication/AuthStateProvider"
import PreLoadSpinner from "../../PreLoadSpinner"
import DynamicForm from "../../FormBuilder/DynamicForm"
import FormTemplateId from "../../../constants/FormTemplateList"
import querystringify from "querystringify"
import FormContainer from '../../FormBuilder/FormContainer'
import GeoLocation from "../../Common/GeoLocation"
import Cs from "../../../services/CommonService"
import CaseReportService from './CaseReportService'
import { useCurrentUserHook } from "../../Authentication/useUserHook"
// let formMode = null
// let report = {}
// let data = {}
// let formFn = {}

function CaseReportForm(props) {
  const {currentUser} = useCurrentUserHook()
  const [isFormRendered, setFormRendered] = useState(false)
  const [post, setPost] = useState({})
  let { current: scope } = useRef({ formFn: {}})

  const params = props.match.params
  let queryParam = querystringify.parse(props.location.search);

  const get = () => {
    CaseReportService.get(params.id).then((res) => {
      if (res.status === 200) {
        scope.report = { ...res.data.case_report, ...scope.report.geo_data }
        scope.data = { ...scope.data, ...scope.report.data }
        setFormRendered(true)
      }
    }).catch(function (res) {
      setFormRendered(true)
      scope.report = null
    });
  };

  useEffect(() => {
    scope.formFn = {
      form_type: 'case_report',
    };
    scope.report = {};
    scope.data = {};
    setFormMode();
  }, []);

  const setFormMode = () =>{
    if (params.id) {
      get()
      scope.formMode = "edit-form-submissions"
    } else if (!isFormRendered) {
      scope.formMode = "create-form-submissions"
      setFormRendered(true)
    }
  }

  const create = (report) => {
    report.organization_id = currentUser.current_organization_id
    report.created_by = currentUser.id
    report.updated_by = currentUser.id
    report.journey_profile_id = queryParam.journey_profile_id
    report.form_id = FormTemplateId.CaseReportForm
    report.activity_id = params.activity_id
    CaseReportService.create(report).then((res) => {
      if (res.status === 201) {
        onSubmitPageRedirect(res.data.case_report)
      }
    }).catch((res) => {
      onSubmitPageRedirect()
    })
  }

  const update = (report) => {
    CaseReportService.update(report).then((res) => {
      if (res.status === 204) {
        onSubmitPageRedirect(report)
      }
    }).catch((res) => {

    })
  }

  const onSubmitPageRedirect = (report) => {
    if(props.isPopupMode){
      props.onFormSubmit(report)
    }
  }

  if (isFormRendered) {
    return (
      <FormContainer post={post} isPopupMode={props.isPopupMode}>
        <DynamicForm
          formMode={scope.formMode}
          formId={FormTemplateId.CaseReportForm}
          form={scope.report}
          data={scope.data}
          formFn={scope.formFn}
          onCreate={create}
          onUpdate={update}
          post={post}
          onCancel={()=>onSubmitPageRedirect()}
          setPost={setPost}
          isPopupMode={props.isPopupMode}/>
        <GeoLocation geoData={scope.report}/>
      </FormContainer>
    )
  } else {
    return null
  }
}

export default CaseReportForm;