import React, { lazy, Suspense } from 'react';
import PrivateRoute from "../../routes/PrivateRoute";
const InventoryForm = lazy(() => import('./ResourceInventories/InventoryForm'));
const InventoryList = lazy(() => import('./ResourceInventories/InventoryList'));
const StockForm = lazy(() => import('./ResourceStocks/StockForm'));
const StockList = lazy(() => import('./ResourceStocks/StockList'));
const SupplyForm = lazy(() => import('./ResourceSupplies/SupplyForm'));
const SupplyList = lazy(() => import('./ResourceSupplies/SupplyList'));
  
const ResourceManagementRoute = (props) => (
  <Suspense fallback={<div className="spinner"/>}>	
    <PrivateRoute path="/resource/inventories" searchString={props.searchString} component={InventoryList} exact />
    <PrivateRoute path="/new/resource/inventory/:organization_id/:id?" component={InventoryForm} exact />
    <PrivateRoute path="/inventoy/:resource_inventory_id/stocks" searchString={props.searchString} component={StockList} exact />
    <PrivateRoute path="/new/stock/:organization_id/:resource_inventory_id/:id?" component={StockForm} exact />
    <PrivateRoute path="/inventoy/:resource_inventory_id/supplies" searchString={props.searchString} component={SupplyList} exact />
    <PrivateRoute path="/new/supply/:organization_id/:resource_inventory_id/:id?" component={SupplyForm} exact />
  </Suspense>
)

export default ResourceManagementRoute;