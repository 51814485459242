import React, {useState, useEffect} from "react";
import importScript from '../../hooks/importScript';
import env from '../../env';
import { useHistory } from "react-router-dom";
import {NotificationPopup} from "../Common/NotificationPopup";
import { publish } from "../../hooks/useEvents"
import Cs from "../../services/CommonService"

let url = env.socket;

const useSocket = (currentUser) => {
    
    const history = useHistory()
    let [isConnected, setIsConnected] = useState(false)
    let [socket, setSocket] = useState({})
    let [newMessage, setNewMessage] = useState()

    useEffect(() => {
        if(!isConnected)importScript('/scripts/socket.io.min.js', init);
        return () => {
          try{  
            init();
            if (window.Notification && Notification.permission !== "granted") {
                Notification.requestPermission(function (status) {
                    if (Notification.permission !== status) {
                        Notification.permission = status;
                    }
                })
            }
          }catch(e){
            console.log(e)
          }  
        }
    }, []);

    function init(){
        if(!currentUser)return;
        let socket = {}
        socket.io = window.io(
            env.socket+'?token='+currentUser.id,
            {
                "transports": ["websocket"], 
                "secure": true,
                "reconnection": true,
                "reconnectionDelay": 1000,
                "reconnectionDelayMax" : 5000,
                "reconnectionAttempts": 25
            },
        );

        //io('ws://127.0.0.1:9001', {transports: ["websocket"]});
        
        setIsConnected(true);    

        /*socket.io.on("welcome", (data) => {
          console.log("Message: ", data)  
        })  */
        
        /*socket.io.on('connect', function () {
            socket.io.emit('join', {id: currentUser.id});
        })*/

        socket.io.on("chat_message", (res) => {
            //socket.onChatMessageReceived(res)
            if(res.notification_type && res[res.notification_type]){
                const data = res[res.notification_type];
                res.date = data.updated_at?Cs.formatUpdateDate(data.updated_at, 'MMM D, YYYY h:mm A'):null;
            }

            if (window.Notification && Notification.permission === "granted") {
                systemNotification(res);
            }else if (window.Notification && Notification.permission !== "denied") {
                Notification.requestPermission((status) => {
                    if (status === "granted") {
                       systemNotification(res); 
                    }else {
                       uiNotification(res);
                    }
                })
            }else {
                uiNotification(res);
            }
        })
 
    }

    const publishEvent = (eventType, data) =>{
        publish(eventType, data);
    }

    const uiNotification = (res) =>{
        setNewMessage(res)
        if(res.notification_type === 'chat'){
            res.notifiable_type = 'chat'
            //setSocketData && setSocketData(res)
        }else if(res.notification_type === 'comment'){
            publishEvent('inc_comment', res.comment)
            publishEvent('comment', res.comment)
        }else if(res.notification_type === 'audio_file_upload'){
            publishEvent('inc_audio_msg', res.audio_file_upload)
        }
        /*res.sender_name = res.sender_name
        setNotification(res);*/
    }

    const systemNotification = (res) =>{
        let notification = {}

        if(res.notification_type === 'chat'){
            notification = new Notification(`Chat-${res.author.name}`, {
                body: res.message,
                icon: 'images/cueTree_logo.png'
            })
            //setSocketData && setSocketData(res)
        }else{
            if(res.notification_type === 'comment'){
                publishEvent('inc_comment', res.comment)
                publishEvent('comment', res.comment)
            }
            notification = new Notification(res.title, {
                body: res.message,
                icon: 'images/cueTree_logo.png'
            })
        }  

        notification.onclick = function(event) {
            event.preventDefault(); // prevent the browser from focusing the Notification's tab
            if(res.notification_type == 'chat'){
                //socket.openChatUser(res)
            }else{
                history.push(res.url)
                //window.open('https://health.beta.cuetree.com/redirect?data='+JSON.stringify(res || {}));    
            }
        }
    }

    return {
        socket,
        newMessage,
        setNewMessage
    }

}

const PopupNotification = ({currentUser}) =>{
    const history = useHistory()
    const {newMessage, setNewMessage} = useSocket(currentUser)

    const style = {
        position: 'absolute',
        zIndex: 99,
        width: '100%'
    }

    if(newMessage)
        return(
            <div style={style}>  
              <NotificationPopup 
                iconClass="fas fa-circle-info lred"
                cardCss="br-grey-2"
                title={newMessage.title}
                message={newMessage.message} 
                onSuccess={()=>{history.push(newMessage.url);setNewMessage(null)}}
                onCancel={()=>setNewMessage(null)} 
                successLabel="View message"
                cancelLabel="Will check later"
                date={newMessage.date}
                imageIco={newMessage.profile_image?(env.file_url+newMessage.profile_image):null}/>
            </div>
        )

    return null
}

export {useSocket, PopupNotification}