import React from "react"
import ApexChart from "../../../Charts/ApexChart"
import DynamicTable from "../../../Common/DynamicTable"
import { EmptyRowAlert } from '../../../Common/TableView'

const PainTrackingTable = (props) =>{
  const {addNotes, addChecklist, formFields, sort, symptoms, openForm, component, dataSources, graphData, Footer, requestParams} = props

  const OptionsEle = ({item}) => (
    <td className="text-center">
      <span id="record_edit" onClick={e=>openForm(component, item.id)} className="fas fa-edit m-r-15 font-18 text-muted"/>  
      <span className="far fa-comment m-r-15 font-18 text-muted" onClick={e=>addNotes(e, component.sid)}/>
      <span className="far fa-check-circle m-r-15 font-18 text-muted" onClick={e=>addChecklist(e, component.sid)}/>
    </td>
  )
  
  return(
    <>
      {graphData.bar_chart?.length > 0 &&
        <div className="col-md-6 p-5">
          <div className="br-grey ht-450 wk-center bg-white">
            <ApexChart id="chat_2" chartType="bar" 
              title="Record Pain Sensation" data={graphData.bar_chart} />
            <Footer type="pain_bar_chart" />
          </div>
        </div>
      }

      <div className="col-md-6 p-5">
        <div className="table-responsive">
          <DynamicTable requestParams={requestParams} onSort={sort} 
            formFields={formFields} formDataList={symptoms} 
            tClass="table"
            OptionsEle={OptionsEle}/>
          {symptoms.length === 0 &&
            <EmptyRowAlert/>
          }
        </div>
      </div>
    </>
  )    
}

export default PainTrackingTable;