import { useState, useEffect } from "react"
import Cs from '../../services/CommonService'
import axios from 'axios'
import env from '../../env'
import { usePageLocale } from '../../hooks/useLocale'
import DataSourceService from "../../services/DataSourceService"
import { useCurrentUserHook } from "../Authentication/useUserHook"

function usePushNotifications(currentUser) {
  const {localeDispatch} = useCurrentUserHook()
  const { labelLocale } = usePageLocale(currentUser.current_locale, 'pushAlertMessage')

  const [isServiceWorkerReady, setServiceWorkerStatus] = useState(false)
  const [userConsent, setSuserConsent] = useState(window.Notification && Notification.permission);
  //to manage the user consent: Notification.permission is a JavaScript native function that return the current state of the permission
  //We initialize the userConsent with that value
  const [userSubscription, setUserSubscription] = useState(null);
  //to manage the use push notification subscription
  const [pushServerSubscriptionId, setPushServerSubscriptionId] = useState();
  //to manage the push server subscription
  const [error, setError] = useState(null);
  //to manage errors
  const [loading, setLoading] = useState(true);
  //to manage async actions
  const [userAgent, setUserAgent] = useState(navigator.userAgent);

  const isPushNotificationSupported = "serviceWorker" in navigator && "PushManager" in window && "Notification" in window;

  useEffect(() => {
    checkServiceWorker(setServiceWorkerStatus)
    if (isServiceWorkerReady && isPushNotificationSupported) {
      setLoading(false);
      setError(false);
      setLoading(false);
      if(userConsent !== "granted")
        askUserPermission()
    }else{
      setLoading(false);
    }
  }, [isServiceWorkerReady]);
  
  useEffect(() => {
    if(isServiceWorkerReady){
      setLoading(true);
      setError(false);
      getPushAlertMessage()
      const getExixtingSubscription = async () => {
        const existingSubscription = await getUserSubscription();
        setUserSubscription(existingSubscription);
        setLoading(false);
      }
      getExixtingSubscription();
    }
  }, [isServiceWorkerReady]);

  const getPushAlertMessage = () =>{
    setLoading(true)
    DataSourceService.getDataSource({
      'data_source_params':{
        'data_source_id':[3995],
        'response_type':'object', 
        'group_by':'sid',
        'locale':currentUser.current_locale,
      }
    }).then((data)=>{
      if(data){
        localeDispatch({
          type: "UPDATE",
          payload: {
            'pushAlertMessage':(data.push_setup_alert),
          }
        })
        setLoading(false)
      }
    })
  }

  const getUserAgent = () =>{
    if (navigator.userAgent.indexOf('Safari') !== -1 && navigator.userAgent.indexOf('Chrome') === -1) {
      console.log("Safari");
    } else {
      console.log(window.userAgent);
    }
  }

  function getUserSubscription() {
    //wait for service worker installation to be ready, and then
    return navigator.serviceWorker.ready
      .then(function(serviceWorker) {
        return serviceWorker.pushManager.getSubscription();
      })
      .then(function(pushSubscription) {
        return pushSubscription;
      });
  }

  const askUserPermission = () => {
    setLoading(true)
    setError(false)
    if(window.Notification){
      Notification.requestPermission(function (permission) {
        if (permission !== "granted") {
          setError({
            name: labelLocale(5), // "Consent denied",
            message: labelLocale(5 ,'message'), // "You denied the consent to receive notifications",
            code: 0
          })
        }
        setLoading(false)
      })
    }
  }

  async function subscribe() {
    //wait for service worker installation to be ready
    const serviceWorker = await navigator.serviceWorker.ready;
    const response = await axios(`${env.health_backend}push_notifications/vapid_public_key`);
    // Chrome doesn't accept the base64-encoded (string) vapidPublicKey yet
    // urlBase64ToUint8Array() is defined 
    const convertedVapidKey = urlBase64ToUint8Array(response.data);

    // subscribe and return the subscription
    return await serviceWorker.pushManager.subscribe({
      userVisibleOnly: true,
      applicationServerKey: convertedVapidKey
    });
  }

  const susbribeToPushNotification = () => {
    setLoading(true);
    setError(false);
    subscribe()
      .then(function(subscrition) {
        setUserSubscription(subscrition);
        setLoading(false);
        sendSubscriptionToApi(subscrition)
      })
      .catch(err => {
        console.error("Couldn't create the notification subscription", err, "name:", err.name, "message:", err.message, "code:", err.code);
        setError(err);
        setLoading(false);
      });
  };

  /**
   * define a click handler that sends the push susbcribtion to the push server.
   * Once the subscription ics created on the server, it saves the id using the hook setPushServerSubscriptionId
   */
  function sendSubscriptionToApi(subscrition){
    setLoading(true);
    setError(false);
    // Send the subscription details to the server using the Fetch API.
    let req = {
      method: 'post',
      url: `${env.health_backend}push_notifications/register`,
      data: {'subscription': subscrition, 'setting_id':currentUser?.setting.id}
    }

    Cs.useAxios(req).then((res)=>{
      //sendNotification(subscription) 
      setPushServerSubscriptionId(res.data.success);
      setLoading(false);
      sendNotification()
    }).catch((err)=>{
      setLoading(false);
      setError(err);
    })
  }

  function sendNotification(){
    setLoading(true);
    setError(false);
    setTimeout(function() {
      let req = {
        method: 'post',
        url: `${env.health_backend}push_notifications/send_notification`,
        data: {
          'message':'Welcome to Cuedwell'
        }
      }

      Cs.useAxios(req).then((res)=>{
        setLoading(false);
      }).catch((err)=>{
        setLoading(false);
        setError(err);
      })
    }, 5000)
  }

  function urlBase64ToUint8Array(base64String) {
    var padding = '='.repeat((4 - base64String.length % 4) % 4);
    var base64 = (base64String + padding)
      .replace(/\-/g, '+')
      .replace(/_/g, '/');
 
    var rawData = window.atob(base64);
    var outputArray = new Uint8Array(rawData.length);
 
    for (var i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
  }

  return {
    isServiceWorkerReady,
    isPushNotificationSupported,
    askUserPermission,
    susbribeToPushNotification,
    sendSubscriptionToApi,
    pushServerSubscriptionId,
    sendNotification,
    urlBase64ToUint8Array,
    userConsent,
    userSubscription,
    error,
    loading,
    labelLocale
  };
}

const checkServiceWorker = async (callback) => {
  const serviceWorker = await navigator.serviceWorker.ready;
  if(serviceWorker){
    callback(true)
  }
}

export {checkServiceWorker, usePushNotifications}