import React from "react"

const SpanRichTextView = ({html, className, style}) => {
  return (
    <span className={className} style={style} dangerouslySetInnerHTML={{ __html: html }}></span>
  )
}

const DivRichTextView = ({html, className, style}) => {
  return (
    <div className={className} style={style} dangerouslySetInnerHTML={{ __html: html }}></div>
  )
}

const ParaRichTextView = ({html, className, style}) => {
  return (
    <div className={className} style={style} dangerouslySetInnerHTML={{ __html: html }}></div>
  )
}

const LabelRichTextView = ({html, className, style}) => {
  return (
    <div className={className} style={style} dangerouslySetInnerHTML={{ __html: html }}></div>
  )
}

export {SpanRichTextView, DivRichTextView,ParaRichTextView,LabelRichTextView};