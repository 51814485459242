import React, {useState} from 'react'
import FormHelper from '../../../components/FormBuilder/FormHelpers'
import {FieldLabel, OptionLabel, Description, RequiredMsg, FieldReview, FieldPoint} from '../FieldLabel'
import {ImageFlexList} from '../../Common/Image'
import {CueTip} from '../../../components/Common/CueTooltip';

const InputRadio = ({renderField, formFn, field, position, formData, readOnly, errors, forceUpdate, isFormWizard, openDataSourceModal, openFieldLabelModal, currentLocale, fieldReview}) => {
  let [model, setModel] = useState(formData);

  if(!renderField){
    return null
  }

  let inputAttributes = {
    'type':'radio',
    'name': field.client_id,
    'placeholder': field.placeholder,
    'disabled':readOnly
  }

  let parentAttributes = {
    'tooltip': field.tooltip,
    'id': field.client_id,
  }

  let labelAttributes = FormHelper.setLabelStyle(field, isFormWizard, parentAttributes);

  let validation = {
    required: field.required
  }
  
  let opkv = field.options_key_value;
  
  const handleChange = (event) =>{
    const { target } = event;
    const { name } = target;
    formData[name] = FormHelper.isNumeric(target.value);
    setModel(target.value);
    setError();
    /*Added timeout for form id 477->head_support check issue*/
    /*Duplicate field issue head_support*/
    setTimeout(function() {
      forceUpdate();
      target.checked = true;
    }, 0);
  }

  const selected = (value) => {
    return formData[field.client_id] == value;
  }

  const setError = (event) => {
    if(field.required){
      const name = field.client_id;
      errors[name].invalid = formData[name]==null?true:false;
      errors[name].touched = true;
      if(errors[name].invalid){
        errors.invalid[name] = true;
      }else{
        delete errors.invalid[name]
      }
    }
  }
  
  const Error = () => {
    if(field.required && (errors[field.client_id].touched || errors.isSubmitted) && errors[field.client_id].invalid){
      return(
        <div className="errormsg">
          <div>
            <span className="fas fa-exclamation-triangle m-r-5"/>
            <RequiredMsg {...{field, currentLocale}}/>
          </div>
        </div>
      )
    }
    return null;
  }

  //FormHelper.setDisabled(field, inputAttributes);
  //FormHelper.checkFieldValidity(field, formData, errors);
  
  return (
  	<div {...parentAttributes}>
      <div className="flex coldir m-b-20 tooltip">
        <CueTip 
          positionCss={position>1?'top':'bottom'}
          tooltip={field.tooltip}
          currentLocale={currentLocale}
          locale={field.locale?.tooltip}/>
        <FieldLabel field={field} 
          isFormWizard={isFormWizard}
          labelAttributes={labelAttributes} 
          currentLocale={currentLocale}
          openDataSourceModal={openDataSourceModal}
          openFieldLabelModal={openFieldLabelModal}/>
        <div className={opkv.options?.length <=3 ?"flex coldir-xs cgap-35":""}>
          {(opkv && opkv.options) 
            ? <OptionsObjList list={opkv.options} handleChange={handleChange} inputAttributes={inputAttributes} 
              selected={formData[field.client_id]} currentLocale={currentLocale} showOnlyImage={field.show_only_image}/> 
            : <OptionsStringList list={field.options} handleChange={handleChange} inputAttributes={inputAttributes} 
              selected={formData[field.client_id]}/>
          }  
        </div>   
        <FieldPoint userRefId={formFn.userRefId} clientId={field.client_id} 
          point={field.point} currentLocale={currentLocale}  
          negPoint={field.incorrect_point}/>
        <Description description={field.description} 
          currentLocale={currentLocale}
          locale={field.locale?.description}/>
        <FieldReview review={fieldReview}/>
        <Error/>
      </div>
    </div> 
  )
}

const OptionsObjList = ({list, inputAttributes, handleChange, selected, currentLocale, showOnlyImage=false}) => list.map((o, k) =>{
  const radioStyle = {top: o.images?'1px':''}
  
  return(
    <label className={`radio-container br-grey-hov ${o.read_only=='yes'?'input-disabled':''} br-btm-grey ${showOnlyImage?'':''}`} key={k}>
      {o.images ? <ImageFlexList styleName="img-responsive" list={o.images}/> : null}
      {showOnlyImage ? 
        null
        :
        <span className="black-light-1 f14">
          <OptionLabel option={o} currentLocale={currentLocale}/>
        </span>
      }
      <input type="radio" onChange={handleChange} {...inputAttributes}  defaultValue={o.value} checked={selected==o.value}/>
      <span className="radio-checkmark" style={radioStyle}></span>
    </label>
  )
})

const OptionsStringList = ({list, inputAttributes, handleChange, selected}) => list.map((o,k) =>
  <label className="radio-container pointer" key={k}>
    <span className="black-light-1 f14">{o}</span>
    <input type="radio" onChange={handleChange} {...inputAttributes}  defaultValue={o} checked={selected==o}/>
    <span className="radio-checkmark"></span>
  </label>
)

export default InputRadio;