const useUserAgent = () => {
  
   // Detects if device is on iOS 
  const isIos = () => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    return /iphone|ipad|ipod/.test( userAgent );
  }

  // Detects if device is in standalone mode
  const isInStandaloneMode = () => ('standalone' in window.navigator) && (window.navigator.standalone);

  const isPwaMode = () =>{
    if (isInStandaloneMode() || window.matchMedia('(display-mode: standalone)').matches) {
      return true
    }
    return false
  }

  return {
    isPwaMode,
    isIos,
    isInStandaloneMode
  }

};

const useDeviceInfo = () =>{

  const getDeviceStorageInfo = async () =>{
    if (navigator.storage && navigator.storage.estimate) {
      const quota = await navigator.storage.estimate();
      // quota.usage -> Number of bytes used.
      // quota.quota -> Maximum number of bytes available.
      /*const percentageUsed = (quota.usage / quota.quota) * 100;
      console.log(`You've used ${percentageUsed}% of the available storage.`);
      const remaining = quota.quota - quota.usage;
      console.log(`You can write up to ${remaining} more bytes.`);
      console.log((quota.quota / 1024 / 1024).toFixed(2) + "MB");*/
      return {
        'usage': (quota.usage / 1024 / 1024).toFixed(2) + "MB",
        'quota': (quota.quota / 1024 / 1024).toFixed(2) + "MB"
      }
    }
  }

  const getNetworkConnectionInfo = () => {
    if(navigator.connection){
      return{
        downlink: navigator.connection.downlink,
        effectiveType: navigator.connection.effectiveType,
        rtt: navigator.connection.rtt
      }
    }
  }

  const getDeviceMemory = () => navigator.deviceMemory ? navigator.deviceMemory : null;

  const getDeviceConcurrency = () => navigator.hardwareConcurrency ? navigator.hardwareConcurrency : null;

  const getDeviceInfo = async () =>{
    try{
      return {
        'storage': await getDeviceStorageInfo(),
        'connection': getNetworkConnectionInfo(),
        'memory': getDeviceMemory(),
        'concurrency': getDeviceConcurrency()
      }
    }catch(e){
      console.log(e.message);
      return null;
    }
  }

  return {
    getDeviceInfo
  }
}

export {useUserAgent, useDeviceInfo};
