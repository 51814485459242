import React, { useEffect, useState, useRef } from "react"
import DynamicForm from "../FormBuilder/DynamicForm"
import FormTemplateId from "../../constants/FormTemplateList"
import OrganizationService from "./OrganizationService"
import FormContainer from '../FormBuilder/FormContainer'
import GeoLocation from "../Common/GeoLocation"
import { CityState } from "../Common/CityState"
import GenericModal from '../Modals/GenericModal'
import useModal from "../../hooks/useModal"
import { NotificationPopup } from '../Common/NotificationPopup'
import { useCurrentUserHook } from "../Authentication/useUserHook"

const message = ` Thank you for your interest in the cueTree Health application.
  Your account is almost ready.
  A cueTree application representative will contact you to confirm your business
  information before your account can be activated.
  We look forward to serving your business.`

function OrganizationForm(props) {
  let { current: scope } = useRef({ formFn: {}});
  const {currentUser, dispatch} = useCurrentUserHook()
  const [isFormRendered, setFormRendered] = useState(false)
  const [post, setPost] = useState({})

  const params = props.match.params
  const path = props.match.path
  const history = props.history

  const {isOpen:isInactiveOrgNoticeOpen, toggleModal:toggleInactiveOrgNotice} = useModal()

  /*TODO: Move API to Router to load data before view*/
  const get = () => {
    OrganizationService.get(params.id).then((res) => {
      if (res.status == 200) {
        scope.organization = {...res.data.organization, ...scope.organization.geo_data}
        scope.data = scope.organization.data || {}
        setFormRendered(true)
      }
    }).catch((res) => {
      scope.organization = null
    })
  }

  useEffect(() => {
    scope.organization = {}
    scope.data = {}
    scope.formFn = {form_type: "health.organization", editPermission:true}
    scope.formMode = "create-form-submissions"
    scope.data = {}
    if (params.id) {
      get()
      scope.formMode = "edit-form-submissions"
    } else if (!isFormRendered) {
      setFormRendered(true)
    }
  }, [])

  const create = (organization) => {
    OrganizationService.create(organization).then(({status, data}) => {
      if (status == 201) {
        if(!path.includes('/organization/')){
          currentUser.current_member = data.organization_member
          currentUser.current_organization = data.organization
          currentUser.current_organization_id = data.organization.id
          dispatch({
            type: "UPDATE",
            payload: {'user':currentUser},
          })
        }   
        onSubmitPageRedirect();
      }
    })
  }

  const update = (organization) => {
    OrganizationService.update(organization).then((res) => {
      if (res.status == 204) {
        if(currentUser.current_organization_id == organization.id){
          currentUser.current_organization = organization
          dispatch({
            type: "UPDATE",
            payload: {'user':currentUser},
          })
        }
        onSubmitPageRedirect()
      }
    })
  }

  const onSubmitPageRedirect = (cancel) => {
    if(path.includes('/organization/')){
      history.push('/list/organizations')
    }else if(currentUser.current_organization.is_active==false && !cancel){
      toggleInactiveOrgNotice()
    }else{
      history.push('/health/table')
    }
  }

  const onCancel = () => onSubmitPageRedirect(true);

  if (isFormRendered) {
    return (
      <FormContainer post={post} isPopupMode={props.isPopupMode}>
        <DynamicForm formMode={scope.formMode}
          formId={FormTemplateId.OrganizationForm}
          form={scope.organization}
          data={scope.data}
          formFn={scope.formFn}
          onCreate={create}
          onUpdate={update}
          post={post}
          onCancel={onSubmitPageRedirect}
          setPost={setPost}
          isPopupMode={props.isPopupMode}/>
        <GeoLocation geoData={scope.organization}/>
        <CityState formFn={scope.formFn} data={scope.data}/>
        {isInactiveOrgNoticeOpen &&
          <GenericModal component={NotificationPopup} toggleModal={toggleInactiveOrgNotice} 
            isOpen={isInactiveOrgNoticeOpen} message={message} onSuccess={onCancel}/>
        }
      </FormContainer>
    )
  } else {
    return null
  }
}

export default OrganizationForm;