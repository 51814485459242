import React, { useState, useEffect } from "react";
import useModal from "../../hooks/useModal";
import { usePageLocale } from '../../hooks/useLocale';
import { CueTip } from "../Common/CueTooltip";
import CommentsModal from "./CommentsModal";
import { subscribe, unsubscribe } from "../../hooks/useEvents";

const CommentsBtn = ({isBtn=true, className, badgeClassName, item, commentableType, commentableId, currentUser, onInitOpen=false}) =>{

  const [count, setCount] = useState(item.note_count || 0)
  const [badgeStyle, setBadgeStyle] = useState({})

  const { isOpen: isNotesOpen, toggleModal:toggleNotesModal } = useModal(onInitOpen)
  const { labelLocale } = usePageLocale(currentUser.current_locale, 'casePortalPage')

  const incrementCount = (number) => setCount(number!==null>1?(parseInt(count) + number):0)

  useEffect(()=>{
    setBadgeStyle(count>0?{'background':'red'}:{})
  }, [count])

  useEffect(()=>{
    subscribe("inc_comment", ({detail:data}) => {
      if(data.commentable_id === commentableId && data.commentable_type === commentableType){
        incrementCount(1);
      }
    })
    return () => {
      unsubscribe("inc_comment", () => {})
    }
  }, [])

  return(
    <>
      {isBtn ?
        <span id="chat_btn" data-tip={labelLocale(7)} className={className?className:"bg-grey-4 round-btn m-r-5 m-t-4 tooltip"} onClick={(e)=>toggleNotesModal(e)}>
            <i className="fas fa-comment" />
            <span className={"badge-2 up "+badgeClassName} style={badgeStyle}>{count}</span>
            <CueTip tooltip={labelLocale(7)} positionCss="bottom"/>
        </span>
        :
        <span onClick={(e)=>toggleNotesModal(e)} className="fas fa-comment m-l-20 highlight"/>
      }
      {isNotesOpen && 
        <CommentsModal 
          isOpen={isNotesOpen} 
          toggleModal={toggleNotesModal} 
          incrementCount={incrementCount}
          item={item} 
          type={commentableType}/>
      }
    </>
  )
}

export {CommentsBtn};