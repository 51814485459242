import React, {Fragment, useState, useEffect} from "react"
import useStyle from '../../hooks/useStyle'
import GenericModal from "../Modals/GenericModal"
import DigitalContentService from './DigitalContentService'
import UserDataViewService from "../../services/UserDataViewService"
import {FontAwesomeBtn} from "../Common/Button"
import {Image} from "../Common/Image"
import {AudioPlayer} from "../Common/RecordAudio"
import {PlannerVideoPlayer} from "../Common/VideoPlayer"
import Cs from '../../services/CommonService'
import { ParaRichTextView } from "../Common/RichTextView"

const FeaturesPopup = ({features, updateAsRead}) => {
  useStyle('dashboard')      
  const f = features[0]
  return(
    <div id="ct" className="col-xs-12">
      <div className="card card-style">
        <div className="p-15 card-title-gray">
          <h3>
            <i className="fa fa-info-circle"/>
            <span className="m-l-10">{f.data.title}</span>
          </h3>
          <span className="m-l-35">{f.user_name}, {Cs.formatUpdateDate(f.created_at)}</span>
        </div>
        <div className="p-15">
          <div>
            <div className="success-txt m-b-10">
              {f.data.audio ?
                <div className="border-dashed-sm">
                  <AudioPlayer url={f.data.audio}/>
                </div>
                : null
              }
              {f.data.details ?
                <ParaRichTextView className="rich-text-f18 m-t-10 lblack border-dashed-sm" html={f.data.details}/>
                : null
              }
              {f.data.ref_image ?
                <div className="wk-center m-t-10 border-dashed-sm">
                  <Image image={f.data.ref_image} styleName="img-responsive"/>
                </div>
                : null
              }
              {f.data.planners_array?.[0]?.id ?
                <div className="wk-center m-t-10 border-dashed-sm">
                  <PlannerVideoPlayer plannerId={f.data?.planners_array[0]?.id} playInline={true}/>
                </div>
                : null
              }
            </div>
            <small className="text-lite-gray">{f?.record_id}</small>
            {updateAsRead ?
              <div className="m-t-10 pull-right">
                <FontAwesomeBtn 
                  isResponsive={false}
                  onSelect={()=>updateAsRead(f)}
                  css="bg-cancel m-r-5 f14" iconCss="fa fa-window-close" 
                  name="Do not show this message again">
                </FontAwesomeBtn>
              </div>
              :null
            }
          </div>
        </div>
      </div>
    </div>
  )

}

const NewFeatures = ({isOpen, toggleModal, showAll=false, isPopupMode=true, recordIds, isActive=true}) =>{
  const [features, setFeatures] = useState([])

  useEffect(()=>{
    if(showAll || !sessionStorage.getItem("ct_new_feature_closed")){
      getNewFeatures()
    }
  }, [])

  const getNewFeatures = async () =>{
    const {data, status} = await DigitalContentService.getNewFeatures({
      'record_ids':recordIds,
      'page':1,
      'per_page':1,
      'sort_column': 'updated_at',
      'sort_direction': 'desc',
      'is_active':isActive,
      'filter':{
        'is_not_viewed':!showAll
      }
    })
    if(status === 200){
      if(data.digital_contents.length>0){
        setFeatures(data.digital_contents)
      }
    }
  }

  const updateAsRead = async (item) =>{
    const {status} = await UserDataViewService.create({
      'is_viewed':true,
      'item_id':item.id,
      'item_type':'digital_content'
    })
    if(status === 201){
      const list = features.filter((f)=> f.id !== item.id)
      setFeatures([...list])
      if(list.length == 0)toggleModal();
    }
  }

  const closeModal = () =>{
    sessionStorage.setItem("ct_new_feature_closed", true)
    toggleModal()
  }

  if(isOpen && features.length > 0){
    if(isPopupMode){
      return <GenericModal component={FeaturesPopup} 
      updateAsRead={updateAsRead} features={features} 
      isOpen={isOpen} toggleModal={closeModal}/>
    }else{
      return <FeaturesPopup features={features}/>
    }
  }

  return null
}

export {NewFeatures};