import React, { Suspense, lazy } from 'react'
import PrivateRoute from '../../routes/PrivateRoute'
import PublicRoute from '../../routes/PublicRoute'
/*import TrainingPrograms from './List'
import TrainingProgramForm from './Form'
import TrainingSessions from './TrainingSessions/List'
import AssessmentList from "./Assessments/List/AssessmentList"
import AssessmentForm from "./Assessments/Form/AssessmentForm"
import StepAssessmentForm from "./Assessments/Form/StepAssessmentForm"
import QuestionForm from "./Assessments/Form/QuestionForm"
import AssessmentAnswers from "./Assessments/List/AssessmentAnswers"
import UserAssessments from './Assessments/List/UserAssessments'*/
import TraineeUserManager from './TraineeUserManager'
import TraineeContentList from './TraineeContentList'
const TrainingPrograms = lazy(() => import('./List'));
const TrainingProgramForm = lazy(() => import('./Form'));
const TrainingSections = lazy(() => import('./TrainingSections/List'));
const AssessmentList = lazy(() => import('./Assessments/List/AssessmentList'));
const AssessmentForm = lazy(() => import('./Assessments/Form/AssessmentForm'));
const StepAssessmentForm = lazy(() => import('./Assessments/Form/StepAssessmentForm'));
const QuestionForm = lazy(() => import('./Assessments/Form/QuestionForm'));
const AssessmentAnswers = lazy(() => import('./Assessments/List/AssessmentAnswers'));
const UserAssessments = lazy(() => import('./Assessments/List/UserAssessments'));
const QuestionList = lazy(() => import('./Assessments/List/QuestionList'));
const TrainingSchedules = lazy(() => import('./TrainingSchedules'));
const HCWAssessmentTabs = lazy(() => import('./Assessments/List/HCWAssessmentTabs'));
const HCWAssessmentTable = lazy(() => import('./Assessments/List/HCWAssessmentTable'));
const QuizInstructionPage = lazy(() => import('./QuizInstructionPage'));
const ReportsPopup = lazy(() => import('./Reports/ReportsPopup'));
const TestCaseForm = lazy(() => import('./TraineeTestCases/TestCaseForm'));
const TestCaseList = lazy(() => import('./TraineeTestCases/TestCaseList'));
const TraineeTestCaseReport = lazy(() => import('./TraineeTestCases/TraineeTestCaseReport'));
const SessionTable = lazy(() => import('./TrainingSession/SessionTable'));

const TrainingRouter = (props) => (
  <Suspense fallback={<div className="spinner"/>}>
    <PrivateRoute path="/list/training_programs" component={TrainingPrograms} searchString={props.searchString} exact/>
    <PrivateRoute path="/list/training/sections/:training_program_id" component={TrainingSections} searchString={props.searchString} exact/>
    <PrivateRoute path="/training_programs/create/:id?" component={TrainingProgramForm} exact/>
    <PrivateRoute path="/list/assessments/:training_program_id?" component={AssessmentList} exact/>
    <PrivateRoute path="/assessment/form/:id?" component={AssessmentForm} exact/>
    <PrivateRoute path="/step_assessment/form/:assessment_id/:id?" component={StepAssessmentForm} exact/>
    <PublicRoute path="/question/:assessment_id/:id?" component={QuestionForm} exact/>
    <PrivateRoute path="/answers/:assessment_id" component={AssessmentAnswers} searchString={props.searchString} exact/>
    <PrivateRoute path="/list/quiz" component={UserAssessments} exact/>
    <PrivateRoute path="/user/training_programs" component={TraineeUserManager} exact/>
    <PublicRoute path="/user/training_program/:training_program_id" component={TraineeContentList} exact/>
    <PrivateRoute path="/org/training_manager/:organization_id" component={TrainingPrograms} searchString={props.searchString} exact/>
    <PrivateRoute path="/assessment/list/questions" component={QuestionList} searchString={props.searchString}/>
    <PrivateRoute path="/training_programs/schedules/:organization_id" component={TrainingSchedules} />
    <PrivateRoute path="/preworkshop/quiz/report" component={HCWAssessmentTabs} searchString={props.searchString} exact/>
    <PrivateRoute path="/quiz/reports" component={HCWAssessmentTabs} searchString={props.searchString} exact/>
    <PublicRoute path="/quiz/info/:assessment_id/:session_id" component={QuizInstructionPage} exact/>
    <PublicRoute path="/assessment/report/:organization_id" component={ReportsPopup} exact/>
    <PublicRoute path={["/trainee/test/case/:organization_id", "/trainee/practical/test/:assessment_id/"]} component={TestCaseForm} exact/>
    <PrivateRoute path="/list/trainee/test/cases/:organization_id/:assessment_id" component={TraineeTestCaseReport} searchString={props.searchString} exact/>
    <PrivateRoute path="/list/test/cases/:organization_id/:assessment_id" component={TestCaseList} searchString={props.searchString} exact/>
    <PrivateRoute path="/list/training/sessions/:training_program_id" component={SessionTable} searchString={props.searchString} exact/>
  </Suspense>
)

export default TrainingRouter;