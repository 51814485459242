import { useState} from "react";
import Cs from "../../services/CommonService";
import AuthenticationService from "../../services/AuthService";
import {SideMenuRoute} from "../Routes/Routing";
import env from "../../env";
import {useDeviceInfo} from '../../hooks/useUserAgent'

const useAuth = (history, userDispatch, geoData) => {
	const {getDeviceInfo} = useDeviceInfo();

	let [user, setUser] = useState({
	    registration_mode: '',
	    email: '',
	    password: '',
	    isSubmitting: false,
	    errorMessage: null,
  	})

	const handleFormSubmit = async (e) => {
	    e && e.preventDefault();
	    const userAgent = Cs.getBrowserType();
	    setUser({
	      ...user,
	      isSubmitting: true,
	      errorMessage: null,
	    })
	    /*if (user.isChecked) {
	      localStorage.email = user.email
	      localStorage.password = user.password
	      localStorage.checkbox = true
	    }*/
	    const req = { 
	      'user': { 
	        'email': user.email, 
	        'password': user.password,
	        'phone_no': user.phone_no,
	        'uid': user.uid,
	        'registration_mode': user.registration_mode,
	        'geo_data': geoData.geo_data,
	        'user_agent': userAgent,
	        'token': user.token,
	        'platform': navigator.platform, 
	        'lrid': localStorage.cuedwell_uid,
	        'device': await getDeviceInfo()
	      } 
	    }
	    AuthenticationService.signin(req).then((res) => {
	      if ([201, 200].includes(res.status)) {  
	        userDispatch({
	          type: "LOGIN",
	          payload: res.data,
	        })
	        if(env.admin_emails.includes(res.data.user.email)){
	        	/*if(userAgent !== 'Safari')*/
	        	//PushNotification(res.data.user);
	        }
	      } else {
	      	if(user.registration_mode === 'uid'){
	      		history.push('/signin');
	      	}else{
		      	setUser({
		          ...user,
		          isSubmitting: false,
		          errorMessage: res.data.error || res.statusText,
		        });
	      	}
	      }
	    }).catch((error) => {
	      console.log('error',error);
	      setUser({ ...user, isSubmitting: false, errorMessage: 'Network Error' });
	    })
  	}

	const routeUser = (user) => {
	    if(user.is_active == false && user.in_active_reason == "default_password_not_reset"){
	      history.push("/password/update");
	    }
	    else if(user.current_member?.home_page?.sid){
	      //history.push(user.current_member?.home_page?.url)
	    	SideMenuRoute(history, user.current_member?.home_page, user);
	    }
	    else if(user.setting?.welcome_tour !== 'Completed'){
	      history.push('/journey/tour');
	    }
	    else{
	      history.push("/health/table");
	    }
	}

	return {
		user,
		setUser,
		handleFormSubmit,
		routeUser
	}

}

export default useAuth;