import env from '../env';
import axios from 'axios';

class DataSourceService {

  static async getDataSourceList(req) {
    const response = await axios.get(env.health_backend + 'health/data_source_lists', { params: req });
    return response;
  }
  
  static async createDataSource(req) {
    const u = env.health_backend + 'health/data_source_lists';
    const res = await axios.post(u, req);
    if (res.status === 201) {
      return res;
    }
  }
  
  static async updateDataSourceList(id, req) {
    const u = env.health_backend + 'health/data_source_list/' + id;
    const res = await axios.put(u, req);
    if (res.status === 204) {
      return res.data;
    }
  }
  
  static async searchDataSource(params) {
    const u = env.health_backend + 'health/data_source_lists/search';
    const res = await axios.post(u, params);
    if (res.status === 200) {
      return res.data;
    }
  }
  
  static async createDataSourceList(params) {
    const u = env.health_backend + 'health/data_source_lists';
    const res = await axios.post(u, params);
    if (res.status === 200) {
      return res.data;
    }
  }
  
  static async updateDataSourceListStatus(params) {
    const u = env.health_backend + 'health/data_source_lists/status';
    const res = await axios.post(u, params);
    if (res.status === 200) {
      return res;
    }
  }
  
  static async deleteDataSourceListStatus(req) {
    const u = env.health_backend + 'health/data_source_lists/status';
    const res = await axios.delete(u, { params: req });
    return res;
  }
  
  static async getDataSource(params) {
    const res = await axios.get(env.health_backend + 'health/data_sources', { params });
    if (params.page_locale) {
      return res.data;
    }
    return res.data.data_source_lists;
  }
  
  static async getPageLocale(params) {
    const res = await axios.get(env.health_backend + 'health/page_locales', { params });
    return res.data.page_locales;
  }

}

export default DataSourceService;