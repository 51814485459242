import env from "../env";
import axios from "axios";

class LocationService {
  static async get_countries(req) {
    return await axios.get(env.health_backend + "countries", {params: req});
  }

  static async get_state(req) {
    return await axios.get(env.health_backend + "states", {params: req});
  }

  static async get_district(req) {
    return await axios.get(env.health_backend + "cities", {params: req});
  }

  static async get_taluka(req) {
    return await axios.get(env.health_backend + "sub_districts", {params: req});
  }

  static async get_village(req) {
    return await axios.get(env.health_backend + "villages", {params: req});
  }

  static async getPublicHealthCenters(req) {
    return await axios.get(env.health_backend + "public_health_centers", {params: req});
  }

  static async reverseGeocode(req) {
    return await axios.get("https://nominatim.openstreetmap.org/reverse", {
      params: req,
      withCredentials: false,
    });
  }
}
export default LocationService;
