import { useEffect } from 'react';

let mainApp = document.getElementById('overlay');

const useStyle = (resourceUrl, isDifferentLocation) => {
  useEffect(() => {
    /* return new Promise((resolve, reject) => {*/
    let link = null;  
    const url = isDifferentLocation?`${resourceUrl}?v=6`:`/styles/${resourceUrl}.css?v=6`;
    if(document.head.querySelectorAll(`link[href="${url}"]`).length == 0){  
      link = document.createElement('link');
      link.setAttribute('rel', 'stylesheet');
      link.setAttribute('type', 'text/css');
      link.async = false;
      link.onload = function(){
        if(mainApp.className.indexOf('hidden') < 0)
          mainApp.classList.add('hidden')
      }
      link.onerror = function(){
        if(mainApp.className.indexOf('hidden') < 0)
          mainApp.classList.add('hidden')
      }
      link.setAttribute('href', url)
      document.head.appendChild(link)
      if(mainApp.className.indexOf('hidden') > -1){
          mainApp.className = mainApp.className.replace('hidden', '')
      }
      //mainApp.className += " hidden";
      //document.head.insertAdjacentHTML("beforeend", `<style type="text/css">body{display:none}</style>`)
      /*})*/
    }

    return () => {
      link && document.head.removeChild(link);
    }
  }, [resourceUrl]);
}

export default useStyle;