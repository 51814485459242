import BaseService from "../../../services/BaseService";
import env from '../../../env';
import axios from 'axios';
let url = env.health_backend + 'training/'

class AssessmentService extends BaseService {

  async getUserAssessments(req) {
    return axios.get(`${url}user/assessments`, { params: req });
  }

  async getFormFieldsAssessment(req) {
    return axios.get(`${url}form_fields/assessments`, { params: req });
  }

  async getEmailNotificationList(id, req) {
    return axios.get(`${url}email_notification/assessments/${id}`, { params: req });
  }

  async clone(req) {
    return axios.post(`${url}clone/assessments`, req);
  }

  async search(req) {
    return axios.post(`${url}search/assessments`, req);
  }

  async reports(req) {
    return axios.get(env.health_backend + 'training/assessment/reports', { params: req })
  }

  async getInstructions(req) {
    return axios.get(env.health_backend + 'training/assessment/instructions', { params: req })
  }

  async getTrainingReports(req) {
    return axios.get(env.health_backend + 'health/training_reports', { params: req })
  }

  async getFilters(req){
    return axios.get(env.health_backend+'training/assessment/filters', {params: req})
  }

  async coverageReport(req) {
    return axios.get(env.health_backend + 'training/assessment/coverage_report', { params: req })
  }

}

export default new AssessmentService('health_backend', 'training/assessments');