import { useEffect, useContext, useState } from 'react';
import { AuthContext } from "../contexts/Authentication/AuthStateProvider";
import importScript from './importScript';

const useIdbKeyval = (storeName) => {
  const { state:{isAuthenticated, user:currentUser} } = useContext(AuthContext);
  const [store, setStore] = useState()
  const [isStoreLoaded, setIsStoreLoaded] = useState(false)

  useEffect(() => {
    importScript('https://cdn.jsdelivr.net/npm/idb-keyval@3/dist/idb-keyval-iife.min.js', init, window.idbKeyval);
  }, [])

  const init = () =>{
    if(window.idbKeyval && storeName){
      setStore(new window.idbKeyval.Store(storeName, 'key-value'));
      setIsStoreLoaded(true)
    }
  }

  const set = (id, data) =>{
    window.idbKeyval.set(id, data, store);
  }

  const addToJourneyCacheList = async (journey) =>{
    const uniqueLists = await window.idbKeyval.get('journeyList', store) || []
    const idx = uniqueLists.findIndex((i) => i.record_id === journey.record_id)
    if(idx < 0){
      uniqueLists.push({
        'id':journey.id,
        'child_id':journey.child?.id,
        'record_id':journey.record_id, 
        'mother_name':journey.data?.mother_name, 
        'mobile_no':journey.data?.cell_number
      })
      set('journeyList', uniqueLists)
    }
  }

  const getByKey = async (key) =>{
    const response = await window.idbKeyval.get(key, store) || []
    return response
  }

  const getAllKeys = async () =>{
    const keys = await window.idbKeyval.keys(store);
    return keys;
  }

  return {
    isStoreLoaded,
    addToJourneyCacheList,
    getByKey,
    getAllKeys
  }
}

export {useIdbKeyval};