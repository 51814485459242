import env from '../../env';
import axios from 'axios';
import BaseService from '../../services/BaseService';
let url = `${env.health_backend}health/digital_contents`

class DigitalContentService extends BaseService {

  async getNewFeatures(req) {
    return await axios.get(env.health_backend + 'health/new_features', { params: req });
  }

  async getHelpScreens(req) {
    return await axios.post(`${env.health_backend}health/help_screens`, req);
  }
}

export default new DigitalContentService('health_backend', 'health/digital_contents');