import React from 'react';
import ReactTooltip from 'react-tooltip';
import { SpanRichTextView } from './RichTextView';

const CueTooltip = (props) => {
	return(
	  <ReactTooltip id={`tooltip_${props.id}`} place="bottom" type="info" effect='solid'>
        <SpanRichTextView html={props.description}/>
      </ReactTooltip>
	)
}

const CTTooltip = (props) => {
	return(
		<ReactTooltip place="bottom" overridePosition={ ({ left, top }, currentEvent, currentTarget, node) => {
			const d = document.documentElement;
			left = Math.min(d.clientWidth - node.clientWidth, left);
			top = Math.min(d.clientHeight - node.clientHeight, top);
			left = Math.max(0, left);
			top = Math.max(0, top);
			return { top, left }
		}}/>
	)
}

//positionCss = top, left, bottom, right
const CueTip = ({tooltip, positionCss='top', currentLocale, locale}) => tooltip?(
	<SpanRichTextView className={`tooltiptext ${positionCss}`} html={(locale?.[currentLocale] || tooltip)}/>
):null

export {CueTooltip, CTTooltip, CueTip};