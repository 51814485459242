import React, { Fragment, useState, useEffect } from "react";
import ReactDOM from "react-dom";
import useStyle from "../../hooks/useStyle";
import useEscape from "../../hooks/useEscape";
import { getLocale } from "../FormBuilder/FieldLabel";
import { usePageLocale } from '../../hooks/useLocale';
import DataSourceService from "../../services/DataSourceService";
import { useCurrentUserHook } from "../Authentication/useUserHook";

const DataSourceMenu = ({id, title, journeyProfileId, isOpen, toggleModal, onSelect, dataSourceId, datasourceList, dataSourceKey, activeDslId, isSelected}) => {
  
  useStyle("filter_dialog");
  useEscape(toggleModal);
  
  const {currentUser} = useCurrentUserHook()
  const { labelLocale:popupLocale } = usePageLocale(currentUser.current_locale, 'popup');

  const [list, setList] = useState(datasourceList);
  
  useEffect(() => {
    if(id) getList();
  }, [])

  let getList = () => {
    let req = {
      "data_source_params":{
        "data_source_id": [id],
      }
    }

    DataSourceService.getDataSource(req).then((res) => {
      setList(res[Object.keys(res)[0]].options);
    })
  }

  const checkIsSelected = (o) =>{
    if(isSelected){
      return isSelected(o);
    }else{
      return (activeDslId && activeDslId === o.pk_id);
    }
  }

  return ReactDOM.createPortal(
    <Fragment>
      <div className="ml-modal">
        <div className="ml-modal-content ml-card-4 ml-animate-zoom brd-10">
          <div className="flex justspacebetween modal-title">
            <h4 className="f18 text-white fw500 p12-10-15">{title || popupLocale(1)}</h4>
            <button className="btn-close" title="Close Modal" onClick={e=>toggleModal(e)}>
              ×
            </button>
          </div>

          {list && (
            <div className="p-5 scroll-x">
              <div className="popup-menu-grid">
                <DataSourceListItems locale={currentUser.current_locale} {...{list, activeDslId, dataSourceKey, onSelect, checkIsSelected}}/>
              </div>

              <small className="font-9 lgrey lh-16 p-5">{id || dataSourceId}</small>
            </div>
          )}
        </div>
      </div>
    </Fragment>, document.body
  )
}

const DataSourceListItems = ({locale, list, activeDslId, dataSourceKey, onSelect, checkIsSelected}) =>{
    if(dataSourceKey == 'data'){
      return(
        <>
          {list.map((o, k) =>(
            <div className="ver-icons" onClick={()=>onSelect(o.data)} key={k}>
              <span>
                <i className={`${o.data.icon || "fas fa-plus"}`} style={{ color: o.data.icon_color }}/>
              </span>
              <div className="popup-menu-title">{getLocale(o.data, locale)}</div>
              <i className="fas fa-chevron-right lgrey pull-right"/>
            </div>
          ))}
        </>    
      )
    }

    return(
      <>
        {list.map((o, k) =>(
          <div className={`ver-icons ${checkIsSelected(o) ? 'bg-lblue-1' : ''}`} onClick={()=>onSelect(o)} key={k}>
            <span>
              <i className={`${o.icon || "fas fa-plus"}`} style={{ color: o.icon_color }}/>
            </span>
            <div className="popup-menu-title">{getLocale(o, locale)}</div>
            <i className="fas fa-chevron-right lgrey pull-right"/>
          </div>
        ))}
      </>  
    )
}

export {DataSourceMenu, DataSourceListItems};