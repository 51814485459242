import React, { Fragment, useState, useEffect } from "react";
import ReactDOM from "react-dom";
import useStyle from "../../hooks/useStyle";
import useEscape from "../../hooks/useEscape";
import DataSourceMappingService from "../../services/DataSourceMappingService";
import PlannerService from "../HealthJourney/Health/Planners/PlannerService";
import PlannerStepService from "../HealthJourney/Health/Planners/PlannerStepService";
import CounselingService from "../HealthJourney/Counseling/CounselingService";

let timer = null
let searchKeyword = null
const DataSourceMapperModal = ({isOpen, toggleModal, field={}, dataSourceItem={}, type, formId, alert, formRule}) => {
  
  useStyle("filter_dialog")

  let [planners, setPlanners] = useState([])
  let [counselings, setCounselings] = useState([])
  let [selectedCounselings, setSelectedCounselings] = useState([])
  let [plannerStepList, setPlannerStepList] = useState([])
  let [activeOptionIdx, setActiveOptionIdx] = useState()

  useEscape(toggleModal)

  let title = (type == 'infant_journey.counseling')?'counseling':'planner';

  useEffect(() => {
    if(type == 'infant_journey.counseling'){
      if(formRule?.id && formRule?.counseling_count > 0){
        getCounselings(null, {'form_rule_id':formRule.id})
      }
    }else{
      getPlanners(null, true)  
    }

    return () =>{
      timer = null;
      searchKeyword = null;
    }
  }, [])

  let search = () =>{
    if(type == 'infant_journey.counseling'){
      getCounselings(searchKeyword)
    }else{
      getPlanners(searchKeyword)  
    }
  }

  const handleChange=(event)=>{
    clearTimeout(timer);
    searchKeyword = event.target.value
    timer = setTimeout(search, 1000);
  }

  let getPlannerSteps = (p, idx) => {
    if(activeOptionIdx == idx){
      setActiveOptionIdx(null)
      return
    }

    let req = {
      form_alert_id: alert.id,
      planner_id: p.id,
      fields:'mapping_id',
      data_source_list_id: dataSourceItem.pk_id,
      mapping_type:type
    }

    PlannerStepService.getAll(req).then((res) => {
      setPlannerStepList(res.data.planner_steps)
      setActiveOptionIdx(idx)
    })
  }

  let getCounselings = (search, filter) => {
    let req = {
      default_fields:'ignore',
      fields:['id', 'data_source', 'mapping_id', 'record_id', 'counseling_form_id'],
      form_alert_id: alert?.id,
      form_rule_id: formRule?.id,
      search: search,
      search_column:'data_source.stage,data_source.step',
      data_source_list_id: dataSourceItem.pk_id,
      mapping_type:type,
      filter: filter,
      per_page: 30
    }

    CounselingService.getAll(req).then((res) => {
      if(filter?.form_rule_id){
        setSelectedCounselings(res.data.counselings)
      }else{
        setCounselings(res.data.counselings)
      }  
    })
  }

  let getPlanners = (search, exists_in_mapper) => {
    let req = {
      form_alert_id: alert?.id,
      fields:'mapping_id',
      data_source_list_id:dataSourceItem.pk_id,
      mapping_type:type,
      search: search,
      step_search: search,
      exists_in_data_source_mapper: exists_in_mapper,
      per_page: 30,
      page:1
    }

    PlannerService.getAll(req).then((res) => {
      setPlanners(res.data.planners)
    })
  }

  let toggle = (d, item_type) =>{
    if(d.mapping_id){
      destroy(d)
    }else{
      create(d, item_type)
    }
  }

  let create = (p, item_type) =>{
    let req = {
      'mapping_id':p.id,
      'mapping_type':type,
      'form_template_id':formId,
      'form_alert_id':alert?.id,
      'form_rule_id':formRule?.id,
      'data_source_id':field.data_source_id,
      'data_source_list_id':dataSourceItem.pk_id,
      'data_source_list_value':dataSourceItem.value,
    }

    if(item_type === 'step_planner'){
      req.mapping_id = p.planner_id
      req.step_mapping_id = p.id
    }
      
    DataSourceMappingService.create(req).then((res)=>{
      if(res.status == 201){
        p.mapping_id = res.data.mapping.id
        if(type === 'infant_journey.counseling'){
          setSelectedCounselings([p, ...selectedCounselings])
          setCounselings(counselings.filter(c => c.id !== p.id))
        }
        setPlannerStepList([...plannerStepList])
      }
    })
  }

  let destroy = (d) =>{
    DataSourceMappingService.delete(d.mapping_id).then((res)=>{
      if(res.status == 204){
        d.mapping_id = null
        setSelectedCounselings(selectedCounselings.filter(c => c.mapping_id !== d.mapping_id))
        setPlannerStepList([...plannerStepList])
      }
    })  
  }

  const PlannerStepList = ({}) => plannerStepList.map((o, k) =>
    <span className="checkbox-container" key={k} onClick={e => toggle(o, 'step_planner')}>
      <span>{o.data.start_time} - {o.data.description}</span>
      <input type="checkbox" checked={o.mapping_id} readOnly/>
      <span className="checkbox-checkmark"></span>
    </span>
  )

  let Planners = () => planners.map((d, i) =>{
    let ds = d.data_source
    return(
      <Fragment key={i}>
        <div className="filter-label" onClick={e=>getPlannerSteps(d, i)}>  
          <div className="d-flex">
            <input type="checkbox" checked={d.mapping_id} onChange={e=>toggle(d)} required />
            <label className="m-l-5">{d.data.name} ({ds?.language?.label})</label>
          </div>
        </div>
        {activeOptionIdx == i && <PlannerStepList/>}
      </Fragment>
    )
  })

  const Counselings = ({counselings}) => counselings.map((o, k) =>
    <span className="checkbox-container" key={k} onClick={e => toggle(o)}>
      <span>
        {o.data_source?.stage?.label} - {o.data_source?.language?.label}
        <br/>
        {o.data_source?.step?.label} - {o.record_id} - {o.counseling_form_id}
      </span>
      <input type="checkbox" checked={o.mapping_id} readOnly/>
      <span className="checkbox-checkmark"></span>
    </span>
  )

  return ReactDOM.createPortal(
    <Fragment>
      <div className="ml-modal">
        <div className="ml-modal-content ml-card-4 ml-animate-zoom brd-10">
          <div className="flex justspacebetween modal-title">
            <h4 className="f18 text-white fw500 p12-10-15">Please select {title}</h4>
            <button className="btn-close"
              title="Close Modal" onClick={e=>toggleModal(e)}>×
            </button>
          </div>
          
          <div className="p-5 scroll-x">
            <div className="input-group m-b-20">
              <input type="text" onChange={e=>handleChange(e)} placeholder="Search" 
              className="form-control input-sm"/>
              <span className="input-group-btn">
                <p type="button" className="bg-highlight input-sm">
                  <i className="fa fa-search white"></i>
                </p>
              </span>
            </div>  
            {/*<Planners/>*/} 
            <Counselings counselings={selectedCounselings}/>  
            <Counselings counselings={counselings}/> 
          </div>
          
        </div>
      </div>
    </Fragment>, document.body
  )
}

export default DataSourceMapperModal;