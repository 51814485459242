import axios from 'axios';

//axios.defaults.baseURL = 'https://jsonplaceholder.typicode.com';
//axios.defaults.headers.common['Authorization'] = 'AUTH TOKEN';
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.withCredentials = true;
//axios.defaults.withCredentials = 'include';

axios.interceptors.request.use(request => {
    //console.log(request);
    // Edit request config
    return request;
}, error => {
    //console.log(error);
    networkError(error);
    return Promise.reject(error);
});

axios.interceptors.response.use(response => {
    //console.log(response);
    // Edit response config
    return response;
}, error => {
    //console.log(error);
    const res = networkError(error);
    /*return error.response;*/
    return res ? res : Promise.reject(error);
});

const networkError = (e) => {
    if(navigator.onLine && e.response){
      if(e.config.url.indexOf('nominatim.openstreetmap.org') > -1){
        //third party api issue
      }else if(e.response.data?.error){
        alert(e.response.data?.error)
      }
    }else if(!navigator.onLine && window.location.pathname !== '/offline'){ 
      if(e.config.headers["client-cache"]){
        return {"status": 202}
      }else{
        if (window.confirm("Network Issue. Press OK to use offline mode.") == true) {
            document.getElementById('offline').click()
        }
      }
    }
}