const exitAlertMsg = `If you click "OK" this form will close and you will lose the data you entered.

If you click "Cancel" this form will remain open and you can submit by clicking "Submit" or "Save draft".`

const Portal = {  
  cancer_journey:[3675, 3676],
  infant_journey:[3673, 3674, 3692],
}

const Locales = [
  {label:"English (default)", sid:"english", value:1},
  {label:'Gujarati (India)', sid:'gujarati', iso_code:'gu', value:4},
  {label:'Tamil (India)', sid:'tamil', iso_code:'ta', value:5},
  {label:'Marathi (India)', sid:'marathi', iso_code:'mr', value:3},
  {label:'Hindi (India)', sid:'hindi', iso_code:'hi', value:2},
  {label:'Kannada (India)', sid:'kannada', iso_code:'kn', value:11},
  {label:'Malayalam (India)', sid:'malayalam', iso_code:'ms', value:7},
  {label:'Telugu (India)', sid:'telugu', iso_code:'te', value:6},
]

const Permissions = [
  {label: 'Invite User', sid: 'invite_user'},
  {label: 'Roles', sid: 'roles'},
  {label: 'Reports', sid:'reports'},
  {label: 'Manage Planners', sid:'manage_planner'},
  {label: 'Manage Counseling', sid:'manage_counseling'}
]

const PermissionsObj = {
  invite_user: {label: 'Invite User', sid: 'invite_user'},
  roles: {label: 'Roles', sid: 'roles'},
  reports: {label: 'Reports', sid:'reports'},
  manage_planners: {label: 'Manage Planners', sid:'manage_planner'},
  manage_counseling: {label: 'Manage Counseling', sid:'manage_counseling'}
}

const AdminTypes = [
  {'label':'Admin', 'key':'role_admin'},
  {'label':'Deputy Admin', 'key':'role_dupty_admin'}
]

const FormAlertTypes = [
  {label: 'Single prompt based on group selection of values', sid: 'min_selection'},
  {label: 'Individual prompt based on individual values', sid: 'data_source_list'},
]

const DataSourceSelectionType = [
  {label: 'Selected', sid: 'selected'},
  {label: 'UnSelected', sid: 'un_selected'},
]

const ActiveStatusList = [
  {label:'ALL', value:null},
  {label:'Active', value:'true'},
  {label:'InActive', value:'false'},
]

/*const JourneyStatusList = [
  {label:'Active', value:'active'},
  {label:'Inactive', value:'inactive'},
  {label:'Closed', value:'closed'},
]*/

const DefaultFilter = {label:'ALL', value:null}

const setDefaultLabel = (options_obj, options_list) =>{
  if(options_obj){
    options_obj[0] = DefaultFilter
  }else if(options_list){
    options_list.unshift(DefaultFilter)
  }
}

const PreScheduleEventsDayList = []

const setEventsDayList = (day_diff, last_day=365) =>{
  for (let i = 1; i <= last_day; i = i+day_diff) {
    PreScheduleEventsDayList.push({label:`Day ${i} - Day ${i+day_diff}`, start:i, end:i+day_diff})
  }
}
setEventsDayList(30);

const OrgRoleConfirmOptions = [
  {label: 'Yes', sid: 'roles_created', color:'bg-submit'},
  {label: 'No', sid: 'roles_not_created', color:'bg-cancel'},
]

const DataSourcePermissionKey = {
  3530:'infant_portal',
  3673:'infant_ppt',
  3674:'infant_process',
  4698:'case_share',
  4710:'case_add_on',
  3737:'menu',
  3970:'form',
  4727:'schedule_menu',
  3933:'slide_menu',
  4605:'training'
  /*amj_ppt
  amj_process*/
}

const InfantGrowthZscoreLabel = {
  SUW:{label:'Severely Underweight', backgroundColor:'rgb(245, 77, 89)'},
  MUW:{label:'Moderately Underweight', backgroundColor:'black'},
  SS:{label:'Severely Stunted ', backgroundColor:'rgb(245, 77, 89)'},
  MS:{label:'Moderately Stunted', backgroundColor:'black'},
  SAM:{label:'Severely Acute Malnourished', backgroundColor:'rgb(245, 77, 89)'},
  MAM:{label:'Moderately Acute Malnourished', backgroundColor:'black'},
}

const DateRangeList = [
  {label:'1W', value:'1W'},
  {label:'2W', value:'2W'},
  {label:'4W', value:'4W'},
  {label:'13W', value:'13W'},
  {label:'6M', value:'6M'},
  {label:'1Y', value:'1Y'},
  {label:'3Y', value:'3Y'},
  {label:'5Y', value:'5Y'},
]

const DataSources = {  
  OrganizationJourneyMenu:3419,
  IndividualJourneyMenu:3838,
  sideMenuDsId:3737,
  headerDsId:3932,
  digitalContentMenu:3657,
  confirmMenuDsId:3988,
  sideMenuTopNav:3997
}

let WHOMetricsList = [
  { label: "Weight-for-age", sid: "weight_age", value: 1 },
  { label: "Length-for-age", sid: "length_age", value: 0 },
  { label: "Weight-for-length", sid: "length_weight", value: 2 },
  { label: "Head circumference-for-age", sid: "head_age", value: 3 },
]

let AgeDataFilterList = [
  { label: "Age Filter" },
  { label: "1W", value: 1, type:'week' },
  { label: "2W", value: 2, type:'week' },
  { label: "4W", value: 4, type:'week' },
  { label: "13W", value: 13, type:'week' },
  { label: "6M", value: 6, type:'month' },
  { label: "1Y", value: 1, type:'year' },
  { label: "3Y", value: 3, type:'year' },
  { label: "5Y", value: 5, type:'year' },
]

let WHOGraphList = [
    {
      tab:'Weight-for-age',
      graphs:[
        {
          id:'1',
          label: "Weight-for-age",
          who_key: "WhoWeightVsAgePercentiles",
          data_key: "WeightVsAge",
          x_axis_label: "Months",
          y_axis_label: "Weight (Kgs)",
          y_axis_min:2,
        },
        {
          id:'2',
          label: "Weight-for-age (Z score)",
          who_key: "WhoWeightVsAgePercentiles",
          who_zscore_key: "WhoWeightVsAgeZscores",
          data_key: "WeightVsAge",
          zscore_data_key: "WeightVsAgeZscore",
          x_axis_label: "Months",
          y_axis_label: "Weight (Kgs)",
          is_zscore_graph:true,
          y_axis_min:1,
          ytick_amount:8
        },
      ]
    },
    {
      tab:'Length-for-age',
      graphs:[
        {
          id:'4',
          label: "Length-for-age",
          who_key: "WhoLengthVsAgePercentiles",
          data_key: "LengthVsAge",
          x_axis_label: "Months",
          y_axis_label: "Height (cms)",
        },
        {
          id:'5',
          label: "Length-for-age (Z score)",
          who_key: "WhoLengthVsAgePercentiles",
          who_zscore_key: "WhoLengthVsAgeZscores",
          data_key: "LengthVsAge",
          zscore_data_key: "LengthVsAgeZscore",
          x_axis_label: "Months",
          y_axis_label: "Height (cms)",
          is_zscore_graph:true
        },
      ]
    },
    {
      tab: "Weight-for-length",  
      graphs:[
        {
          id:'7',
          label: "Weight-for-length",
          who_key: "WhoWeightVsHeightPercentiles",
          data_key: "LengthVsWeight",
          x_axis_label: "Height (cms)",
          y_axis_label: "Weight (Kgs)",
          y_axis_min:1,
          y_axis_max: 28,
          ytick_amount:12,
        },
        {
          id:'8',
          label: "Weight-for-length (Z score)",
          who_key: "WhoWeightVsHeightPercentiles",
          who_zscore_key: "WhoWeightVsHeightZscores",
          data_key: "LengthVsWeight",
          zscore_data_key: "LengthVsWeightZscore",
          x_axis_label: "Height (cms)",
          y_axis_label: "Weight (Kgs)",
          y_axis_min:1,
          y_axis_max: 28,
          ytick_amount:12,
          is_zscore_graph:true
        },
      ]
    }
]

const GraphMetricsList = [
   {label:'Mothers', sid:'health_metrics'},
   {label:'Infants', sid:'infant_metrics'},
   {label:'Growth metrics', sid:'who_median'},
   /*{label:'Service delivery', sid:'who_median'},*/
   {label:'Resource management', sid:'user_report'},
   {label:'By Geography', sid:'geography'},
   {label:'By Notification', sid:'notification'}
]

const PresetDateRange = [
  {label:'Last 30 days', sid:'last_30_days'},
  {label:'Last Calendar Month', sid:'last_month'},
  {label:'Last 90 days', sid:'last_90_days'},
]

const MaternalPregnancyFilter = [
  {
    label:'Number of new MCJ registrations', sid:'new_mcj', data_key:'maternal_pregnancy', chart:'bar', 
    chartOption:{
      title: {
        text: 'Number of new MCJ registrations'
      },
      xAxis:{name:'States', type:'category'}, 
      yAxis:{name:'No.of cases',type:'value'},
      dimensions:['x', 'y'],
      formatter: (p) => `${p.data.y} cases in ${p.data.x}`
    } 
  },
  {
    label:'MCJ registrations by Medical Facility', 
    sid:'cum_case_count', 
    data_key:'medical_facility', 
    chart:'custom'
  },
  {
    'label':'Zscore Statics Table', 
    'sid':'zscore_statics', 
    'data_key':'zscore_statics', 
    'chart':'table',
    'chartOption':{
      'title': {
        'text': 'Zscore Statics Table'
      }
    }
  },
  {
    'label':'Stage of pregnancy', 
    'sid':'pregnancy_stage', 
    'data_key':'maternal_pregnancy', 
    'chart':'pie',
    'chartOption':{
      'title': {
        'text': 'Stage of pregnancy at Registration',
        'sub_text': 'Cases',
        'show_count': true,
        'description': 'This pie chart displays the distribution of cases at registration according to the maternity stage.'
      }
    }
  },
  {
    'label':'Education Status', 
    'sid':'education', 
    'data_key':'maternal_pregnancy', 
    'chart':'pie',
    'chartOption':{
      'title': {
        'text': 'Education Status of mother',
        'sub_text':'cases',
        'show_count': true,
        'description': 'This pie chart displays the total count of cases for different education attainment levels of the mother. \n The chart indicates that the pilot project involved majority cases with mothers having less than a high school education, \n which makes the visually-driven training and counseling more relevant and helpful.'
      }
    }
  },
  {
    'label':'Age of mother', 
    'sid':'mother_age', 
    'data_key':'maternal_pregnancy', 
    'chart':'bar',
    'chartOption':{
      'title': {
        'text': 'Age of mother when registering case',
        'description':'This chart summarizes the number of mother’s registered in the HST pilot project by age.'
      },
      'xAxis':{'name':'Age', 'type':'category'}, 
      'yAxis':{'name':'No.of cases', 'type':'value'},
      'dimensions':['x', 'y'],
      'formatter': (p) => `${p.data.y} cases in Age ${p.data.x}`
    }
  },
  {
    label:'Mother Weight', 
    sid:'mother_weight', 
    data_key:'maternal_pregnancy', 
    chart:'scatter_bar_histogram',
    chartOption:{
      title: {
        text: 'Weight(Kgs) of Mother at Registration',
        sub_text:'Cases',
        'show_count': true,
        'description': 'This chart shows the distribution of mother’s weight at the time when a case was registered.'
      },
      formatter: (p)=> (p.componentType === 'markPoint') ? p.data.value : `${p.data.y} cases - ${p.data.x} kgs`,
      /*labelFormatter: (p)=>`${p.data.x} kgs`,*/
      xAxis:{name:'Weight (kg)', type:'value'}, 
      yAxis:{name:'Cases', type:'value'},
      barWidth: '99.3%',
      /*encode: { x: 1, y: 0},*/
    }
  },
  {
    label:'Mother Height', 
    sid:'mother_height', 
    data_key:'maternal_pregnancy', 
    chart:'scatter_bar_histogram',
    chartOption:{
      title: {
        text: 'Height(in cms) of Mother at Registration',
        sub_text:'Cases',
        'show_count': true
      },
      formatter:(p)=>(p.componentType === 'markPoint') ? p.data.value : `${p.data.x} cm - ${p.data.y} cases`,
      /*labelFormatter: (p)=>`${p.data.x} cm`,*/
      xAxis:{name:'Height (cm)', type:'value'}, 
      yAxis:{name:'Cases', type:'value'}
    }
  },
  {
    label:'Number of new births', 
    sid:'new_birth_count', 
    data_key:'child_birth', 
    chart:'pie',
    chartOption:{
      title: {
        text: 'Number of new births'
      }
    }
  },
  {
    label:'Location of delivery', 
    sid:'delivery_location', 
    data_key:'child_birth', 
    chart:'pie',
    chartOption:{
      title: {
        text: 'Location of delivery of baby',
        sub_text: 'cases',
        'show_count': true,
        'description': 'This pie chart displays the location where the baby was delivered. The most common location were private hospitals.'
      }
    }
  },
  {
    label:'Type of delivery', 
    sid:'delivery_type', 
    data_key:'child_birth', 
    chart:'pie',
    chartOption:{
      title: {
        text: 'Type of delivery',
        sub_text: 'births',
        'show_count': true,
        'description':'This pie chart displays the type of child delivery recorded for the cases.'
      }
    }
  },
  {
    label:'Completed weeks of pregnancy at delivery', 
    sid:'gestational_week', 
    data_key:'child_birth', 
    chart:'bar',
    chartOption:{
      title: {
        text: 'Completed weeks of pregnancy at delivery',
        'description': 'This distribution chart summarizes the gestational age (in number of weeks) for each recorded birth among registered mothers.'
      },
      xAxis:{name:'Weeks', type:'value'}, 
      yAxis:{name:'No.of cases',type:'value'},
      dimensions:['x', 'y'],
      formatter: (p) => `${p.data.y} cases in ${p.data.x} weeks`
    }
  },
  {
    label:'Gender of child', 
    sid:'gender', 
    data_key:'child_birth', 
    chart:'pie',
    chartOption:{
      title: {
        text: 'Gender of child'
      }
    }
  },
  {
    label:'Distribution of baby weight(kgs) at birth', 
    sid:'birth_weight', 
    data_key:'child_birth', 
    chart:'scatter_bar_histogram',
    chartOption:{
      title: {
        text: 'Distribution of baby weight at birth',
        sub_text:'babies',
        'show_count': true,
        'description': 'This chart displays the weight of the baby, as reported at birth. The median age is shown in the legend box at the bottom. The chart does not separate out the data based on the gender reported for the child. The chart informs that the median weight of the babies for whom this data was reported in the pilot project is below the median weight for baby (boys or girls) according to the WHO reference.'
      },
      formatter:(p)=>(p.componentType === 'markPoint') ? p.data.value : `${p.data.x} kgs - ${p.data.y} cases`,
      labelFormatter: (p)=>`${p.data.name} kg`,
      xAxis:{name:'Weight (kg)', type:'value'}, 
      yAxis:{name:'Cases', type:'value'}
    }
  },
  {
    label:'Distribution of baby length(cms) at birth', 
    sid:'birth_height', 
    data_key:'child_birth', 
    chart:'scatter_bar_histogram',
    chartOption:{
      title: {
        text: 'Distribution of baby length at birth',
        sub_text:'babies',
        'show_count': true,
        'description':'This displays the length of the baby, as reported at birth. The median age is shown in the legend box at the bottom. The chart does not separate out the data based on the gender reported for the child. The chart informs that the median length of the babies for whom this data was reported in the pilot project is below the median length for baby (boys or girls) according to the WHO reference.'
      },
      formatter:(p)=>(p.componentType === 'markPoint') ? p.data.value : `${p.data.x} cm - ${p.data.y} cases`,
      labelFormatter: (p)=>`${p.data.x} cm`,
      xAxis:{name:'Height (cm)', type:'value'}, 
      yAxis:{name:'Cases', type:'value'}
    }
  },
  {
    label:'Key reason for not recording details', 
    sid:'reason_no_measure', 
    data_key:'growth_measurement', 
    chart:'pie',
    chartOption:{
      title: {
        text: 'Key reason for not recording details',
        'description': 'This pie chart displays the illness reported by HCW when taking growth measurements. In a full project, reporting of reasons for not recording growth details can be used to set up follow up actions (e.g., notifying supervisor, checking whether the same reason is reported on the next visit) and to evaluate their impact on the case.'
      }
    }
  },
  {
    label:'Location and stage when measurement taken', 
    sid:'measurement_location', 
    data_key:'growth_measurement', 
    chart:'pie',
    chartOption:{
      title: {
        text: 'Location and stage when measurement taken',
        sub_text:'Submission',
        'show_count': true,
        'description': 'This pie chart displays the location where the growth measurements were taken by HCW.  Nearly 25% of the measurements were taken in an “Other” location, which should be understood better in a full project follow up.'
      }
    }
  },
  {
    label:'Babys weight (in kgs)', 
    sid:'baby_weight', 
    data_key:'growth_measurement', 
    chart:'scatter',
    chartOption:{
      title: {
        text: 'Babys weight (in kgs)'
      },
      formatter:(p)=>(p.componentType === 'markPoint') ? p.data.value : `${p.data.x} - ${p.data.y} entries`,
      xAxis:{name:'Weight (kg)', type:'value'}, 
      yAxis:{name:'Cases', type:'value'}
    }
  },
  {
    label:'Length of baby (in centimeters)', 
    sid:'baby_height', 
    data_key:'growth_measurement', 
    chart:'scatter',
    chartOption:{
      title: {
        text: 'Length of baby (in centimeters)'
      },
      formatter:(p)=>(p.componentType === 'markPoint') ? p.data.value : `${p.data.x} - ${p.data.y} entries`,
      xAxis:{name:'Length (cm)', type:'value'}, 
      yAxis:{name:'Cases', type:'value'}
    }
  },
  {
    label:'Is the child suffering from any illness', 
    sid:'illness_list', 
    data_key:'growth_measurement', 
    chart:'pie',
    chartOption:{
      title: {
        text: 'Illness reported for child',
        sub_text: 'Cases',
        'show_count': true,
        'description':'This pie chart displays the illness reported by HCW when taking growth measurements. In a full project, reporting of illness can be used to set up follow up actions (e.g., notifying supervisor, checking whether the illness is reported on the next visit) and to evaluate their impact on growth measures.'
      }
    }
  },
  {
    label:'First Pregnancy', 
    sid:'first_pregnancy', 
    data_key:'child_birth', 
    chart:'pie',
    chartOption:{
      title: {
        text: 'Births involving first pregnancy',
        sub_text: 'births',
        'show_count': true,
        'description':'This pie chart displays the total count of cases involving a mother in her first pregnancy or not. The chart indicates that the pilot project involved majority cases with first-time mothers, which makes the training and counseling more relevant and helpful.'
      }
    }
  },
  {
    label:'Protein Intake', 
    sid:'protein_intake', 
    data_key:'growth_measurement', 
    chart:'scatter',
    chartOption:{
      title: {
        text: 'Protein Intake'
      },
      formatter:(p)=>(p.componentType === 'markPoint') ? p.data.value : `${p.data.x} grams - ${p.data.y} entries`,
      xAxis:{name:'Protein Intake (gram)', type:'value'}, 
      yAxis:{name:'Cases', type:'value'}
    }
  },
  {
    label:'Hemoglobin Value', 
    sid:'hemoglobin', 
    data_key:'growth_measurement', 
    chart:'pie',
    chartOption:{
      title: {
        text: 'Hemoglobin Value'
      }
    }
  },
  {
    label:'Mobile', sid:'phone_no_count', 
    data_key:'maternal_pregnancy', 
    chart:'pie',
    chartOption:{
      title: {
        text: 'Mobile'
      }
    }
  },
  {
    label:'Separate Feed Room', 
    sid:'separate_feed_room', 
    data_key:'growth_measurement', 
    chart:'pie',
    chartOption:{
      title: {
        text: 'Separate Feed Room'
      }
    }
  },
  {
    label:'Delay in submitting Registration of new cases',
    sid:'delayed_case_submission', 
    data_key:'maternal_pregnancy', 
    chart:'line', 
    chartOption:{
      title: {
        text: 'Delay in submitting Registration of new cases'
      },
      xAxis:{type: 'category', boundaryGap: false, data: []}, 
      yAxis:{type: 'value', boundaryGap: [0, '100%']},
      dataZoom: [
        {
          type: 'inside',
          start: 0,
          end: 10
        },
        {
          start: 0,
          end: 10
        }
      ]
    } 
  },
  {
    label:'Distribution chart for Weight of Mother',
    sid:'pregnancy_stage_age_stack', 
    data_key:'maternal_pregnancy', 
    chart:'bar-y-category-stack', 
    chartOption:{
      title: {
        text: 'Distribution chart for Weight of Mother'
      },
      xAxis: {type: 'value'},
      yAxis: {
        type: 'category',
        data: []
      }
    } 
  }
]

const LeaderboardCharts = {
  taluka:[
    {label:'Top 10 list of Talukas with most MCJs registered', sid:'most_registered', data_key:'talukas', chart:'bar'},
    {label:'Bottom 10 list of Talukas with most MCJs registered', sid:'least_registered', data_key:'talukas', chart:'bar'},
    {label:'Top 10 list of Talukas with most weights under WHO median', sid:'weight_below_median', data_key:'talukas', chart:'bar'},
    {label:'Top 10 list of Talukas with most weights above WHO median', sid:'weight_above_median', data_key:'talukas', chart:'bar'},
    {label:'Top 10 list of Talukas with most heights under WHO median', sid:'height_below_median', data_key:'talukas', chart:'bar'},
    {label:'Top 10 list of Talukas with most heights above WHO median', sid:'height_above_median', data_key:'talukas', chart:'bar'},
  ],
  village:[
    {label:'Top 10 list of Villages with most MCJs registered ', sid:'most_registered', data_key:'villages', chart:'bar'},
    {label:'Bottom 10 list of Villages with most MCJs registered', sid:'least_registered', data_key:'villages', chart:'bar'},
    {label:'Top 10 list of Villages with most weights under WHO median', sid:'weight_below_median', data_key:'villages', chart:'bar'},
    {label:'Top 10 list of Villages with most weights above WHO median', sid:'weight_above_median', data_key:'villages', chart:'bar'},
    {label:'Top 10 list of Villages with most heights under WHO median', sid:'height_below_median', data_key:'villages', chart:'bar'},
    {label:'Top 10 list of Villages with most heights above WHO median', sid:'height_above_median', data_key:'villages', chart:'bar'},
  ],
  medical_facility:[ 
    {label:'Top 10 list of Facilities with most MCJs registered', sid:'most_registered', data_key:'facilities', chart:'bar'},
    {label:'Bottom 10 list of Facilities with most MCJs registered', sid:'least_registered', data_key:'facilities', chart:'bar'},
    {label:'Top 10 list of Facilities with most weights under WHO median', sid:'weight_below_median', data_key:'facilities', chart:'bar'},
    {label:'Top 10 list of Facilities with most weights above WHO median', sid:'weight_above_median', data_key:'facilities', chart:'bar'},
    {label:'Top 10 list of Facilities with most heights under WHO median', sid:'height_below_median', data_key:'facilities', chart:'bar'},
    {label:'Top 10 list of Facilities with most heights above WHO median', sid:'height_above_median', data_key:'facilities', chart:'bar'},
  ]  
}

const OrgMemberCharts = [
  {
    'label':'Cases Created', 
    'sid':'journey_count', 
    'type':'bar', 
    'chart':{
      'title': {
        'text': 'Number of cases registered by each unique HCW',
        'sub_text':'Cases',
        'show_count': true,
        'description':'This bar graph shows the distribution of the total number of HCWs that registered the specified numbers of cases. For reference, the IITB-HST project required that each HCW register 10 cases.'
      },
      'formatter': (p) => `${p.data.value} users with ${p.data.name} cases`,
      'xAxis':{name:'No.of cases created by users', type:'value'}, 
      'yAxis':{name:'No.of users',type:'value'}
    }
  },
  {
    'label':'Activities By Type', 
    'sid':'mcj_org_activity_count', 
    'type':'pie',
    'chart':{
      'title': {
        'text': 'Data submission by HCWs',
        'sub_text': 'Submission',
        'show_count': true,
        'description': 'This pie chart displays the number of data submissions made by HCWs across all cases in the pilot project. The chart indicates that most workers failed to gather and submit data on periodic ANC checks, household demographic information, and post-natal ward care information. Based on this learning from the pilot project, the case forms are streamlined for regular project by (A) eliminating these forms and (B) incorporating essential fields from these forms into the case and child registration forms. This will help gather essential data, while reducing perceived work items.'
      },
      formatter: (p) => `${p.data.name}-${p.data.value}`
    }
  },
  {
    'label':'Assess CF', 
    'sid':'mcj_access_cf', 
    'type':'treemap',
    'chart':{
      'title': {
        'text': 'Type of food given to baby',
        'sub_text': 'Food groups given to baby, as reported in Complementary Feeding form',
        'show_count':false,
        'description':'This treemap chart shows the most frequently reported type of food category for the complementary feeding stage. In a full project, this data collection should be streamlined and counseling should be provided for better/diverse nutritional intake.'
      },
      formatter: (p) => `${p.data.name}-${p.data.value}`,
      labelFormatter: (p)=>{
        return `${p.data.name} (${p.data.value})`
      },
      yAxis:{name:'Cases', type:'value'}, 
      xAxis:{name:'Weight (kg)', type:'value'}
    }
  },
  {
    'label':'Updates Made', 'sid':'journey_update_count', type:'scatter_bar_histogram', 
    chart:{
      title: {
        text: 'Updates Made',
        'description': 'This correlation histogram chart shows that most updates were made by a relatively smaller number of HCWs. In a full project, action should be taken by supervisors and mentors to ensure that HCWs register more cases and do the necessary follow up visits/submissions. This is important to make sure that more mother-child dyads receive the benefits of the IITB-HST counseling protocols.'
      },
      xAxis:{name:'No.of updates submitted by user', type:'category'}, 
      yAxis:{name:'Nof.of users',type:'value'},
      formatter: (p) => `${p.data.value} users with ${p.data.name} cases guided`
    }
  },
  {
    'label':'Missed Schedules', 'sid':'missed_schedule_count', type:'pie',
    chart:{
      title: {
        text: 'Missed Schedules'
      },
      formatter: (p) => `${p.data.value} users with ${p.data.name} missed schedule`
    }
  },
  {
    'label':'Active User By Day', 'sid':'active_users', type:'pie',
    chart:{
      title: {
        text: 'Active User By Day'
      },
      formatter: (p) => `${p.data.value} users active on Day ${p.data.name}`
    }
  },
  //{'label':'Locations', 'sid':'location_count', formatter: (p) => `${p.data.value} users in ${p.data.name} location`},
  //{'label':'# Login', 'sid':'login_count', formatter: (p) => `${p.data.value} users with ${p.data.name} login`},
  {
    'label':'# Cases Shared', 'sid':'shared_journey_count', type:'pie',
    chart:{
      title: {
        text: '# Cases Shared'
      },
      formatter: (p) => `${p.data.value} users with ${p.data.name} shared cases`, 
    }
  },
  {
    'label':"Geo Location", 'sid':'geo_location'
  },{
    'label':'Number of users engaged in activity', 
    'sid':'funnel_data_chart', 
    'type':'funnel',
    'chart':{
      'title': {
        'text': 'Number of users engaged in activity',
        'sub_text': 'Attrition in user activity at each stage in the maternal child journey',
        'description': 'This funnel chart shows the drop off in engagement as HCWs went from initial training to submitting forms such as the Complementary Feeding forms. In a full project, follow up actions should be implemented to reduce the drop off for the various data intake forms.'
      },
      formatter: (p) => `${p.data.name}-${p.data.count}` 
    }
  },{
    'label':'Org Member Ranking', 
    'sid':'org_member_ranking', 
    'type':'table'
  }/*{
    'label':'Sankey', 'sid':'sankey_levels', type:'sankey_levels',
    chart:{
      title: {
        text: 'Sankey'
      },
      formatter: (p) => `${p.data.name}-${p.data.count}`, 
    }
  }*/
]

/*const InfantActivityFilter = [
  {label: 'Show previous week', per_page:15, date_type:'past_week'},
  {label: 'Show next week', per_page:15, date_type:'next_week'},
  {label: 'Show all past details', per_page:15, date_type:'all_past_details'},
  {label: 'Show all upcoming details', per_page:15, date_type:'all_future_details'}
]*/

const ApexResponsive = {
  pieChart:[
          {
            breakpoint: 1000,
            options: {
              chart: {
                width: 475,
              }
            }
          },
          {
            breakpoint: 500,
            options: {
              chart: {
                width: 350,
              }
            }
          }
        ]
}

const ChartTypes = [
  {label: 'Bar', sid: 'bar'},
  {label: 'Pie', sid: 'pie'},
  {label: 'Line', sid:'line'},
  {label: 'Tree Map', sid:'treemap'}
]

const OrgFilter = [
  {label: 'No Org Assignment', sid: 'no_org_mapping'},
]

const UserRoleFilter = [
  {label: 'No Alert Recipient', sid: 'no_alert_recipient'}
]

const NoFormAlertFilter = [
  {label: 'No Form Configuration', sid: 'no_form_field_mapping'},
]

const MemberFilter = [
  {'label':'No Member assignment', 'sid':'no_member_assigned'},
]

const ImageUploadFilter = [
  {'label':'No image upload', 'sid':'no_image_upload'},
]

const PrivacyType = [
  {'label':'Public', 'sid':'public'},
  {'label':'All Org', 'sid':'org_public'},
  {'label':'Org Private', 'sid':'org_private'}
]

const CaseListFilter = {
  'is_active':[
    {'label':'Deleted Cases', 'value':'false'},
  ],
  'search_by':[
    {'label':'Mother Name', 'sid':'mother_name'},
    {'label':'Case ID', 'sid':'case_id'},
    {'label':'Child Name', 'sid':'child_name'},
    {'label':'Child ID', 'sid':'child_id'},
    {'label':'User Name', 'sid':'user_name'}
  ],
  'assignment':[
    {'label':'My Assignment', 'value':'true'},
  ],
  'weight_zscore_label':[
    {'label':'SUW'}, 
    {'label':'MUW'}, 
    {'label':'Mild'},
    {'label':'Normal'}
  ],
  'wfh_zscore_label':[
    {'label':'SAM'}, 
    {'label':'MAM'}, 
    {'label':'Normal'}
  ],
  'draft':[
    {'label':'Draft', 'value':'true'}
  ],
  'faltering':[
    {'label':'Faltering', 'value':'low'},
  ],
  'case_reports':[
    {'label':'Measurements uploaded', 'value':'measurements_uploaded'},
    {'label':'Measurements not uploaded', 'value':'measurements_not_uploaded'}
  ],
  'pregnancy_stages':[
    {'label':'ANC', 'value':'anc'},
    {'label':'PNC', 'value':'pnc'}
  ]
}

export {
  DataSources, AdminTypes, OrgRoleConfirmOptions, Portal, Locales, exitAlertMsg,
  Permissions, PermissionsObj, FormAlertTypes, DataSourceSelectionType, 
  ActiveStatusList, DefaultFilter,  setDefaultLabel, PreScheduleEventsDayList,
  DataSourcePermissionKey, InfantGrowthZscoreLabel, DateRangeList, OrgMemberCharts,
  WHOMetricsList, AgeDataFilterList, WHOGraphList, GraphMetricsList, PresetDateRange,
  MaternalPregnancyFilter, LeaderboardCharts, ApexResponsive, PrivacyType, CaseListFilter,
  ChartTypes, OrgFilter, UserRoleFilter, NoFormAlertFilter, MemberFilter, ImageUploadFilter
}