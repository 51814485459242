import React, {useContext, useState, useEffect} from "react"
import {AuthContext} from "../../contexts/Authentication/AuthStateProvider"
import useStyle from '../../hooks/useStyle'
import GeoLocation from "../Common/GeoLocation"
import {usePageTitle} from "../../hooks/pageHead"
import useAuth from './useAuth' 
import PreLoadSpinner from "../PreLoadSpinner"
import querystringify from "querystringify"

let geoData = {}
const GoogleSSORedirect = (props) => {
  useStyle('/css/login.css', true) 
  usePageTitle('Login')

  const urlParams = querystringify.parse(props.location.search);
  const { state: authState, dispatch } = useContext(AuthContext)
  const { user, setUser, handleFormSubmit, routeUser } = useAuth(props.history, dispatch, geoData)

  useEffect(()=>{
    if (urlParams.error) {
      console.log(`An error occurred: ${urlParams.error}`);
    } else {
      user.registration_mode = 'google_sso'
      user.google_token = urlParams.code
      setUser({...user})
      handleFormSubmit()
      console.log(`The code is: ${urlParams.code}`);
    }
  }, [])

  return (
    <div id="ct">
      <PreLoadSpinner/>
      <GeoLocation geoData={geoData} getAddress="true"/>
    </div>
  )

}

export default GoogleSSORedirect;