import env from '../../env';
import axios from 'axios';
let url = `${env.health_backend}health/nutrition_cares`
let notification_url = `${env.health_backend}health/nutrition_care/notifications`

class NutritionCareService {
  
  getAll(req){
    return new Promise((resolve, reject) => {
      axios.get(url, {params: req}).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        reject(e);
      })
    })
  }

  get(id){
    return new Promise((resolve, reject) => {
      axios.get(`${url}/${id}`).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        console.log('getid sp',e)
        reject(e);
      })
    })
  }

  create(req){
    return new Promise((resolve, reject) => {
      axios.post(url,req).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        reject(e);
      })
    })
  }

  update(id, req) {
    return new Promise((resolve, reject) => {
      axios.put(`${url}/${id}`, req).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        reject(e);
      })
    })
  }

  updateEventDrivenNotification(req) {
    return new Promise((resolve, reject) => {
      axios.put(`${env.health_backend}health/event_driven_notifications/${req.id}`, req).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        reject(e);
      })
    })
  }

  delete(id) {
    return new Promise((resolve, reject) => {
      axios.delete(`${url}/${id}`).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        reject(e);
      })
    })
  }

  notifications(req){
    return new Promise((resolve, reject) => {
      axios.get(notification_url, {params: req}).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        reject(e);
      })
    })
  }

  planners(req){
    return new Promise((resolve, reject) => {
      axios.get(`${env.health_backend}health/notification/planners`, {params: req}).then((res) => {
        // handle success
        resolve(res);
      })
    })
  }

  clone(req){
    return new Promise((resolve, reject) => {
      axios.post(`${env.health_backend}health/nutrition_care/clone`, req).then((res) => {
        // handle success
        resolve(res);
      })
    })
  }

}

export default new NutritionCareService();