import BaseService from "./../../../services/BaseService";
//const url = `${env.health_backend}health/patient_additional_info`;

class PatientAdditionalInfoService extends BaseService {
  /*
  getAll(req){
    return new Promise((resolve, reject) => {
      axios.get(url, {params: req}).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        reject(e);
      })
    })
  }

  get(req){
    return new Promise((resolve, reject) => {
      axios.get(`${url}/${req.id}`, { params: req}).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        reject(e);
      })
    })
  }

  create(role){
    return new Promise((resolve, reject) => {
      axios.post(url,role).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        reject(e);
      })
    })
  }

  update(role) {
    return new Promise((resolve, reject) => {
      axios.put(`${url}/${role.id}`, role).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        reject(e);
      })
    })
  }

  delete(id) {
    return new Promise((resolve, reject) => {
      axios.delete(`${url}/${id}`).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        reject(e);
      })
    })
  }
  */
}

export default new PatientAdditionalInfoService('health_backend', 'health/patient_additional_info');