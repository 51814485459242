import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { UnregisterSw, RegisterSw } from "./RegisterSw";

RegisterSw();

ReactDOM.render(
	<BrowserRouter>
		<App/>
	</BrowserRouter>, 
	document.getElementById('page')
);