const CountryCodes = {
	"af": ["Afghanistan (‫افغانستان‬‎)", "af", "93", "+##-##-###-####", "##-###-####"],
	"ax": ["Åland Islands", "ax", "358", ""],
	"al": ["Albania (Shqipëri)", "al", "355", "+###(###)###-###", "(###)###-###"],
	"dz": ["Algeria (‫الجزائر‬‎)", "dz", "213", "+###-##-###-####", "##-###-####"],
	"as": ["American Samoa", "as", "1684", "+#(###)###-####", "###-####"],
	"ad": ["Andorra", "ad", "376", "+###-###-###", "###-###"],
	"ao": ["Angola", "ao", "244", "+###(###)###-###", "(###)###-###"],
	"ai": ["Anguilla", "ai", "1264", "+#(###)###-####", "###-####"],
	"aq": ["Antarctica", "aq", "672", ""],
	"ag": ["Antigua and Barbuda", "ag", "1268", "+#(###)###-####", "###-####"],
	"ar": ["Argentina", "ar", "54", "+##(###)###-####", "(###)###-####"],
	"am": ["Armenia (Հայաստան)", "am", "374", "+###-##-###-###", "##-###-###"],
	"aw": ["Aruba", "aw", "297", "+###-###-####", "###-####"],
	"au": ["Australia", "au", "61", "+## ### ### ###", "### ### ###"],
	"at": ["Austria (Österreich)", "at", "43", "+##(###)###-####", "(###)###-####"],
	"az": ["Azerbaijan (Azərbaycan)", "az", "994", "+###-##-###-##-##", "##-###-##-##"],
	"bs": ["Bahamas", "bs", "1242", "+#(###)###-####", "###-####"],
	"bh": ["Bahrain (‫البحرين‬‎)", "bh", "973", "+###-####-####", "####-####"],
	"bd": ["Bangladesh (বাংলাদেশ)", "bd", "880", "+###-##-###-###", "##-###-###"],
	"bb": ["Barbados", "bb", "1246", "+#(###)###-####", "###-####"],
	"by": ["Belarus (Беларусь)", "by", "375", "+###(##)###-##-##", "(##)###-##-##"],
	"be": ["Belgium (België)", "be", "32", "+## ### ## ## ##", "### ## ## ##"],
	"bz": ["Belize", "bz", "501", "+###-###-####", "###-####"],
	"bj": ["Benin (Bénin)", "bj", "229", "+###-##-##-####", "##-##-####"],
	"bm": ["Bermuda", "bm", "1441", "+#(###)###-####", "###-####"],
	"bt": ["Bhutan (འབྲུག)", "bt", "975", "+###-#-###-###", "#-###-###"],
	"bo": ["Bolivia", "bo", "591", "+###-#-###-####", "#-###-####"],
	"ba": ["Bosnia and Herzegovina (Босна и Херцеговина)", "ba", "387", "+###-##-####", "##-####"],
	"bw": ["Botswana", "bw", "267", "+###-##-###-###", "##-###-###"],
	"bv": ["Bouvet Island", "bv", "47", ""],
	"br": ["Brazil (Brasil)", "br", "55", "+##-##-####-####", "##-####-####"],
	"io": ["British Indian Ocean Territory", "io", "246", "+###-###-####", "###-####"],
	"vg": ["British Virgin Islands", "vg", "1284", "+#(###)###-####", "###-####"],
	"bn": ["Brunei", "bn", "673", "+###-###-####", "###-####"],
	"bg": ["Bulgaria (България)", "bg", "359", "+###(###)###-###", "(###)###-###"],
	"bf": ["Burkina Faso", "bf", "226", "+###-##-##-####", "##-##-####"],
	"bi": ["Burundi (Uburundi)", "bi", "257", "+###-##-##-####", "##-##-####"],
	"kh": ["Cambodia (កម្ពុជា)", "kh", "855", "+###-##-###-###", "##-###-###"],
	"cm": ["Cameroon (Cameroun)", "cm", "237", "+###-####-####", "####-####"],
	"ca": ["Canada", "ca", "1", "+# (###) ###-####", 1, ["204", "236", "249", "250", "289", "306", "343", "365", "387", "403", "416", "418", "431", "437", "438", "450", "506", "514", "519", "548", "579", "581", "587", "604", "613", "639", "647", "672", "705", "709", "742", "778", "780", "782", "807", "819", "825", "867", "873", "902", "905"], " (###) ###-####"],
	"cv": ["Cape Verde (Kabu Verdi)", "cv", "238", "+###(###)##-##", "(###)##-##"],
	"bq": ["Caribbean Netherlands", "bq", "599", "+###-###-####", 1, "###-####"],
	"ky": ["Cayman Islands", "ky", "1345", "+#(###)###-####", "###-####"],
	"cf": ["Central African Republic (République centrafricaine)", "cf", "236", "+###-##-##-####", "##-##-####"],
	"td": ["Chad (Tchad)", "td", "235", "+###-##-##-##-##", "##-##-##-##"],
	"cl": ["Chile", "cl", "56", "+##-#-####-####", "#-####-####"],
	"cn": ["China (中国)", "cn", "86", "+## ##-########", "##-########"],
	"cx": ["Christmas Island", "cx", "61", ""],
	"cc": ["Cocos (Keeling) Islands", "cc", "61", ""],
	"co": ["Colombia", "co", "57", "+##(###)###-####", "(###)###-####"],
	"km": ["Comoros (‫جزر القمر‬‎)", "km", "269", "+###-##-#####", "##-#####"],
	"cd": ["Congo (DRC) (Jamhuri ya Kidemokrasia ya Kongo)", "cd", "243", "+###(###)###-###", "(###)###-###"],
	"cg": ["Congo (Republic) (Congo-Brazzaville)", "cg", "242", "+###-##-###-####", "##-###-####"],
	"ck": ["Cook Islands", "ck", "682", "+###-##-###", "##-###"],
	"cr": ["Costa Rica", "cr", "506", "+### ####-####", "####-####"],
	"ci": ["Côte d’Ivoire", "ci", "225", "+###-##-###-###", "##-###-###"],
	"hr": ["Croatia (Hrvatska)", "hr", "385", "+###-##-###-###", "##-###-###"],
	"cu": ["Cuba", "cu", "53", "+##-#-###-####", "#-###-####"],
	"cw": ["Curaçao", "cw", "599", "+###-###-####", 0, "###-####"],
	"cy": ["Cyprus (Κύπρος)", "cy", "357", "+###-##-###-###", "##-###-###"],
	"cz": ["Czech Republic (Česká republika)", "cz", "420", "+###(###)###-###", "(###)###-###"],
	"dk": ["Denmark (Danmark)", "dk", "45", "+## ## ## ## ##", "## ## ## ##"],
	"dj": ["Djibouti", "dj", "253", "+###-##-##-##-##", "##-##-##-##"],
	"dm": ["Dominica", "dm", "1767", "+#(###)###-####", "###-####"],
	"do": ["Dominican Republic (República Dominicana)", "do", "1", "+#(###)###-####", 2, ["809", "829", "849"], "(###)###-####"],
	"ec": ["Ecuador", "ec", "593", "+###-#-###-####", "#-###-####"],
	"eg": ["Egypt (‫مصر‬‎)", "eg", "20", "+##(###)###-####", "(###)###-####"],
	"sv": ["El Salvador", "sv", "503", "+### ####-####", "####-####"],
	"gq": ["Equatorial Guinea (Guinea Ecuatorial)", "gq", "240", "+###-##-###-####", "##-###-####"],
	"er": ["Eritrea", "er", "291", "+###-#-###-###", "#-###-###"],
	"ee": ["Estonia (Eesti)", "ee", "372", "+###-###-####", "###-####"],
	"et": ["Ethiopia", "et", "251", "+###-##-###-####", "##-###-####"],
	"fk": ["Falkland Islands (Islas Malvinas)", "fk", "500", "+###-#####", "#####"],
	"fo": ["Faroe Islands (Føroyar)", "fo", "298", "+###-###-###", "###-###"],
	"fj": ["Fiji", "fj", "679", "+###-##-#####", "##-#####"],
	"fi": ["Finland (Suomi)", "fi", "358", "+### ## #### ####", "## #### ####"],
	"fr": ["France", "fr", "33", "+## # ## ## ## ##", "# ## ## ## ##"],
	"gf": ["French Guiana (Guyane française)", "gf", "594", "+###-#####-####", "#####-####"],
	"pf": ["French Polynesia (Polynésie française)", "pf", "689", "+###-##-##-##", "##-##-##"],
	"tf": ["French Southern and Antarctic Lands", "tf", "262", ""],
	"ga": ["Gabon", "ga", "241", "+###-#-##-##-##", "#-##-##-##"],
	"gm": ["Gambia", "gm", "220", "+###(###)##-##", "(###)##-##"],
	"ge": ["Georgia (საქართველო)", "ge", "995", "+###(###)###-###", "(###)###-###"],
	"de": ["Germany (Deutschland)", "de", "49", "+## ### #######", "### #######"],
	"gh": ["Ghana (Gaana)", "gh", "233", "+###(###)###-###", "(###)###-###"],
	"gi": ["Gibraltar", "gi", "350", "+###-###-#####", "###-#####"],
	"gr": ["Greece (Ελλάδα)", "gr", "30", "+##(###)###-####", "(###)###-####"],
	"gl": ["Greenland (Kalaallit Nunaat)", "gl", "299", "+###-##-##-##", "##-##-##"],
	"gd": ["Grenada", "gd", "1473", "+#(###)###-####", "###-####"],
	"gp": ["Guadeloupe", "gp", "590", "", 0],
	"gu": ["Guam", "gu", "1671", "+#(###)###-####", "###-####"],
	"gt": ["Guatemala", "gt", "502", "+### ####-####", "####-####"],
	"gg": ["Guernsey", "gg", "44", ""],
	"gn": ["Guinea (Guinée)", "gn", "224", "+###-##-###-###", "##-###-###"],
	"gw": ["Guinea-Bissau (Guiné Bissau)", "gw", "245", "+###-#-######", "#-######"],
	"gy": ["Guyana", "gy", "592", "+###-###-####", "###-####"],
	"ht": ["Haiti", "ht", "509", "+### ####-####", "####-####"],
	"hm": ["Heard Island and McDonald Islands", "hm", "672", ""],
	"hn": ["Honduras", "hn", "504", "+###-####-####", "####-####"],
	"hk": ["Hong Kong (香港)", "hk", "852", "+### #### ####", "#### ####"],
	"hu": ["Hungary (Magyarország)", "hu", "36", "+##(###)###-###", "(###)###-###"],
	"is": ["Iceland (Ísland)", "is", "354", "+### ### ####", "### ####"],
	"in": ["India (भारत)", "in", "91", "+## #####-#####", "#####-#####"],
	"id": ["Indonesia", "id", "62", "+##-##-###-##", "##-###-##"],
	"ir": ["Iran (‫ایران‬‎)", "ir", "98", "+##(###)###-####", "(###)###-####"],
	"iq": ["Iraq (‫العراق‬‎)", "iq", "964", "+###(###)###-####", "(###)###-####"],
	"ie": ["Ireland", "ie", "353", "+### ## #######", "## #######"],
	"im": ["Isle of Man", "im", "44", ""],
	"il": ["Israel (‫ישראל‬‎)", "il", "972", "+###-#-###-####", "#-###-####"],
	"it": ["Italy (Italia)", "it", "39", "+## ### ######", 0, "### ######"],
	"jm": ["Jamaica", "jm", "1876", "+#(###)###-####", "###-####"],
	"jp": ["Japan (日本)", "jp", "81", "+## ### ## ####", "### ## ####"],
	"je": ["Jersey", "je", "44", ""],
	"jo": ["Jordan (‫الأردن‬‎)", "jo", "962", "+###-#-####-####", "#-####-####"],
	"kz": ["Kazakhstan (Казахстан)", "kz", "7", "+# ### ###-##-##", 1, "### ###-##-##"],
	"ke": ["Kenya", "ke", "254", "+###-###-######", "###-######"],
	"ki": ["Kiribati", "ki", "686", "+###-##-###", "##-###"],
	"xk": ["Kosovo", "xk", "383", ""],
	"kw": ["Kuwait (‫الكويت‬‎)", "kw", "965", "+###-####-####", "####-####"],
	"kg": ["Kyrgyzstan (Кыргызстан)", "kg", "996", "+###(###)###-###", "(###)###-###"],
	"la": ["Laos (ລາວ)", "la", "856", "+###-##-###-###", "##-###-###"],
	"lv": ["Latvia (Latvija)", "lv", "371", "+###-##-###-###", "##-###-###"],
	"lb": ["Lebanon (‫لبنان‬‎)", "lb", "961", "+###-#-###-###", "#-###-###"],
	"ls": ["Lesotho", "ls", "266", "+###-#-###-####", "#-###-####"],
	"lr": ["Liberia", "lr", "231", "+###-##-###-###", "##-###-###"],
	"ly": ["Libya (‫ليبيا‬‎)", "ly", "218", "+###-##-###-###", "##-###-###"],
	"li": ["Liechtenstein", "li", "423", "+###(###)###-####", "(###)###-####"],
	"lt": ["Lithuania (Lietuva)", "lt", "370", "+###(###)##-###", "(###)##-###"],
	"lu": ["Luxembourg", "lu", "352", "+###(###)###-###", "(###)###-###"],
	"mo": ["Macau (澳門)", "mo", "853", "+###-####-####", "####-####"],
	"mk": ["Macedonia (FYROM) (Македонија)", "mk", "389", "+###-##-###-###", "##-###-###"],
	"mg": ["Madagascar (Madagasikara)", "mg", "261", "+###-##-##-#####", "##-##-#####"],
	"mw": ["Malawi", "mw", "265", "+###-#-####-####", "#-####-####"],
	"my": ["Malaysia", "my", "60", "+## ##-####-####", "##-####-####"],
	"mv": ["Maldives", "mv", "960", "+###-###-####", "###-####"],
	"ml": ["Mali", "ml", "223", "+###-##-##-####", "##-##-####"],
	"mt": ["Malta", "mt", "356", "+###-####-####", "####-####"],
	"mh": ["Marshall Islands", "mh", "692", "+###-###-####", "###-####"],
	"mq": ["Martinique", "mq", "596", "+###(###)##-##-##", "(###)##-##-##"],
	"mr": ["Mauritania (‫موريتانيا‬‎)", "mr", "222", "+###-##-##-####", "##-##-####"],
	"mu": ["Mauritius (Moris)", "mu", "230", "+###-###-####", "###-####"],
	"yt": ["Mayotte", "yt", "262", ""],
	"mx": ["Mexico (México)", "mx", "52", "+##-##-##-####", "##-##-####"],
	"fm": ["Micronesia", "fm", "691", "+###-###-####", "###-####"],
	"md": ["Moldova (Republica Moldova)", "md", "373", "+###-####-####", "####-####"],
	"mc": ["Monaco", "mc", "377", "+###-##-###-###", "##-###-###"],
	"mn": ["Mongolia (Монгол)", "mn", "976", "+###-##-##-####", "##-##-####"],
	"me": ["Montenegro (Crna Gora)", "me", "382", "+###-##-###-###", "##-###-###"],
	"ms": ["Montserrat", "ms", "1664", "+#(###)###-####", "###-####"],
	"ma": ["Morocco (‫المغرب‬‎)", "ma", "212", "+###-##-####-###", "##-####-###"],
	"mz": ["Mozambique (Moçambique)", "mz", "258", "+###-##-###-###", "##-###-###"],
	"mm": ["Myanmar (Burma) (မြန်မာ)", "mm", "95", "+##-###-###", "###-###"],
	"na": ["Namibia (Namibië)", "na", "264", "+###-##-###-####", "##-###-####"],
	"nr": ["Nauru", "nr", "674", "+###-###-####", "###-####"],
	"np": ["Nepal (नेपाल)", "np", "977", "+###-##-###-###", "##-###-###"],
	"nl": ["Netherlands (Nederland)", "nl", "31", "+## ## ########", "## ########"],
	"nc": ["New Caledonia (Nouvelle-Calédonie)", "nc", "687", "+###-##-####", "##-####"],
	"nz": ["New Zealand", "nz", "64", "+## ###-###-####", "###-###-####"],
	"ni": ["Nicaragua", "ni", "505", "+###-####-####", "####-####"],
	"ne": ["Niger (Nijar)", "ne", "227", "+###-##-##-####", "##-##-####"],
	"ng": ["Nigeria", "ng", "234", "+###-##-###-##", "##-###-##"],
	"nu": ["Niue", "nu", "683", "+###-####", "####"],
	"nf": ["Norfolk Island", "nf", "672", "+###-###-###", "###-###"],
	"kp": ["North Korea (조선 민주주의 인민 공화국)", "kp", "850", "+###-###-###", "###-###"],
	"mp": ["Northern Mariana Islands", "mp", "1670", "+#(###)###-####", "###-####"],
	"no": ["Norway (Norge)", "no", "47", "+## ### ## ###", "### ## ###"],
	"om": ["Oman (‫عُمان‬‎)", "om", "968", "+###-##-###-###", "##-###-###"],
	"pk": ["Pakistan (‫پاکستان‬‎)", "pk", "92", "+## ###-#######", "###-#######"],
	"pw": ["Palau", "pw", "680", "+###-###-####", "###-####"],
	"ps": ["Palestine (‫فلسطين‬‎)", "ps", "970", "+###-##-###-####", "##-###-####"],
	"pa": ["Panama (Panamá)", "pa", "507", "+###-###-####", "###-####"],
	"pg": ["Papua New Guinea", "pg", "675", "+###(###)##-###", "(###)##-###"],
	"py": ["Paraguay", "py", "595", "+###(###)###-###", "(###)###-###"],
	"pe": ["Peru (Perú)", "pe", "51", "+##(###)###-###", "(###)###-###"],
	"ph": ["Philippines", "ph", "63", "+## ### ####", "### ####"],
	"pn": ["Pitcairn Islands", "pn", "64", ""],
	"pl": ["Poland (Polska)", "pl", "48", "+## ###-###-###", "###-###-###"],
	"pt": ["Portugal", "pt", "351", "+###-##-###-####", "##-###-####"],
	"pr": ["Puerto Rico", "pr", "1", "+# (###) ###-####", 3, ["787", "939"], " (###) ###-####"],
	"qa": ["Qatar (‫قطر‬‎)", "qa", "974", "+###-####-####", "####-####"],
	"re": ["Réunion (La Réunion)", "re", "262", "+###-#####-####", "#####-####"],
	"ro": ["Romania (România)", "ro", "40", "+##-##-###-####", "##-###-####"],
	"ru": ["Russia (Россия)", "ru", "7", "+# ### ###-##-##", 0, "### ###-##-##"],
	"rw": ["Rwanda", "rw", "250", "+###(###)###-###", "(###)###-###"],
	"bl": ["Saint Barthélemy (Saint-Barthélemy)", "bl", "590", "", 1],
	"sh": ["Saint Helena", "sh", "290"],
	"kn": ["Saint Kitts and Nevis", "kn", "1869", "+#(###)###-####", "###-####"],
	"lc": ["Saint Lucia", "lc", "1758", "+#(###)###-####", "###-####"],
	"mf": ["Saint Martin (Saint-Martin (partie française))", "mf", "590", "", 2],
	"pm": ["Saint Pierre and Miquelon (Saint-Pierre-et-Miquelon)", "pm", "508"],
	"vc": ["Saint Vincent and the Grenadines", "vc", "1784", "+#(###)###-####", "###-####"],
	"ws": ["Samoa", "ws", "685", "+###-##-####", "##-####"],
	"sm": ["San Marino", "sm", "378", "+###-####-######", "####-######"],
	"st": ["São Tomé and Príncipe (São Tomé e Príncipe)", "st", "239", "+###-##-#####", "##-#####"],
	"sa": ["Saudi Arabia (‫المملكة العربية السعودية‬‎)", "sa", "966", "+###-##-###-####", "##-###-####"],
	"sn": ["Senegal (Sénégal)", "sn", "221", "+###-##-###-####", "##-###-####"],
	"rs": ["Serbia (Србија)", "rs", "381", "+###-##-###-####", "##-###-####"],
	"sc": ["Seychelles", "sc", "248", "+###-#-###-###", "#-###-###"],
	"sl": ["Sierra Leone", "sl", "232", "+###-##-######", "##-######"],
	"sg": ["Singapore", "sg", "65", "+## ####-####", "####-####"],
	"sx": ["Sint Maarten", "sx", "1721", "+#(###)###-####", "###-####"],
	"sk": ["Slovakia (Slovensko)", "sk", "421", "+###(###)###-###", "(###)###-###"],
	"si": ["Slovenia (Slovenija)", "si", "386", "+###-##-###-###", "##-###-###"],
	"sb": ["Solomon Islands", "sb", "677", "+###-#####", "#####"],
	"so": ["Somalia (Soomaaliya)", "so", "252", "+###-#-###-###", "#-###-###"],
	"za": ["South Africa", "za", "27", "+##-##-###-####", "##-###-####"],
	"gs": ["South Georgia and the South Sandwich Islands", "gs", "500", ""],
	"kr": ["South Korea (대한민국)", "kr", "82", "+##-##-###-####", "##-###-####"],
	"ss": ["South Sudan (‫جنوب السودان‬‎)", "ss", "211", "+###-##-###-####", "##-###-####"],
	"es": ["Spain (España)", "es", "34", "+## ### ### ###", "### ### ###"],
	"lk": ["Sri Lanka (ශ්‍රී ලංකාව)", "lk", "94", "+##-##-###-####", "##-###-####"],
	"sd": ["Sudan (‫السودان‬‎)", "sd", "249", "+###-##-###-####", "##-###-####"],
	"sr": ["Suriname", "sr", "597", "+###-###-###", "###-###"],
	"sj": ["Svalbard and Jan Mayen", "sj", "47", ""],
	"sz": ["Swaziland", "sz", "268", "+###-##-##-####", "##-##-####"],
	"se": ["Sweden (Sverige)", "se", "46", "+## ## ### ## ##", "## ### ## ##"],
	"ch": ["Switzerland (Schweiz)", "ch", "41", "+## ## ### ## ##", "## ### ## ##"],
	"sy": ["Syria (‫سوريا‬‎)", "sy", "963", "+###-##-####-###", "##-####-###"],
	"tw": ["Taiwan (台灣)", "tw", "886", "+###-####-####", "####-####"],
	"tj": ["Tajikistan", "tj", "992", "+###-##-###-####", "##-###-####"],
	"tz": ["Tanzania", "tz", "255", "+###-##-###-####", "##-###-####"],
	"th": ["Thailand (ไทย)", "th", "66", "+##-##-###-###", "##-###-###"],
	"tl": ["Timor-Leste", "tl", "670", "+###-###-####", "###-####"],
	"tg": ["Togo", "tg", "228", "+###-##-###-###", "##-###-###"],
	"tk": ["Tokelau", "tk", "690", "+###-####", "####"],
	"to": ["Tonga", "to", "676", "+###-#####", "#####"],
	"tt": ["Trinidad and Tobago", "tt", "1868", "+#(###)###-####", "###-####"],
	"tn": ["Tunisia (‫تونس‬‎)", "tn", "216", "+###-##-###-###", "##-###-###"],
	"tr": ["Turkey (Türkiye)", "tr", "90", "+## ### ### ## ##", "### ### ## ##"],
	"tm": ["Turkmenistan", "tm", "993", "+###-#-###-####", "#-###-####"],
	"tc": ["Turks and Caicos Islands", "tc", "1649", "+#(###)###-####", "###-####"],
	"tv": ["Tuvalu", "tv", "688", "+###-#####", "#####"],
	"vi": ["U.S. Virgin Islands", "vi", "1340", "+#(###)###-####", "###-####"],
	"ug": ["Uganda", "ug", "256", "+###(###)###-###", "(###)###-###"],
	"ua": ["Ukraine (Україна)", "ua", "380", "+###(##)###-##-##", "(##)###-##-##"],
	"ae": ["United Arab Emirates (‫الإمارات العربية المتحدة‬‎)", "ae", "971", "+###-#-###-####", "#-###-####"],
	"gb": ["United Kingdom", "gb", "44", "+## #### ######", "#### ######"],
	"us": ["United States", "us", "1", "+# (###) ###-####", 0, " (###) ###-####"],
	"um": ["United States Minor Outlying Islands", "um", "1", "", 2],
	"uy": ["Uruguay", "uy", "598", "+###-#-###-##-##", "#-###-##-##"],
	"uz": ["Uzbekistan (Oʻzbekiston)", "uz", "998", "+###-##-###-####", "##-###-####"],
	"vu": ["Vanuatu", "vu", "678", "+###-#####", "#####"],
	"va": ["Vatican City (Città del Vaticano)", "va", "39", "+## ## #### ####", 1, "## #### ####"],
	"ve": ["Venezuela", "ve", "58", "+##(###)###-####", "(###)###-####"],
	"vn": ["Vietnam (Việt Nam)", "vn", "84", "+##-##-####-###", "##-####-###"],
	"wf": ["Wallis and Futuna", "wf", "681", "+###-##-####", "##-####"],
	"eh": ["Western Sahara", "eh", "212", "+###-##-####", "##-####"],
	"ye": ["Yemen (‫اليمن‬‎)", "ye", "967", "+###-#-###-###", "#-###-###"],
	"zm": ["Zambia", "zm", "260", "+###-##-###-####", "##-###-####"],
	"zw": ["Zimbabwe", "zw", "263", "+###-#-######", "#-######"]
}

/*for (let key in CountryCodes) {
  console.log(key)
  let c = CountryCodes[key]
  console.log(c[2])
  console.log(c[3])
  const len = c[2].length
  if(c[3]){
	let hs1 = c[3]?.slice(0, len+1)
	let hs2 = null
	if(hs1.includes('(')){
	  hs2 = c[3]?.slice(len+1+1) 	
	}else{
	  hs2 = c[3]?.slice(len+1)	
	}
	if(hs2[0]=='-' || hs2[0]==')' || hs2[0]==' '){
	  hs2 = hs2?.slice(1)		
	}
	console.log(hs2)
	c.push(hs2)
  }	
}
console.log(JSON.stringify(CountryCodes))*/
export {CountryCodes}