import React, { useState, useEffect } from "react"
import SavedReportFilterService from "./SavedReportFilterService"

const SavedReportFilterList = ({filter, setFilter}) => {
  const [checked, setChecked] = useState()
  const [savedFilters, setSavedFilters] = useState([])
  const [newFilter, setNewFilter] = useState({})

  useEffect(()=>{
    getFilters()
  }, [])

  const getFilters = async () =>{
    const {data, status} = await SavedReportFilterService.getAll()
    if(status === 200){
      setSavedFilters(data.filters)
    }
  }

  /*useEffect(()=>{
    if(newFilter.id){
      setSavedFilters([...savedFilters, newFilter]) 
    }
  }, [newFilter])*/

  const addFilter = (target, is_checkbox) =>{
    setNewFilter({...newFilter, [target.name]: is_checkbox?target.checked:target.value})
  }

  const saveFilter = async () => {
    if(newFilter.name && filter){
      const {data, status} = await SavedReportFilterService.create({...newFilter, data:filter})
      if(status === 201){
        setSavedFilters([...savedFilters, data.filter])
        setFilter({'id':data.filter.id, ...filter})
      }
    }
  }

  const deleteFilter = (e, id) =>{
    e.stopPropagation()
    SavedReportFilterService.delete(id).then((res) => {
      if (res.status === 204) {
        setSavedFilters(savedFilters.filter(i => i.id !== id))
        setFilter(filter.id===id?{}:filter)
      }
    })
  }

  const checkFilter = (f) =>{
    setFilter(f.id === filter.id?{}:{'id':f.id, ...f.data})
  }

  return(
    <div>
      <div className="card-title card-title-gray m-t-10 flex aligncenter p10 w-full">
        <h4 className="f18 text-left">
          Predefined Filters
        </h4>
      </div>
      <div className="card-body flex aligncenter justifycenter coldir p10">
        <div className="m-b-10 flex aligncenter justifycenter">
            {/*<div className="m-r-15">
            <input type="checkbox" id="save_filter"
              onChange={(e)=>addFilter(e.target, true)} 
              name="save_filter" className="checkbox-md" />
            </div>*/}
        
            <input onChange={(e)=>addFilter(e.target)} name="name" 
              className="form-input form-input-gray form-input-full" 
              placeholder="Enter name to save current search"/>
            {filter?.id ?   
              null
              :
              <span className="bg-highlight round-btn m-l-15" onClick={()=>saveFilter()}>
                <i className="fas fa-plus"/>
              </span>
            }
          </div>
        <div className="col-xs-12 mxh-300 scroll-auto m-l-20">
          {/*<CheckBox list={savedFilters || []} 
            labelKey="name" valueKey="id" onToggleReturnObject={true}
            toggle={(id)=>checkFilter(id)} 
            exists={(id)=>filter?.id === id} 
            name="data_filter_types"/> */}
          {savedFilters?.map((f, i)=>
            <span key={i} className="checkbox-container" onClick={() => checkFilter(f)}>
              <span>{f.name}</span>
              <i className="fas fa-times-circle m-l-5 lred" onClick={(e)=>deleteFilter(e, f.id)}/>
              <input type="checkbox" checked={filter?.id === f.id} readOnly/>
              <span className="checkbox-checkmark"/>
            </span>
          )}
        </div>
      </div>
    </div>
  )
}

export {SavedReportFilterList};