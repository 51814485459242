import { useState, useRef } from 'react';
import FormTestInputService from './FormTestInputService';
import useSort from '../../hooks/useSorting';

const useFormTestInput = (formId) => {
  const [loading, setLoading] = useState(true);
  const [testDataList, setTestDataList] = useState([]);
  const [pagemeta, setPagemeta] = useState({});
  const [currentpage, setCurrentpage] = useState(1);
  const [formFields, setFormFields] = useState([]);

  let {current:requestParams} = useRef({
    'page':currentpage,
    'per_page':15,
    'sort_column':'updated_at',
    'sort_direction':'desc',
    'fields':"assigned_organizations",
    'filter':{
      'form_id':formId
    },
    'form_fields_params':{
      'ids':[formId],
      'is_table_view':true
    }
  });

  const getFormTestInputList = () =>{
    setLoading(true)
    FormTestInputService.getAll(requestParams).then((res)=>{
      if(res.status === 200){
        requestParams.totalItems = res.data.meta.total;
        setTestDataList(res.data.form_test_inputs);
        setFormFields(res.data.form_fields[formId]);
        setLoading(false);
      }
    })
  }
  
  const {sort} = useSort(requestParams, getFormTestInputList)

  const deleteFormInput = async (id) =>{
    setLoading(true);
    const {status} = await FormTestInputService.delete(id);
    setTestDataList(testDataList.filter(d => d.id !== id));
    setLoading(false);
  }

  return {
    deleteFormInput,
    requestParams,
    getFormTestInputList,
    currentpage,
    setCurrentpage,
    sort,
    testDataList,
    setTestDataList,
    pagemeta,
    setPagemeta,
    formFields
  }
};

export {useFormTestInput};