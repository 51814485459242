import env from '../env';
import axios from 'axios';

class FormTemplateService {
  static async getFormTemplateById(id, req) {
    const u = env.health_backend + 'health' + (req?.is_public ? '/public' : '') + '/form_templates/' + id;
    const response = await axios.get(u, { params: req, cache: true });
    return response.data;
  }
  
  static async getFormTemplates(req) {
    const u = env.health_backend + 'health/form_templates';
    const response = await axios.get(u, { params: req });
    return response;
  }
  
  static async getFormFieldsById(req) {
    const u = env.health_backend + 'health/form_template/form_fields';
    const response = await axios.get(u, { params: req });
    return response;
  }
  
  static async getFormWizardFields(req) {
    const res = await axios.post(env.health_backend + 'form_wizard_fields/form_templates', req);
    return res;
  }
  
  static async getFormWizardSchedules(form_wizard_schedule_id) {
    const res = await axios.get(env.health_backend + `form_wizard_schedulers/${form_wizard_schedule_id}`);
    return res;
  }
  
  static async getFormTemplateRules(req) {
    const u = env.health_backend + 'health/get_form_template_rules';
    const response = await axios.get(u, { params: req });
    return response;
  }
  
  static async syncFormTemplateRules() {
    const u = env.health_backend + 'health/sync/template_rules';
    const response = await axios.get(u);
    return response;
  }
  
  static async updateFormFieldLocale(req) {
    const res = await axios.put(env.health_backend + 'health/form_template/update_locale', req);
    return res;
  }
  
  static async getFormFieldFilters(req) {
    const u = env.health_backend + 'health/form_field_filters';
    const res = await axios.post(u, req);
    if (res.status === 200) {
      return res.data;
    }
    return null;
  }
  
  static async getFormFields(req) {
    const u = env.health_backend + 'health/form_template/get_form_fields';
    const response = await axios.post(u, req);
    return response;
  }
  
}
export default FormTemplateService;

