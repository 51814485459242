const setCurrentPage = (pagemeta) =>{
  pagemeta.current_page = parseInt(pagemeta.current_page)
}

export default (state, action) => {
  const {type, payload} = action
  switch (type) {
    case "REPLACE":
      setCurrentPage(payload.pagemeta)
      return {
        ...state,
        'pagemeta':payload.pagemeta,
        'journeyList':[...payload.journeyList],
        'dataSource': (payload.dataSource || state.dataSource),
      };
    case "APPEND":
      setCurrentPage(payload.pagemeta)
      return {
        ...state,
        'pagemeta':payload.pagemeta,
        'journeyList':[...state.journeyList, ...payload.journeyList],
        'dataSource': (payload.dataSource || state.dataSource),
      };
    case "DELETE":
      return {
        ...state,
        'journeyList':[...state.journeyList.filter(i => i.id !== action.id)]
      };
    case "UPDATE":
      state.journeyList[action.idx] = {...state.journeyList[action.idx], ...payload}
      return {
        ...state,
        'journeyList': [...state.journeyList]
      };
    case "UPDATE_BY_ID":
      const idx = state.journeyList.findIndex(j => j.id === payload.id)
      if(idx > -1){
        state.journeyList[idx] = {...state.journeyList[idx], ...payload}
      }
      return {
        ...state,
        'journeyList':[...state.journeyList]
      };
    case "ACTIVE":
      return {
        ...state,
        'activeJourney':payload.activeJourney
      };
    case "UPDATE_PAGEMETA":
      state.pagemeta = payload.pagemeta;
      return state
    case "CLEAR":
      return {
        ...state,
        'journeyList':[], 
        'pagemeta':{},
        'activeJourney':null
      };
    default:
      return state;
  }
};