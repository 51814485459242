import env from '../../../env';
import axios from 'axios';
import BaseService from '../../../services/BaseService';

class CounselingService extends BaseService {

  async uploadCsv(req) {
    return await axios.post(`${env.infant_backend}upload_csv/counselings`, req);
  }

  async notifications(req) {
    return await axios.get(`${env.infant_backend}notification/counselings`, { params: req });
  }

  async updateRichTextImg(req) {
    return await axios.put(env.infant_backend + 'counselings:rich_text_images', req);
  }

}

export default new CounselingService('infant_backend', 'counselings');