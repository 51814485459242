import React, {lazy, Suspense} from 'react'
import PrivateRoute from "../../routes/PrivateRoute"
import PublicRoute from "../../routes/PublicRoute"
const ContactListTable = lazy(() => import('./List/ContactListTable'));
const ContactForm = lazy(() => import('./ContactForm'));

const ContactsRouter = (props) => (
  <Suspense fallback={<div className="spinner"/>}>	
    <PrivateRoute path="/list/contacts" searchString={props.searchString} component={ContactListTable} exact />
    <PublicRoute path="/contact/:id?" component={ContactForm} exact />
  </Suspense>
)

export default ContactsRouter;