import React, { useState} from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/swiper.min.css";
import "swiper/components/navigation/navigation.min.css"

// import Swiper core and required modules
import SwiperCore, {Navigation,Keyboard} from 'swiper/core';

// install Swiper modules
SwiperCore.use([Navigation, Keyboard]);

/*let Swiper = null
let SwiperSlide = null
let SwiperCore = null 
let Navigation = null 
let Keyboard = null*/
const SwiperContainer = ({children, setActiveIndex, currentpage, setCurrentpage, requestParams, indexEle, cardViewCount, autoHeight, onReachEnd, loop}) => {

  const [loading, setLoading] = useState(false)

  /*useEffect(()=>{
    import('swiper/react').then((d) => {
      console.log(d)
      Swiper = d.Swiper
      SwiperSlide = d.SwiperSlide
      checkSwiperLoaded()
    }).catch(error => console.log(error));

    import('swiper/core').then((d) => {
      console.log(d)
      SwiperCore = d
      Navigation = d.Navigation
      Keyboard = d.Keyboard
      checkSwiperLoaded()
    }).catch(error => console.log(error));
  }, [])

  const checkSwiperLoaded = () =>{
    if(Swiper && SwiperSlide && SwiperCore && Navigation && Keyboard){
     SwiperCore.use([Navigation, Keyboard]);
    }
  }*/

  const reachBeginning = () =>{
    console.log('begining');
    console.log(currentpage);
    /*(currentpage > 1) && setCurrentpage(currentpage - 1)*/
  }

  const reachEnd = () =>{
    console.log('end');
    console.log(currentpage);
    const totalPage = Math.ceil(requestParams.totalItems / requestParams.per_page);
    (currentpage < totalPage) && setCurrentpage(currentpage + 1);
    onReachEnd && onReachEnd(currentpage); 
  }

  const activeIndexChange = (swiper) =>{
    console.log('index');
    //console.log(swiper);
    setActiveIndex && setActiveIndex(swiper.activeIndex);
    if(indexEle?.current)
      indexEle.current.innerText = swiper.snapIndex + 1
    //(currentpage > 1) && setCurrentpage(currentpage - 1)
  }

  if(loading)
    return <div className="spinner"/>

  return(
      <Swiper slidesPerView={cardViewCount}  grabCursor={true}
        slidesPerGroup={cardViewCount} 
        loop={loop}
        keyboard={{"enabled": true}}
        navigation={true} 
        className="mySwiper"
        autoHeight={autoHeight}
        roundLengths={true}
        onSlideChange={() => console.log('slide change')}
        onSwiper={(swiper) => console.log(swiper)}
        onReachEnd={() => reachEnd()}
        onReachBeginning={() => reachBeginning()}
        onBeforeTransitionStart={(swiper) => activeIndexChange(swiper)}>
        {children}
      </Swiper> 
  )

}

export {SwiperContainer, SwiperSlide};