import React from "react"
import DynamicTable from "../../../Common/DynamicTable"
import { EmptyRowAlert } from '../../../Common/TableView'

const DailyCheckTable = (props) =>{
  const {requestParams, sort, onRowClick, formFields, measurements, routeForm, component, Footer, graphData, addNotes, addChecklist} = props

  const OptionsEle = ({item}) => (
    <td className="text-center">
      <span id="record_edit" onClick={e=>routeForm(component, item.id)} className="fas fa-edit m-r-15 font-18 text-muted"/>  
      <span className="far fa-comment m-r-15 font-18 text-muted" onClick={e=>addNotes(e)}/>
      <span className="far fa-check-circle m-r-15 font-18 text-muted" onClick={e=>addChecklist(e)}/>
    </td>
  )

  return(
    <>
      <div className="table-responsive">
        <DynamicTable requestParams={requestParams} onSort={sort} 
          formFields={formFields} formDataList={measurements} 
          tClass="table"
          onRowClick={onRowClick}
          OptionsEle={OptionsEle}/>

        {measurements.length === 0 &&
          <EmptyRowAlert/>
        }
      </div>
    </>
  )    
}

export default DailyCheckTable;