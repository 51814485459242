import React, { Fragment, useState, useEffect, useContext } from "react";
import { useLocation, Prompt } from "react-router-dom";
import Cs from "../../services/CommonService";
import InputTextField from "../../components/FormBuilder/InputComponents/InputTextField";
import InputTextArea from "../../components/FormBuilder/InputComponents/InputTextArea";
import InputSelect from "../../components/FormBuilder/FormWizardComponents/InputSelect";
import InputRadio from "../../components/FormBuilder/InputComponents/InputRadio";
import InputCheckbox from "../../components/FormBuilder/InputComponents/InputCheckbox";
import InputDataTimePicker from "../../components/FormBuilder/InputComponents/InputDataTimePicker";
import InputColorPicker from "../../components/FormBuilder/InputComponents/InputColorPicker";
import InputMultiSelect from "../../components/FormBuilder/FormWizardComponents/InputMultiSelect";
import InputFileUpload from "../../components/FormBuilder/InputComponents/InputFileUpload";
import InputMultiRating from "../../components/FormBuilder/InputComponents/InputMultiRating";
import InputRating from "../../components/FormBuilder/InputComponents/InputRating";
import InputTags from "../../components/FormBuilder/InputComponents/InputTags";
import InputRichText from "../../components/FormBuilder/InputComponents/InputRichText";
import InputExplanatoryText from "../../components/FormBuilder/InputComponents/InputExplanatoryText";
//import InputMapLocation from '../../components/FormBuilder/InputComponents/InputMapLocation'
import InputSlider from "../../components/FormBuilder/InputComponents/InputSlider";
import InputAutoComplete from "../../components/FormBuilder/InputComponents/InputAutoComplete";
import InputSignaturePad from "../../components/FormBuilder/InputComponents/InputSignaturePad";
import FormHelper from "../../components/FormBuilder/FormHelpers";
import { NotificationPopup } from "../Common/NotificationPopup";
import useStyle from "../../hooks/useStyle";
import querystringify from "querystringify";
import useModal from "../../hooks/useModal";
import { AddLocale } from "../../components/Locale/AddLocale";
import GenericModal from "../../components/Modals/GenericModal";
import { FormRuleAlertIcon } from "./FormRuleAlert";
import { FormTitle, FormDescription } from "./FieldLabel";
import { usePageLocale } from "../../hooks/useLocale";
import { LocalizeContext } from "../../contexts/Localize/LocalizeStateProvider";
import { getLocaleLabel } from "./FieldLabel";
import { FormFooter } from "./FormFooter";
import { TimerEle } from "../Common/Timer";
import { PlannerVideoPlayer } from "../Common/VideoPlayer";
import { DivRichTextView } from "../Common/RichTextView";
import FormTemplateService from "../../services/FormTemplateService";
import { useCurrentUserHook } from "../Authentication/useUserHook";

let errors = { invalid: {} };
let templateFields = [];
let plannerList = null;
let post = {};
let currentIndex = 0;
let successDataSourceField = {};
let progressPercentage = 0;
let selectedFormField = {};

const FormWizard = (props) => {
  useStyle("filter_dialog");

  let location = useLocation();
  let queryParam = querystringify.parse(location.search);

  const {currentUser} = useCurrentUserHook()
  const [count, setCount] = useState(0);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [formTemplate, setFormTemplate] = useState({});
  const [loading, setLoading] = useState(true);
  const [langauge, setLangauge] = useState(props.formFn.locale || currentUser.current_locale);
  const [isQuiz, setIsQuiz] = useState(false);
  const [showSuccessMsg, setShowSuccessMsg] = useState(false);

  const { isOpen: isFieldLabelOpen, toggleModal: toggleFieldLabelModal } = useModal();
  const { isOpen: showFormFields, toggleModal:setShowFormFields} = useModal(true);

  const { localeDispatch } = useContext(LocalizeContext);
  const { labelLocale: formCancelAlertLocale } = usePageLocale(langauge, "formCancelAlert");
  const { labelLocale } = usePageLocale(langauge, "formLocale");
  const { labelLocale: quizLocale } = usePageLocale(langauge, "quizFormLabels");

  const forceUpdate = () => {
    setCount((value) => ++value);
  }

  const onSubmit = (draft) => {
    setLoading(true);
    props.form.updated_by = currentUser.id;
    props.form.draft = draft ? true : false;
    props.form.data = props.data;

    if (props.confirmSubmissionAlert) {
      confirmSubmission();
      return;
    }

    setIsFormSubmitted(true);
    save();
  }
  
  useEffect(() => {
    props.formFn.ds = props.formFn.ds || {};
    if (props.form_wizard_schedule_id) {
      getFormWizardSchedules();
    } else if (props.form_wizard_id) {
      getFormWizardFields();
    } else if (props.assessment_id) {
      getFormAssessmentFields();
      setIsQuiz(true);
    } else if (props.user_form_id) {
      getUserForm();
    } else if (props.formId) {
      getFormTemplate();
    }

    return () => {
      errors = { invalid: {} };
      successDataSourceField = {};
      templateFields = [];
      post = {};
      currentIndex = 0;
      progressPercentage = 0;
      plannerList = null;
      sessionStorage.setItem('current_timer', null)
    }
  }, []);

  const confirmSubmission = () => {
    /*const propmt = `You are now on the last item. 
        If you click “OK,” your responses will be submitted.`*/

    const r = window.confirm(
      `  ${formCancelAlertLocale(5)}\n\n  ${formCancelAlertLocale(6)}`
    );
    if (r == true) {
      if(isQuiz)sessionStorage.setItem('current_timer', null);
      setIsFormSubmitted(true);
      save();
    }
  }

  const getExitAlert = () =>{
    return `  ${formCancelAlertLocale(3)}\n\n  ${formCancelAlertLocale(4)}`;
  }

  const save = () => {
    if (!props.form.id) {
      props.form.created_by = currentUser.id;
      props.onCreate(props.form);
    } else {
      props.onUpdate(props.form);
    }
  }

  const onTimeExpired = () =>{
    onSubmit(true);
  }

  const getFormTemplate = () => {
    FormTemplateService.getFormTemplateById(props.formId, {
      'locale': currentUser.current_locale,
      'is_public': props.formFn.is_public,
      'page_locale': {
        'data_source_ids': [3930, 3953],
        'locale': langauge,
      },
    }).then(({ form_template, page_locale }) => {
      post = form_template.post;
      form_template.template_fields.forEach(
        (t) => (templateFields = templateFields.concat(t.child_template_fields))
      );
      setFormTemplate(form_template);
      init();
      calFormCompletePercentage();
      setLocale(page_locale);
      setLoading(false);
    })
  }

  const getFormWizardFields = () => {
    let req = { 'form_wizard_id': props.form_wizard_id };
    FormTemplateService.getFormWizardFields(req).then((res) => {
      templateFields = res.data.form_wizard_fields;
      post = res.data.post;
      let template = res.data.form_template;
      if (template.submit_action && template.submit_action.success_msg_field) {
        successDataSourceField.success_msg_field = template.submit_action.success_msg_field;
      }
      setFormTemplate(template);
      init();
      calFormCompletePercentage();
      setLoading(false);
    })
  }

  const getFormWizardSchedules = () => {
    const req = {
      'form_template_id': queryParam.form_template_id,
      'unfilled_client_id': queryParam.unfilled_client_id,
      'empty_field_params': queryParam.item_type
        ? { 'item_id': queryParam.item_id, 'item_type': queryParam.item_type }
        : null,
    }

    FormTemplateService.getFormWizardSchedules(props.form_wizard_schedule_id, req).then(
      (res) => {
        templateFields = res.data.form_wizard_scheduler;
        post = res.data.post;
        init();
        calFormCompletePercentage();
        setLoading(false);
      }
    )
  }

  const getFormAssessmentFields = () => {
    let req = {
      'assessment_id': props.assessment_id,
      'is_preview': queryParam.is_preview,
      'session_id': queryParam.sid,
      'user_req_id': queryParam.urrid,
      'user_id': (currentUser?.id || queryParam.uid),
      'trainee_id': queryParam.trainee_id,
      'page_locale': {
        'data_source_ids': [3930, 4594, 4599], //ds 3953 and 4594 are same except 4594 is used for quiz.
        'locale': langauge
      }
    }
    Cs.getFormAssessmentFields(req).then(({ status, data: { assessment_form, page_locale, planners } }) => {
        templateFields = assessment_form.form_fields;
        post = assessment_form.post;
        post.unselectCss = post?.browser_controls?.includes(1)
          ? "unselectable"
          : "";
        setFormTemplate(assessment_form.form_template);
        init();
        calFormCompletePercentage();
        setLocale(page_locale);
        getDataFromSessionStore();
        plannerList = planners;
        setLoading(false);
      }
    )
  }

  const setLocale = (pageLocale) => {
    localeDispatch({
      'type': "UPDATE",
      'payload': {
        'formCancelAlert': pageLocale?.cancellation,
        'formLocale': pageLocale?.text_labels_forms,
        'quizFormLabels':pageLocale?.quiz_form_labels,
      }
    })
  }

  const getUserForm = () => {
    let req = { 'id': props.user_form_id, 'is_form_wizard': true };
    Cs.getUserForm(req).then((res) => {
      templateFields = res.form_wizard_fields;
      post = res.post;
      let template = res.form_template;
      setFormTemplate(template);
      init();
      calFormCompletePercentage();
      setLoading(false);
    })
  }

  const getDataFromSessionStore = () => {
    if (props.assessment_id && queryParam.sid) {
      const form = Cs.getSessionStore(queryParam.sid, true);
      if (form && form.data) {
        if (window.confirm("Restore last saved data.") == true) {
          for (const k in form.data) {
            props.data[k] = form.data[k];
          }
        }
      }
    }
  }

  const saveDataToSessionStore = () => {
    if (props.assessment_id && queryParam.sid) {
      props.form.updated_by = currentUser.id;
      props.form.draft = true;
      props.form.data = props.data;
      Cs.setSessionStore(queryParam.sid, props.form, true);
    }
  }

  const init = () => {
    if (templateFields.length == 0) return null;
    let form_template_id = templateFields[0].id;
    let template = { user_id: templateFields[0].user_id };
    templateFields.push({ id: form_template_id, component: "submit" });
    forceUpdate();
  }

  const next = (i) => {
    let currentComponent = templateFields[currentIndex];
    if (errors.invalid[currentComponent.client_id]) {
      errors.isSubmitted = true;
      forceUpdate();
      return;
    }

    setLoading(true);
    errors.isSubmitted = false;

    let nextIdx = currentIndex + i;
    let nextComponent = templateFields[nextIdx];
    currentIndex = nextIdx;

    if (nextComponent.component == "submit") {
      onSubmit();
      setLoadingTimeout(false);
      return;
    }

    if (props.fieldShowHideFn && props.fieldShowHideFn(nextComponent)) {
        resetFieldError(nextComponent);
        next(1);
        return null;
    }

    if (nextComponent.visibility_logic) {
      let r = FormHelper.checkFieldConditionality(nextComponent, props.data);
      if (r) {
        //Show the field
        forceUpdate();
      } else {
        //Check show hide for next field
        next(1);
      }
    } else {
      //Show the field
      forceUpdate();
      scrollTo(nextComponent.client_id);
      saveDataToSessionStore();
    }
    calFormCompletePercentage();
    setLoadingTimeout(false);
  }

  const back = (i) => {
    if (currentIndex != 0) {
      setLoading(true);
      let nextIdx = currentIndex - i;
      let nextComponent = templateFields[nextIdx];
      currentIndex = nextIdx;

      if (props.fieldShowHideFn && props.fieldShowHideFn(nextComponent)) {
        resetFieldError(nextComponent);
        back(1);
        return null;
      }

      if (nextComponent.visibility_logic) {
        let r = FormHelper.checkFieldConditionality(nextComponent, props.data);
        if (r) {
          //Show the field
          forceUpdate();
        } else {
          //Check show hide for next field
          back(1);
        }
      } else {
        //Show the field
        forceUpdate();
        scrollTo(nextComponent.client_id);
        saveDataToSessionStore();
      }
      setLoadingTimeout(false);
    }
    calFormCompletePercentage();
  }

  const position = (i) => {
    currentIndex = i;
    forceUpdate();
  }

  const calFormCompletePercentage = () => {
    progressPercentage = (currentIndex / (templateFields.length - 2)) * 100;
    console.log(progressPercentage);
  }

  props.formFn.refreshFormSubmit = () => {
    setCount((value) => ++value);
  }

  /*props.formFn.setIsFormSubmitted = () => {
    setIsFormSubmitted(true);
  }*/

  props.formFn.showSuccessMsgFn = () =>{
    setShowSuccessMsg(true);
  }

  props.formFn.setLoading = (value) => {
    setLoading(value);
  }

  const resetFieldError = (field) => {
    delete errors[field.client_id];
    delete errors.invalid[field.client_id];
  }

  const openFieldLabelModal = (field) => {
    selectedFormField = field;
    toggleFieldLabelModal();
  }

  const scrollTo = (fieldId) => {
    try {
      let ele = document.getElementById("field_" + fieldId);
      document.getElementById("fields_panel").scrollTop = ele.offsetTop;
    } catch (e) {
      console.log(e);
    }
  }

  const setLoadingTimeout = (status) =>{
    setTimeout(function() {
      setLoading(status)
    }, 50)
  }

  if (isFormSubmitted && showSuccessMsg) {
    try {
      let submitMessage = "";
      if (props.formFn.successMessage) {
        submitMessage = props.formFn.successMessage;
      } else if (successDataSourceField.options_key_value) {
        let opkv = successDataSourceField.options_key_value.options;
        let selectedValue = props.data[successDataSourceField.client_id];
        selectedValue = selectedValue.toString();
        selectedValue = selectedValue.split(",").map(Number);
        opkv.map((o) => {
          if (selectedValue.includes(o.value)) submitMessage += o.label;
        })
      } else {
        submitMessage =
          formTemplate &&
          formTemplate.submit_action &&
          formTemplate.submit_action.success_message;
      }
      return (
        <div className="row m-t-30">
          <NotificationPopup 
            title={labelLocale(14)} //Success
            message={submitMessage}
            successLabel={labelLocale(13)} //ok
            onSuccess={props.onCloseSuccessMessage || props.onCancel}
          />
        </div>
      )
    } catch (e) {
      props.onCancel();
    }
  }

  const FieldList = () => templateFields.map((f, i) => {
    const result = FormHelper.checkFieldConditionality(f, props.data);

    if (result) {
      const fieldHasValue = props.data[f.client_id] != null;

      return (
        <Fragment key={i}>
          {f.component !== "submit" ? (
            <div id={"field_" + f.client_id}
              className={"flex aligncenter element-form p-10 " +(currentIndex == i ? "bg-shade-green" : "")}
              onClick={() => position(i)}>
                {fieldHasValue ? <i className="fas fa-check-circle font-14 m-r-10" />: null}
                <DivRichTextView className="p-2 font-14 rich-text p-lhu" html={getLocaleLabel(f, langauge)}/>
            </div>
          ) : null}
        </Fragment>
      )
    }

    return null;
  })

  /*Do not move to seperate component outside function. Rerender need to happen when moving between fields.*/

  const FormFields = ({formId, formFn, forceUpdate, langauge, labelLocale, permission, openFieldLabelModal, formCancelAlertLocale, templateFields, currentIndex, data, formMode, errors, fieldShowHideFn, resetFieldError, successDataSourceField}) => {
    let field = templateFields[currentIndex];
    FormHelper.initializeValue(field, data, formMode);
    FormHelper.checkFieldValidity(field, data, errors, currentIndex);

    const result = FormHelper.checkFieldConditionality(field, data);
    if (!result) {
      resetFieldError(field);
    }

    field.formTemplateId = formId;

    if (successDataSourceField.success_msg_field == field.client_id) {
      successDataSourceField = field;
    }

    let fieldProps = {
      formId: formId,
      currentLocale: langauge,
      labelLocale: labelLocale,
      formCancelAlertLocale: formCancelAlertLocale,
      field: field,
      formData: data,
      formFn: formFn,
      isFormWizard: true,
      position: currentIndex,
      renderField: result,
      openFieldLabelModal: openFieldLabelModal,
      readOnly:
        formMode === "create-form-submissions" && field.read_only
          ? false
          : field.read_only
          ? permission?.read_only_editable
            ? false
            : true
          : false,
    };

    const validationProps = {
      forceUpdate: forceUpdate,
      errors: errors,
    };

    field.placeholder = field.cue_prompt_description;

    switch (field.component) {
      case "textInput":
        FormHelper.onChangeExp(field, data);
        return (
          <div id="ct">
            <InputTextField {...fieldProps} {...validationProps} />
          </div>
        );
        break;
      case "textArea":
        return (
          <div id="ct">
            <InputTextArea {...fieldProps} {...validationProps} />
          </div>
        );
        break;
      case "timepicker":
        return (
          <div id="ct">
            <InputDataTimePicker {...fieldProps} {...validationProps} />
          </div>
        );
        break;
      case "datepicker":
        return (
          <div id="ct">
            <InputDataTimePicker {...fieldProps} {...validationProps} />
          </div>
        );
        break;
      case "checkbox":
        return (
          <div id="ct">
            <InputCheckbox {...fieldProps} {...validationProps} />
          </div>
        );
        break;
      case "radio":
        return <InputRadio {...fieldProps} {...validationProps} />;
        //return <InputSelect {...fieldProps} {...validationProps} />;
        break;
      case "select":
        return <InputSelect {...fieldProps} {...validationProps} />;
        break;
      case "multi_select":
        return <InputMultiSelect {...fieldProps} {...validationProps} />;
        break;
      case "slider":
        return (
          <div id="ct">
            <InputSlider {...fieldProps} {...validationProps} />;
          </div>
        );
        break;
      case "autocomplete":
        return (
          <div id="ct">
            <InputAutoComplete
              {...fieldProps}
              {...validationProps}
              initDataLoad={true}/>
            ;
          </div>
        );
        break;
      case "imageupload":
        return (
          <div id="ct">
            <InputFileUpload {...fieldProps} {...validationProps} />;
          </div>
        );
        break;
      case "multi_file_upload":
        return (
          <div id="ct">
            <InputFileUpload {...fieldProps} {...validationProps} />;
          </div>
        );
        break;
      /*return <InputMultiFileUpload {...fieldProps} {...validationProps} />*/
      case "richtext":
        return (
          <div id="ct">
            <InputRichText {...fieldProps} {...validationProps} />;
          </div>
        );
        break;
      case "explanatory_text":
        return (
          <div id="ct">
            <InputExplanatoryText {...fieldProps} {...validationProps} />;
          </div>
        );
        break;
      case "color_picker":
        return (
          <div id="ct">
            <InputColorPicker {...fieldProps} {...validationProps} />
          </div>
        );
        break;
      case "rating":
        if (field.data_source_id) {
          return <InputMultiRating {...fieldProps} {...validationProps} />;
        } else {
          return <InputRating {...fieldProps} {...validationProps} />;
        }
        break;
      case "input_tags":
        return (
          <div id="ct">
            <InputTags {...fieldProps} {...validationProps} />
          </div>
        );
        break;
      case "signature":
        return (
          <div id="ct">
            <InputSignaturePad {...fieldProps} {...validationProps} />
          </div>
        );
        break;
      default:
        return null;
        break;
    }
  }

  let questionListMenuStyle = {
    left: "0px",
    display: showFormFields ? "block" : "none",
    minWidth: "250px"
  }

  if (templateFields.length === 0){
    return <div className="spinner"/>;
  } 

  const pushLeftCss = props.isPopupMode ? "" : showFormFields ? "col-sm-8 push-fw" : "col-sm-8 push-20";

  return (
    <>
      {!isFormSubmitted && <Prompt message={getExitAlert()}/>}
      <div className="row app-content m-t-40 m-t-20-xs">
        <div className={`col-xs-12 m-b-120 m-t-20 ${pushLeftCss} ${post.unselectCss}`}>
          <div className="flex justspacebetween bg-white br-grey brd-5 p-5">
            <div className="flex aligncenter">
              {props.showFieldMenu &&
                <>
                  <span data-tip="Preview Questions"
                    onClick={() => setShowFormFields()}
                    className="bg-highlight round-btn m-l-10">
                      <i className="fas fa-list-alt" />
                  </span>

                  <div style={questionListMenuStyle}
                    className={`side-menu-bar field-menu bg-white black br-grey ht-92p ${post.unselectCss}`}>
                    <div className="flex justspacebetween bg-ltint fw900 cgap-10">
                      <FormDescription details={post.details} locale={post.locale_details} currentLocale={langauge}/>
                      <div className={"f25 pull-right fas " +(showFormFields ? "fas fa-arrow-left" : "")} onClick={() => setShowFormFields()} />
                    </div>

                    <div className="element-form"/>

                    <div id="fields_panel" className="scroll-auto ht-92p">
                      {showFormFields ? <FieldList/> : null}
                    </div>
                  </div>
                </>
              }
              <div className="flex coldir m-l-15">
                <FormTitle
                  className="font-18 bold-600 m-b-10"
                  title={post.title}
                  locale={post.locale_title}
                  currentLocale={langauge}/>
                <div>
                  <FormDescription
                    details={post.details}
                    locale={post.locale_details}
                    currentLocale={langauge}/>
                </div>
                <div>
                  {/*check label loaded*/}
                  { quizLocale(8) ? 
                    <TimerEle onTimeExpired={onTimeExpired} 
                      totalTimeInMinutes={formTemplate.duration} 
                      langauge={langauge} 
                      timeRemainigLabel={quizLocale(8)}/>
                    :
                    null
                  }
                </div>
                {props.formId ? (
                  <FormRuleAlertIcon
                    currentUser={currentUser}
                    formId={props.formId}/>
                ) : null}
              </div>
            </div>
            <span className="font-14 lred ml-auto flex aligncenter m-r-10">
              <img src={post.logo} className="mxw-85"/>
            </span>
          </div>
          { (loading || isFormSubmitted) ? 
            <div className="spinner"/>
            :
            <div className="portlet t-0 p-t-0 bg-white br-grey">
              <div className="portlet m-t-0">
                <div className="m-l-5 m-r-5">
                  <div className="progress-bar m-t-5">
                    <div className="active" style={{ width: progressPercentage + "%" }}></div>
                  </div>
                </div>
                <div className="clearfix p-l-5 p-r-5">
                  <FormFields formId={props.formId} langauge={langauge} data={props.data} 
                    formMode={props.formMode} permission={props.permission} formFn={props.formFn}
                    {...{forceUpdate, labelLocale, openFieldLabelModal, formCancelAlertLocale, templateFields, currentIndex, errors, resetFieldError, successDataSourceField}}/>
                  <div className="col-xs-12 p-0">
                    <PlannerVedio field={templateFields[currentIndex]} plannerList={plannerList}/>
                  </div>
                  <div className="clerfix" />
                  <div className="col-xs-12 text-center">
                    <div className="font-14 black-light-1">
                      {/*Item 1 of 5*/}
                      {labelLocale(12)} {currentIndex + 1} of {templateFields.length - 1}
                    </div>
                  </div>
                  <div className="col-xs-12 z-idx-99">
                    <div className="col-xs-12 col-sm-4 col-md-4">
                      {currentIndex != 0 ? (
                        <button
                          type="button"
                          id="prev"
                          className="butn p-10 w-l-btn m-b-5"
                          onClick={() => back(1)}>
                          {labelLocale(9)}
                        </button>
                      ) : (
                        <div>.</div>
                      )}
                    </div>
                    {isQuiz ? null :
                      <div className="col-xs-12 col-sm-4 col-md-4 text-center">
                        <button
                          id="draft_btn"
                          type="button"
                          className="butn p-10 bg-draft w-l-btn m-b-5"
                          onClick={() => onSubmit(true)}>
                          {labelLocale(3)}
                        </button>
                      </div>
                    }
                    <div className="col-xs-12 col-sm-4 col-md-4 text-right">
                      <button
                        id="submit_next"
                        type="button"
                        className="butn p-10 w-r-btn"
                        onClick={() => next(1)}>
                        {currentIndex + 2 == templateFields.length
                          ? props.form.id
                            ? labelLocale(7)
                            : labelLocale(2)
                          : labelLocale(8)}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }
          {currentIndex + 2 == templateFields.length ? (
            <div className="m-t-n10 m-b-10 m-l-10 m-r-10">
              <FormFooter {...{ labelLocale, currentUser }} template={formTemplate} showFeedbackLink={!isQuiz}/>
            </div>
          ) : null}
        </div>
      </div>

      {isFieldLabelOpen && (
        <GenericModal
          component={AddLocale}
          defaultLabel={selectedFormField.label}
          localeType="form_field"
          translateFieldKey="label"
          title={selectedFormField.label}
          toggleModal={toggleFieldLabelModal}
          onCancel={toggleFieldLabelModal}
          labelObj={selectedFormField}
        />
      )}
    </>
  );
};

const PlannerVedio = ({field, plannerList}) =>{
  const planner = plannerList?.find(p => p.id === field.planner_id)
  
  return(
    <>
      {planner?.url ?
        <PlannerVideoPlayer className="video-container-2" 
          playInline={true} url={planner?.url} 
          stepPlannerList={planner?.planner_steps}/>
        :
        null
      }
    </>
  )
}

export default FormWizard;