import React from 'react';
import env from "../../env";
import { useCurrentUserHook } from '../Authentication/useUserHook';

const EditDataSourceIcon = ({field, open, iconClass}) => {
  const {currentUser} = useCurrentUserHook()

  let ele = <span className={(iconClass || 'fas fa-list')+' m-l-15 cursor f18'} onClick={e=>open(field)}/>

  if(iconClass == 'fas fa-edit' && env.admin_emails.includes(currentUser.email)){
  	return ele		
  }else if(field.data_source_editable){
  	return ele
  }else{
  	return null
  }
  
}

export default EditDataSourceIcon;
