import React, { useState, useEffect, useRef } from 'react';
import Cs from "../services/CommonService";

const useClientSearch = (searchableList=[], setSearchedList) => {
  
  let {current:scope} = useRef({});
  let [searchKeyword, setSearchKeyword] = useState('');

  useEffect(() => {
    if(searchKeyword.length === 0 && searchableList.length > 0){
      scope.sourceSearchList = Cs.deepCopy(searchableList);
      scope.timer = null;
      scope.WAIT_INTERVAL = 1000;
    }
  }, [searchableList])

  const onSearch=(event)=>{
    clearTimeout(scope.timer);
    searchKeyword = event.target.value;
    scope.timer = setTimeout(search, scope.WAIT_INTERVAL);
    setSearchKeyword(searchKeyword);
  }

  const search = () =>{
    if(searchKeyword.length>0 && searchableList.length>0){
      searchKeyword = searchKeyword.toLowerCase();
      /*for(let s of searchableList){
        console.log(s.toString())
      }*/
      const result = Cs.nestedListSearch(searchableList, searchKeyword);
      setSearchedList([...result]);
    }else{
      setSearchedList([...scope.sourceSearchList]);
    }
  }

  return {
    onSearch
  }
};

const useSearch = (setSearchString, searchString, searchInputEl) => {
  
  let {current:scope} = useRef({});

  const [searchButton, setSearchButton] = useState(false);
  let [searchKeyword, setSearchKeyword] = useState(searchString);
  
  let inputName = useRef("");

  useEffect(() => {
    scope.timer = null;
    scope.WAIT_INTERVAL = 1000;
  }, [])

  const onSearchCallback = (event) =>{
    clearTimeout(scope.timer);
    searchKeyword = event.target.value;
    inputName.current = event.target.name;
    scope.timer = setTimeout(triggerChange, scope.WAIT_INTERVAL);
    setSearchKeyword(searchKeyword);
  }

  const triggerChange = (name) => {
    setSearchString(searchKeyword, inputName.current);
  }

  const searchClick=() =>{
    if(searchButton === true){
      setSearchButton(false);
    }else{
      setSearchButton(true);
    }
  }

  const clearSearch = () => {
    setSearchKeyword("");
    setSearchString(null);
    if(searchInputEl?.current)
      searchInputEl.current.value = '';
  }

  return {
    onSearchCallback,
    searchKeyword,
    searchClick,
    searchButton,
    setSearchButton,
    clearSearch,
    setSearchKeyword
  }
};

const ClientSearchEle = ({className, searchList, setSearchList}) =>{
  const {onSearch} = useClientSearch(searchList, setSearchList);

  return(
    <div className={`input-group ${className}`}>
      <input type="text" onChange={e=>onSearch(e)} placeholder="Search" className="form-control input-sm"/>
      <span className="input-group-btn">
        <p type="button" className="bg-highlight input-sm">
          <i className="fa fa-search white"/>
        </p>
      </span>
    </div>
  )
}

const SearchEle = ({setSearch, className, value}) =>{
  const {onSearchCallback} = useSearch((s) => setSearch(s));

  return(
    <div className={`input-group ${className}`}>
      <input type="text" onChange={e=>onSearchCallback(e)} defaultValue={value} placeholder="Search" className="form-control input-sm"/>
      <span className="input-group-btn">
        <p type="button" className="bg-highlight input-sm">
          <i className="fa fa-search white"/>
        </p>
      </span>
    </div>  
  )
}

export {useSearch, useClientSearch, ClientSearchEle, SearchEle};