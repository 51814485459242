import { useState } from 'react';
import Cs from "../../../../../services/CommonService";
import { getLocale } from '../../../../FormBuilder/FieldLabel';

const useActivityHook = (child_params={}) => {
  const [child, setChild] = useState(child_params)

  const ageFormat = (id, scheduled_date, data) =>{
    const visit_date = id?data.visit_date_formatted_date:scheduled_date
    if(visit_date && child?.data){
      return Cs.ageDWMY(child.data.dob_baby_formatted_date, visit_date);
    }
  }

  const getBgColorByZscore = (z_score, label) =>{
    try{
      z_score = parseFloat(z_score)
      if(z_score <= -3){
        const style = {'backgroundColor': '#FFA191' ,'color': '#A80000'}
        return {
          'r1': style,
          'r2': style,
          'r4': getBgColorByZscoreLable(label)
        }
      }else if(z_score <= -2){
        const style = {'backgroundColor' : '#FFDA70','color': '#fffff'}
        return {
          'r1': style,
          'r2': style,
          'r4': getBgColorByZscoreLable(label)
        }
      }else if(z_score <= -1){
        const style = {'backgroundColor': '#FCFC7A' ,'color':'#918B01'}
        return {
          'r1': style,
          'r2': style,
          'r4': getBgColorByZscoreLable(label)
        }
      }else{
        const style = {'backgroundColor': '#9AFFA9' ,'color': '#00AB8E'}
        
        return {
          'r1': style,
          'r2': style,
          'r4': style
        }
      }
    }catch(e){
      console.error(e.message)
      return {}
    }
  }

  const getBgColorByZscoreLable = (zlabel) =>{
    switch (zlabel) {
      case 'SUW':
      case 'SS':
      case 'Severe Stunting':
      case 'SAM':
        return {'backgroundColor': '#FFA191' ,'color': '#A80000'}
        break;
      case 'MUW':
      case 'Moderate Stunting':
      case 'MS':
      case 'MAM':
        return {'backgroundColor' : '#FFDA70','color': '#fffff'}
        break;
      case 'Mild':
        return {'backgroundColor': '#FCFC7A' ,'color':'#918B01'}
        
        break;
      default:
        return {'backgroundColor': '#9AFFA9' ,'color':'#00AB8E'}
    }
  }

  return {
    child, 
    setChild,
    ageFormat,
    getBgColorByZscore,
    getBgColorByZscoreLable
  }
}

const useAccessBfHook = (currentLocale, accessBFTableLocale) =>{

  const groupAccessBFReport = (formFields, dataSource) =>{
    const accessBfReport = {'pos':[], 'neg':[]};
    if(formFields && dataSource){
      for (const f of formFields) {
        const ds = dataSource[f.client_id];
        if(Array.isArray(ds)){
          const count = ds.reduce((acc, d) => {
            return acc + d.data.rating;
          }, 0);

          accessBfReport[checkRating(count)].push({
            'fieldlabel': getLocale(f, currentLocale, 'label'), 
            'clientId': f.client_id,
            'count': count
          })
        }else if(ds?.value != null){
          accessBfReport[checkRating(ds.rating)].push({
            'fieldlabel': getLocale(f, currentLocale, 'label'), 
            'clientId': f.client_id, 
            'count': 1
          })
        }
      }
    }
    return accessBfReport;
  }

  const checkRating = (rating) =>{
    if(rating <= 0){
      return 'neg';
    }

    return 'pos';
  }

  const checkAlertMessage = (activity) =>{
    if(activity.id){
     return '';
    }else if(Cs.compareDate(activity.scheduled_date, null, 'days') >= 0){
      // 'A visit to take new measurements is scheduled for this date'
      return activity.notification_text || accessBFTableLocale(7);
    }else{
      return accessBFTableLocale(18);
    }
  }

  return {
    groupAccessBFReport,
    checkAlertMessage
  }
}

export {useActivityHook, useAccessBfHook};