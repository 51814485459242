import React, { Fragment, useState, useEffect } from "react";
import ReactDOM from "react-dom";
import useStyle from "../../hooks/useStyle";
import Cs from "../../services/CommonService";
import useEscape from "../../hooks/useEscape";
import DataSourceMappingService from "../../services/DataSourceMappingService";
import { LinkFontAwesomeIcon } from "../Common/ImageIcons";
import useModal from "../../hooks/useModal";
import { NavIcon } from '../Common/MenuItem';
import { Table, THead, TH, TR, TD, TBody } from "../Common/TableView";
import useSort from '../../hooks/useSorting';
import SortDirection from '../Common/SortDirection';

const MapperDataSourceListModal = ({isOpen, toggleModal, mappingId, mappingType}) => {
  
  useStyle("filter_dialog")

  useEscape(toggleModal)

  const [dataSourceList, setDataSourceList] = useState([])
  let [requestParams, setParams] = useState({
    mapping_type:mappingType,
    mapping_id:mappingId
  })

  useEffect(() => {
    getMappingList()
  }, [])

  let getMappingList = (d, idx) => {
    DataSourceMappingService.getAll(requestParams).then((res) => {
      setDataSourceList(res.data.planner_mapping)
    })
  }

  const {sort} = useSort(requestParams, getMappingList)

  const SortDirIco = ({column}) =>{
    return (
      <SortDirection sortColumn={requestParams.sort_column} 
      column={column} reverse={requestParams.reverse}/>
    )
  }

  return ReactDOM.createPortal(
    <Fragment>
      <div className="ml-modal">
        <div className="ml-modal-content ml-card-4 ml-animate-zoom brd-10">
          <div className="flex justspacebetween modal-title">
            <h4 className="f18 text-white fw500 p12-10-15">Please select items</h4>
            <button className="btn-close"
              title="Close Modal" onClick={e=>toggleModal(e)}>×
            </button>
          </div>
          
          <div id="ct" className="table-fix-head">
            <Table className="shadow-small">
              <THead>
                <TR>
                  <TH onClick={()=>sort('record_id')}>
                    Form ID 
                    <SortDirIco column="record_id"/>
                  </TH>
                  <TH onClick={()=>sort('data_source.title')}>
                    DS Title 
                    <SortDirIco column="data_source.title" />
                  </TH>
                  <TH onClick={()=>sort('data_source_list.data.label')}>
                    DS Label
                    <SortDirIco column="data_source_list.data.label"/>
                  </TH>
                  <TH onClick={()=>sort('created_at')} >
                    Date
                    <SortDirIco column="created_at" 
                    />
                  </TH>
                </TR>
              </THead>
              <TBody>
                <TableData dataSourceList={dataSourceList}/>
              </TBody>
            </Table>
          </div>
          
          {/*<div className="p-5 scroll-x">
            <DataSources/>    
          </div>*/}
        </div>
      </div>
    </Fragment>, document.body
  )
}

const TableData = ({dataSourceList}) => dataSourceList.map((o, k) => {
  return(
    <TR key={k}>
      <TD className="text-gray">
        {o.form_template_id}
      </TD>
      <TD>
        {o.data_source?.title}
      </TD>
      <TD>
        {o.data_source_list?.data?.label}
      </TD>
      <TD>
        {Cs.formatUpdateDate(o.created_at)}
      </TD>
    </TR>
  )
})

const DataSourceMapperIcon = ({mappingType, mappingId, iconType}) =>{
  const { isOpen: isPlannerMapperOpen, toggleModal: togglePlannerMapper} = useModal()

  if(isPlannerMapperOpen){
    return(
      <MapperDataSourceListModal isOpen={isPlannerMapperOpen} 
      toggleModal={togglePlannerMapper} mappingId={mappingId} 
      mappingType={mappingType}/>
    )
  }

  if(iconType === 'button'){
    return( 
      <NavIcon id="mapper" dataTip="DS Mapping" 
        className="bg-highlight round-btn float-right m-r-5" 
        onSelect={togglePlannerMapper} icon="fas fa-hands-helping"/>
    )
  }

  return(
    <LinkFontAwesomeIcon 
    iconCss="fas fa-hands-helping font-18 white"
      onSelect={() => togglePlannerMapper()}
    />
  )
  
}

export {MapperDataSourceListModal, DataSourceMapperIcon};