import env from '../../../../env';
import axios from 'axios';

class ActivityService {
  
  getAll(req){
    return new Promise((resolve, reject) => {
      axios.get(`${env.health_backend}health/activities`, {params: req}).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        reject(e);
      })
    })
  }

  create(profile){
    return new Promise((resolve, reject) => {
      axios.post(`${env.health_backend}health/activities`,profile).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        reject(e);
      })
    })
  }

  getById(req){
    return new Promise((resolve, reject) => {
      axios.get(env.health_backend+'health/activities/'+(req.id || null), { params: req}).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        reject(e);
      })
    })
  }

  update(profile) {
    return new Promise((resolve, reject) => {
      axios.put(`${env.health_backend}health/activities/${profile.id}`, profile).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        reject(e);
      })
    })
  }

  delete(id) {
    return new Promise((resolve, reject) => {
      axios.delete(`${env.health_backend}health/activities/${id}`).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        reject(e);
      })
    })
  }

  report(req){
    return new Promise((resolve, reject) => {
      axios.get(`${env.health_backend}health/reports/activities`, {params: req}).then((res) => {
        // handle success
        resolve(res);
      })
    })
  }

}

export default new ActivityService();